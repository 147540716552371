import React, { useState } from 'react';
import PropTypes from 'prop-types';
// Component
import { VStack, Flex, Input, IconButton, Grid } from '@chakra-ui/react';
import CustomFormControl from '../../../core/CustomFormControl';
import { isEqual } from 'lodash';
import DateTimeWithTrappedFocus from '../../../core/DateTimeWithTrappedFocus';
import { Tooltip } from '@/components/ui/tooltip';
import { IoInformationCircleOutline } from 'react-icons/io5';
import { Switch } from '@/components/ui/switch';

const propTypes = {
  editMode: PropTypes.bool,
  isPermit: PropTypes.bool,
  exportDetails: PropTypes.shape({
    docsReceivedDate: PropTypes.Datetime,
    isfReceivedDate: PropTypes.Datetime,
    docs_sent_to_custom_broker: PropTypes.Datetime,
    docsToBankDate: PropTypes.Datetime,
    importPermit: PropTypes.bool,
    importPermitExpiryDate: PropTypes.Datetime,
  }),
  onChangeValue: PropTypes.func,
  onObjectValueChange: PropTypes.func,
  onVoyageValueChange: PropTypes.func,
  showToggles: PropTypes.bool,
};

const ShipmentImportantDetails = ({
  exportDetails,
  editMode,
  onObjectValueChange,
  isCourierForFmg,
  isIsfFileDateCfc,
  isActiveDocsSentToBank,
  isActiveDocsSentToCustomer,
  showToggles,
}) => {
  const [importPermit, setImportPermit] = useState(exportDetails.importPermit?.toString());

  return (
    <VStack gap="11px" align="stretch">
      <Grid gap="20px" templateColumns="repeat(4, 1fr)" width="100%">
        <CustomFormControl
          width="100%"
          label={
            <>
              Docs received Date&nbsp;
              <Tooltip
                content="Records the date when shipment documents were received to track"
                positioning={{ placement: 'right-end' }}
              >
                <IconButton
                  width="14px"
                  height="14px"
                  padding="0"
                  minW="auto"
                  borderRadius="50%"
                  color="#878787"
                  variant="unstyled"
                >
                  <IoInformationCircleOutline size="14px" />
                </IconButton>
              </Tooltip>
            </>
          }
        >
          <DateTimeWithTrappedFocus
            id="docsReceivedDate"
            inputProps={{
              className: 'form-control m-input',
              disabled: !editMode,
            }}
            closeOnSelect
            value={exportDetails?.docsReceivedDate}
            onChange={e =>
              onObjectValueChange(
                'exportDetails',
                'docsReceivedDate',
                e._isAMomentObject ? e.format('YYYY-MM-DD') : null || ''
              )
            }
            timeFormat={false}
            dateFormat="YYYY-MM-DD"
          />
        </CustomFormControl>
        {showToggles && (
          <CustomFormControl
            width="100%"
            label={
              <>
                ISF received Date&nbsp;
                <Tooltip
                  content="Records the date when Importer Security Filing (ISF) documents were received for customs compliance."
                  positioning={{ placement: 'right-end' }}
                >
                  <IconButton
                    width="14px"
                    height="14px"
                    padding="0"
                    minW="auto"
                    borderRadius="50%"
                    color="#878787"
                    variant="unstyled"
                  >
                    <IoInformationCircleOutline size="14px" />
                  </IconButton>
                </Tooltip>
              </>
            }
          >
            <DateTimeWithTrappedFocus
              id="isfReceivedDate"
              inputProps={{
                className: 'form-control m-input',
                disabled: !editMode,
              }}
              closeOnSelect
              value={exportDetails.isfReceivedDate || ''}
              onChange={e =>
                onObjectValueChange(
                  'exportDetails',
                  'isfReceivedDate',
                  e._isAMomentObject ? e.format('YYYY-MM-DD') : null || ''
                )
              }
              timeFormat={false}
              dateFormat="YYYY-MM-DD"
            />
          </CustomFormControl>
        )}
        {showToggles && isIsfFileDateCfc && (
          <CustomFormControl width="100%" label="ISF File Date">
            <DateTimeWithTrappedFocus
              id="isfFileDate"
              inputProps={{
                className: 'form-control m-input',
                disabled: !editMode,
              }}
              closeOnSelect
              value={exportDetails.isfFileDate || ''}
              onChange={e =>
                onObjectValueChange(
                  'exportDetails',
                  'isfFileDate',
                  e._isAMomentObject ? e.format('YYYY-MM-DD') : null || ''
                )
              }
              timeFormat={false}
              dateFormat="YYYY-MM-DD"
            />
          </CustomFormControl>
        )}
        {showToggles && (
          <CustomFormControl
            width="100%"
            label={
              <>
                {isActiveDocsSentToCustomer}&nbsp;
                <Tooltip
                  content="Records the date when shipment documents were sent to the customs broker for processing."
                  positioning={{ placement: 'right-end' }}
                >
                  <IconButton
                    width="14px"
                    height="14px"
                    padding="0"
                    minW="auto"
                    borderRadius="50%"
                    color="#878787"
                    variant="unstyled"
                  >
                    <IoInformationCircleOutline size="14px" />
                  </IconButton>
                </Tooltip>
              </>
            }
          >
            <DateTimeWithTrappedFocus
              id="docsToCustomBrokerDate"
              inputProps={{
                className: 'form-control m-input',
                disabled: !editMode,
              }}
              closeOnSelect
              value={exportDetails?.docsToCustomBrokerDate || ''}
              onChange={e =>
                onObjectValueChange(
                  'exportDetails',
                  'docsToCustomBrokerDate',
                  e._isAMomentObject ? e.format('YYYY-MM-DD') : null || ''
                )
              }
              timeFormat={false}
              dateFormat="YYYY-MM-DD"
            />
          </CustomFormControl>
        )}
      </Grid>
      <Flex gap="20px" width="100%">
        {showToggles && isCourierForFmg ? (
          <CustomFormControl width="100%" label="Courier tracking no.">
            <Input
              backgroundColor="white"
              id="courierTrackingNo"
              value={exportDetails?.courierTrackingNo || ''}
              disabled={!editMode}
              className="form-control"
              onChange={e => onObjectValueChange('exportDetails', 'courierTrackingNo', e.target.value)}
            />
          </CustomFormControl>
        ) : (
          <>
            {showToggles && isActiveDocsSentToBank && (
              <CustomFormControl width="100%" label="Docs sent to Bank">
                <DateTimeWithTrappedFocus
                  id="docsToBankDate"
                  inputProps={{
                    className: 'form-control m-input',
                    disabled: !editMode,
                  }}
                  closeOnSelect
                  value={exportDetails?.docsToBankDate || ''}
                  onChange={e =>
                    onObjectValueChange(
                      'exportDetails',
                      'docsToBankDate',
                      e._isAMomentObject ? e.format('YYYY-MM-DD') : null || ''
                    )
                  }
                  timeFormat={false}
                  dateFormat="YYYY-MM-DD"
                />
              </CustomFormControl>
            )}
          </>
        )}
        {showToggles && (
          <CustomFormControl
            width="100%"
            label={
              <>
                Import Permit&nbsp;
                <Tooltip
                  content="Indicates whether an import permit is required for the shipment (Yes or No)"
                  positioning={{ placement: 'right-end' }}
                >
                  <IconButton
                    width="14px"
                    height="14px"
                    padding="0"
                    minW="auto"
                    borderRadius="50%"
                    color="#878787"
                    variant="unstyled"
                  >
                    <IoInformationCircleOutline size="14px" />
                  </IconButton>
                </Tooltip>
              </>
            }
          >
            <Switch
              marginTop="6px"
              data-test="import-permit"
              id="isRecurring"
              size="lg"
              colorScheme="actionPrimary"
              defaultChecked={importPermit}
              disabled={!editMode}
              onChange={e => {
                const value = e.nativeEvent.target.checked;
                setImportPermit(value);
                onObjectValueChange('exportDetails', 'importPermit', value);
              }}
            />
          </CustomFormControl>
        )}
        {importPermit === 'true' && (
          <CustomFormControl width="100%" label="Import Permit Expiry">
            <DateTimeWithTrappedFocus
              id="import_permit_expiry"
              inputProps={{
                className: 'form-control m-input',
                disabled: !editMode,
              }}
              closeOnSelect
              value={exportDetails?.importPermitExpiryDate || ''}
              onChange={e =>
                onObjectValueChange(
                  'exportDetails',
                  'importPermitExpiryDate',
                  e._isAMomentObject ? e.format('YYYY-MM-DD') : null || ''
                )
              }
              timeFormat={false}
              dateFormat="YYYY-MM-DD"
            />
          </CustomFormControl>
        )}
      </Flex>
    </VStack>
  );
};

ShipmentImportantDetails.ProType = propTypes;
export default React.memo(ShipmentImportantDetails, isEqual);
