import { cloneDeep, get, isEqual, isString, set, uniq } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
// Local
import TopLoaderService from 'top-loader-service';
import 'top-loader-service/TopLoaderService.css';
import Constants from '../../../../Constants';
import { GENERATED_DOCUMENT_ERROR } from '../../../../actions/actions_generated_documents';
import {
  addInternationalLine,
  addOrUpdateLineProductionDetails,
  deleteLineProductionDetails,
  editInternationalLine,
  fetchNetsuitePurchaseOrder,
  removeMarks,
  removeOrder,
  resetModalOrder,
  saveOrder,
  submitOrder,
  draftOrder,
  addOrUpdatePurchaseOrderAssociatedModel,
  deletePurchaseOrderAssociatedModel,
} from '../../../../actions/actions_orders';
import { fetchPackageWeights } from '../../../../actions/actions_package_weights';
import { addOrUpdateVoyage, deleteVoyage } from '../../../../actions/actions_voyage';
import ShipmentImportantDetails from './ShipmentImportantDates';
// Utils
import {
  checkForAsianGrinder,
  constructBuyerOptions,
  convertToCamelCase,
  convertToSnakeCase,
  correctDecimalRoundingBlur,
  correctDecimalRoundingChange,
  getOr,
} from '../../../../helpers';

import {
  IsValidUSDomesticOrder,
  changeableKeyCreator,
  checkIfManualSurchargesApply,
  filterInputProducts,
  getDefaultValueFromConfigs,
  getDocsSentToCustomFieldName,
  getInternalPoLabel,
  getOptionsFromSettingsConfig,
  isDeliveryDateEditable,
  isPoHedgingEnabled,
  productionDateValidations,
  shipmentDateValidations,
  showCourierTrackingNo,
  showIsfFileDate,
  showUsCustomsEntryNumber,
  showActualDateOfArrival,
  showActualDateOfDeparture,
  showDocsSentToBank,
} from '../../../../utils';
import {
  analyzeBasePOForm,
  exportToExcelFile,
  isAmericanDomesticOrder,
  isAustraliaDomesticOrder,
  isEuropeDomesticOrder,
  isGeneralDomesticOrder,
  prepareInternationalOrderPatch,
  validateEditOrderForm,
} from '../../../lib/PurchaseOrderFunctions';
import { filterEndUserOptionsByGrinder, formatOptionsFromFormFields } from '../CreatePurchaseOrderForm/helpers';
import { adaptServerPurchaseOrderToClient, createNewLine } from '../InternationalOrderForm/helpers';

// Actions
import { fetchEntitiesData } from '../../../../slices/masterData/entityManagerSlice';
// Local Components
import {
  Box,
  Button,
  Group,
  Flex,
  HStack,
  IconButton,
  Input,
  Separator,
  Tabs,
  Text,
  Textarea,
  VStack,
  Grid,
} from '@chakra-ui/react';
import InternationalConfirmation from '../../../../containers/orders/InternationalConfirmation';
import Loading from '../../../basic/Loading';
import SelectField from '../../../basic/SelectField';
import PurchaseOrderDocuments from '../../PurchaseOrderDocuments';
import OrderDetails from '../../order-table-modal/OrderDetails/OrderDetails';
import DateSection from '../DateSection/DateSection';
import InternationalOrderLines from '../InternationalOrderLines';
import BillOfLading from './BillOfLading';
import ColdStoreDetails from './ColdStoreDetails';
import './EditInternationalOrder.scss';
import FOBInformation from './FOBInformation';
import InternationalOrderDetails from './InternationalOrderDetails/InternationalOrderDetails';
import RejectedCartons from './RejectedCartons/RejectedCartons';
import SummaryOfCosts from './SummaryOfCosts';
import VoyageDetails from './VoyageDetails';

import { TimePicker } from 'antd';
import { fetchSettingsConfig } from '../../../../actions/action_settings_config';
import { fetchFormFields } from '../../../../actions/actions_form_fields';
import { fetchTransporters } from '../../../../actions/actions_transporters';
import { ReactComponent as DownloadIcon } from '../../../../assets/download-icon.svg';
import { buyersSelector } from '../../../../reducers/reducer_users';
import { CFSwitch } from '../../../basic/Toggle/Toggle';
import CustomFormControl from '../../../core/CustomFormControl';
import DateTimeWithTrappedFocus from '../../../core/DateTimeWithTrappedFocus';
import GeneratedDocuments from '../../GeneratedDocuments';
import PurchaseOrderInfo from '../../PurchaseOrderInfo';
import PurchaseOrderStatusSection from '../../PurchaseOrderStatusSection';
import StatusTag from '../StatusTag';
import Surcharges from '../surcharges';
import TransferOrderModal from '../../TransferOrderModal/TransferOrderModal';
import { capitalizeFirstLetter } from '../../../../functions';
import Confirm from '../../../utilities/Confirm';
import { Tooltip } from '@/components/ui/tooltip';
import { IoInformationCircleOutline } from 'react-icons/io5';
import OrderActivityLog from '../../order-table-modal/OrderActivityLog';
import IssuesXcodes from '../../IssuesXcodes/IssuesXcodes';
import {
  DialogRoot,
  DialogContent,
  DialogBody,
  DialogHeader,
  DialogFooter,
  DialogCloseTrigger,
} from '@/components/ui/dialog';
import { Alert } from '../../../ui/alert';
import { Checkbox } from '@/components/ui/checkbox';
import { Field } from '@/components/ui/field';

class EditInternationalOrder extends PureComponent {
  static propTypes = {
    coldStores: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        name: PropTypes.string.isRequired,
        // Add other properties as needed
      })
    ).isRequired,
    containers: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        name: PropTypes.string.isRequired,
        // Add other properties as needed
      })
    ).isRequired,
    containerDepots: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        name: PropTypes.string.isRequired,
        // Add other properties as needed
      })
    ),
    containerReturns: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        name: PropTypes.string.isRequired,
        // Add other properties as needed
      })
    ),
    currencies: PropTypes.arrayOf(
      PropTypes.shape({
        code: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      })
    ).isRequired,
    currentOrder: PropTypes.instanceOf(Object),
    dispatch: PropTypes.func,
    email: PropTypes.shape({
      isSendingEmail: PropTypes.bool,
      sendEmailError: PropTypes.string,
    }),
    grinders: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        name: PropTypes.string.isRequired,
        // Add other properties as needed
      })
    ).isRequired,
    allGrinders: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        name: PropTypes.string.isRequired,
        // Add other properties as needed
      })
    ).isRequired,
    incoTerms: PropTypes.arrayOf(
      PropTypes.shape({
        code: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
      })
    ).isRequired,
    inputProducts: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        name: PropTypes.string.isRequired,
        // Add other properties as needed
      })
    ).isRequired,
    allInputProducts: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        name: PropTypes.string.isRequired,
        // Add other properties as needed
      })
    ).isRequired,
    hasVoyageDetails: PropTypes.bool,
    isSavingOrder: PropTypes.func,
    loadSizes: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        name: PropTypes.string.isRequired,
        // Add other properties as needed
      })
    ).isRequired,
    onClose: PropTypes.func,
    orders: PropTypes.shape({
      addedOrderId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      isSavingOrder: PropTypes.bool,
    }),
    packers: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        name: PropTypes.string.isRequired,
        // Add other properties as needed
      })
    ).isRequired,
    allPackers: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        name: PropTypes.string.isRequired,
        // Add other properties as needed
      })
    ).isRequired,
    packerPlants: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        name: PropTypes.string.isRequired,
        packerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        est: PropTypes.string,
        originCountry: PropTypes.string,
        hasSpecialWtConversion: PropTypes.bool,
      })
    ).isRequired,
    allPackerPlants: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        name: PropTypes.string.isRequired,
        packerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        est: PropTypes.string,
        originCountry: PropTypes.string,
        hasSpecialWtConversion: PropTypes.bool,
      })
    ).isRequired,
    poDocuments: PropTypes.shape({
      documents: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
          name: PropTypes.string.isRequired,
          // Add other properties as needed
        })
      ).isRequired,
      purchaseOrder: PropTypes.instanceOf(Object),
    }),
    poDocTypes: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        name: PropTypes.string.isRequired,
        // Add other properties as needed
      })
    ).isRequired,
    poId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    ports: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        name: PropTypes.string.isRequired,
        // Add other properties as needed
      })
    ).isRequired,
    priceTypes: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
      })
    ).isRequired,
    productTypes: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        name: PropTypes.string.isRequired,
        // Add other properties as needed
      })
    ).isRequired,
    reasonForRejection: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        reason: PropTypes.string.isRequired,
      })
    ).isRequired,
    shippingLines: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        name: PropTypes.string.isRequired,
        // Add other properties as needed
      })
    ).isRequired,
    tags: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        value: PropTypes.string.isRequired,
      })
    ).isRequired,
    token: PropTypes.string,
    unitsOfMeasure: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        name: PropTypes.string.isRequired,
        // Add other properties as needed
      })
    ).isRequired,
    user: PropTypes.instanceOf(Object),
    users: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        name: PropTypes.string.isRequired,
        // Add other properties as needed
      })
    ).isRequired,
    voyage: PropTypes.instanceOf(Object).isRequired,
    voyageLegs: PropTypes.arrayOf(
      PropTypes.shape({
        voyageNumber: PropTypes.string,
        vesselName: PropTypes.string,
        shippingLineId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        originPortId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        destinationPortId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        eta: PropTypes.string,
        etd: PropTypes.string,
        portOfDischarge: PropTypes.string,
      })
    ).isRequired,
    endUsers: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        name: PropTypes.string.isRequired,
        // Add other properties as needed
      })
    ),
    configs: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      })
    ),
    packageWeights: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        weight: PropTypes.number.isRequired,
        unit: PropTypes.string.isRequired,
      })
    ),
    transporters: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        name: PropTypes.string.isRequired,
        // Add other properties as needed
      })
    ),
    surchargeTypes: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        name: PropTypes.string.isRequired,
        // Add other properties as needed
      })
    ),
    settingsConfig: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      })
    ),
    error: PropTypes.bool.isRequired,
    customPoDocs: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        name: PropTypes.string.isRequired,
        // Add other properties as needed
      })
    ),
    formFields: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        name: PropTypes.string.isRequired,
        // Add other properties as needed
      })
    ),
    issuesXcode: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        code: PropTypes.string.isRequired,
        description: PropTypes.string,
        category: PropTypes.string,
      })
    ),
    allIssuesXcodes: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        code: PropTypes.string.isRequired,
        description: PropTypes.string,
        category: PropTypes.string,
      })
    ),
  };

  validateSurcharges = () => {
    const { form } = this.state;
    if (form.surcharges && form.surcharges.length > 0) {
      for (const surcharge of form.surcharges) {
        if (surcharge.name === 'Miscellaneous' && !surcharge.comment) {
          return false; // Validation fails if comment is missing
        }
      }
    }
    return true;
  };

  static listToObject(list, key) {
    const configObject = list.reduce((agg, item, index) => {
      return {
        ...agg,
        [item[key]]: {
          ...item,
          index,
        },
      };
    }, {});
    return configObject;
  }

  constructor(props) {
    super(props);

    this.state = {
      isInEditMode: false,
      isGeneratingDocument: false,
      confirmationOpened: false,
      requiredFields: [
        'buyIncoterms',
        'sellIncoterms',
        'buyCurrency',
        'buyUnitOfMeasureId',
        'sellUnitOfMeasureId',
        'sellCurrency',
        // 'packerPlantId',
        'packerId',
        'priceType',
        'shipmentDateStart',
        'shipmentDateEnd',
        'arrivalDateStart',
        'arrivalDateEnd',
        'deliveryDate',
      ],
      validationErrors: {
        buyQuantity: false,
        requiredFields: { form: [], po: [], lines: [] },
        dateError: false,
      },
      form: {
        billOfLading: Constants.INITIAL_BOL,
        buyCurrency: '',
        buyIncoterms: '',
        coldstorePlannedDeliveryDate: null,
        buyUnitOfMeasureId: '',
        coldstoreDetails: Constants.INITIAL_COLDSTORE,
        confirmationOfPurchaseComments: '',
        dateType: 'shipment',
        defaultColdstoreId: get(props.currentOrder, 'grinder.defaultColdstoreId', null),
        documents: [],
        estId: '',
        exportDetails: { ...Constants.INITIAL_EXPORT_DETAILS },
        externalComments: '',
        fec: '',
        finalDestination: null,
        foreignExchangeRate: '',
        halal: false,
        onHold: false,
        internalComments: '',
        onHoldReason: '',
        isChilled: false,
        lineIndices: [],
        lines: {},
        loadContainerDetails: '',
        mtc: false,
        packerId: false,
        packerInvoiceNumber: '',
        packerInvoiceDate: null,
        packerPaidDate: null,
        packerPlantId: false,
        packerSoNumber: '',
        preferredEta: null,
        rejectedCartons: [Constants.INITIAL_REJECTED_CARTON],
        sellCurrency: '',
        sellIncoterms: '',
        sellUnitOfMeasureId: '',
        status: 'none',
        tags: [],
        voyageLegs: [],
        endUserId: get(props.currentOrder, 'endUserId', null),
        recvDeliveryDate: '',
        scheduledDeliveryTime: null,
        transporterId: null,
        surcharges: [Constants.INITIAL_SURCHARGES],
        references: [],
        additinalGrn: '',
        estimatedDeliveryDate: null,
      },
      voyage: Constants.INITIAL_VOYAGE,
      voyageUpdated: false,
      voyageDeleted: false,
      voyageLegCreate: false,
      voyageLegId: null,
      voyageLegOptions: [],
      dateValidationErrors: {
        errorMsg: {
          text: null,
          tab: null,
        },
      },
      setColdstoreStatus: false,
      updatedLines: [],
      newLines: [],
      // default value to avoid console error while the component renders
      showShipment: true,
      isShipmentDateRequiredPrompt: false,
      emailModalIsOpen: false,
      isReadyForPaymentUpdated: false,
      updatedProductionDetails: [],
      warnings: {
        packer: false,
        hasExceededSurchargeCount: false,
      },
      formAttributes: {},
      purchaseOrder: {},
      deletedIssuesXcodesIds: [],
    };
  }

  componentDidMount() {
    const {
      dispatch,
      token,
      currentOrder,
      poId,
      allPackerPlants,
      containerDepots,
      inputProducts,
      loadSizes,
      containers,
    } = this.props;

    // fetch packageWeights used for conversions
    dispatch(fetchPackageWeights());
    // fetch trucking companies
    dispatch(fetchTransporters(token));
    dispatch(fetchFormFields(token));
    dispatch(fetchSettingsConfig(token));

    if (!currentOrder || !currentOrder.id) {
      dispatch(fetchNetsuitePurchaseOrder(poId, token));
    } else {
      let adaptedPurchaseOrder = adaptServerPurchaseOrderToClient(currentOrder);

      if (adaptedPurchaseOrder.packerPlantId) {
        const packerPlant = allPackerPlants?.find(el => el.id === adaptedPurchaseOrder.packerPlantId);
        adaptedPurchaseOrder = {
          ...adaptedPurchaseOrder,
          estId: packerPlant ? packerPlant.est : '',
          packerId: packerPlant ? packerPlant.packerId : '',
        };
      }

      let voyage;
      if (currentOrder.voyage) {
        voyage = get(currentOrder, 'voyage[0]', Constants.INITIAL_VOYAGE);
      }
      const isFormOptionsLoaded =
        currentOrder && containerDepots.length && inputProducts.length && loadSizes.length && containers.length;

      this.setState({
        isFormOptionsLoaded,
        ...(voyage && { voyage }),
        setColdstoreStatus: currentOrder.status === Constants.ORDER_STATUSES.COLDSTORE,
        showShipment: !!adaptedPurchaseOrder.shipment,
        formAttributes: this.getFormAttributes(),
        purchaseOrder: convertToSnakeCase(adaptedPurchaseOrder),
      });
    }
    dispatch(fetchEntitiesData());
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      currentOrder,
      allPackerPlants,
      poDocuments,
      isSavingOrder,
      orders,
      email,
      containerDepots,
      inputProducts,
      loadSizes,
      containers,
      voyage,
      voyageLegs,
      error,
    } = this.props;
    const { form, isInEditMode, voyageUpdated, voyageDeleted, voyage: voyageFromState } = this.state;
    if (currentOrder !== prevProps.currentOrder && currentOrder && currentOrder.id) {
      let adaptedPurchaseOrder = adaptServerPurchaseOrderToClient(currentOrder);
      if (adaptedPurchaseOrder.packerPlantId) {
        const packerPlant = allPackerPlants?.find(el => el.id === adaptedPurchaseOrder.packerPlantId);
        adaptedPurchaseOrder = {
          ...adaptedPurchaseOrder,
          estId: packerPlant ? packerPlant.est : '',
          packerId: packerPlant ? packerPlant.packerId : '',
        };
      }

      // Check po for existing voyage data, or fallback to what is already in state:
      let poVoyage = get(currentOrder, 'voyage[0]', voyageFromState);

      // If the voyage has been updated, use the most recent version.
      if (voyage && voyage !== prevProps.voyage) {
        poVoyage = voyage;
      }

      this.setState({
        form: adaptedPurchaseOrder,
        ...(poVoyage && { voyage: poVoyage }),
        voyageLegOptions: voyageLegs,
        voyageUpdated: isInEditMode ? voyageUpdated : false,
        voyageDeleted: isInEditMode ? voyageDeleted : false,
        setColdstoreStatus: currentOrder.status === Constants.ORDER_STATUSES.COLDSTORE,
        showShipment: !!adaptedPurchaseOrder.shipment,
        formAttributes: this.getFormAttributes(),
      });
    }

    if (poDocuments.documents.length !== prevProps.poDocuments.documents.length) {
      if (poDocuments.purchaseOrder.lines !== undefined) {
        const poLines = poDocuments.purchaseOrder.lines.map(l => {
          return { id: l.id, sellPricePerUnit: l.sellPricePerUnit, cosPricePerUnit: l.cosPricePerUnit };
        });
        form.lines = form.lines.map(l => {
          const line = l;
          const poLine = poLines.filter(pl => pl.id === line.id);
          if (poLine.length > 0) {
            line.sellPricePerUnit = poLine[0].sellPricePerUnit;
            line.cosPricePerUnit = poLine[0].cosPricePerUnit;
          }
          return line;
        });
        Object.keys(form.linesObject).forEach(i => {
          const poLineObject = poLines.filter(pl => pl.id === form.linesObject[i].id);
          if (poLineObject.length > 0) {
            const sellPricePerUnit = poLineObject[0].sellPricePerUnit;
            const cosPricePerUnit = poLineObject[0].cosPricePerUnit;

            form.linesObject[i].sellPricePerUnit = sellPricePerUnit;
            form.linesObject[i].cosPricePerUnit = cosPricePerUnit;

            this.handleUpdateLine(i, 'sellPricePerUnit', sellPricePerUnit);
            this.handleUpdateLine(i, 'cosPricePerUnit', cosPricePerUnit);
          }
        });
      }

      this.setState({
        isGeneratingDocument: false,
        form: {
          ...form,
          documents: get(poDocuments, 'documents'),
        },
      });
    }

    if (!isSavingOrder && orders.isSavingOrder !== prevProps.orders.isSavingOrder) {
      this.setState({
        isInEditMode: false,
        updatedProductionDetails: [],
      });
    }

    if (!email.isSendingEmail && prevProps.email.isSendingEmail && !email.sendEmailError) {
      this.setState({
        emailModalIsOpen: false,
      });
    }

    const isFormOptionsLoaded =
      currentOrder && containerDepots.length && inputProducts.length && loadSizes.length && containers.length;
    if (isFormOptionsLoaded !== prevState.isFormOptionsLoaded) {
      this.setState({
        isFormOptionsLoaded,
      });
    }

    if (error !== prevProps.error && isString(error) && error.includes(GENERATED_DOCUMENT_ERROR)) {
      this.setState({
        isGeneratingDocument: false,
      });
    }
  }

  getFormAttributes = () => {
    const { currentOrder, packerPlants, packers, grinders } = this.props;
    const packerPlant = packerPlants.find(p => p.id === currentOrder.packerPlantId);
    const packer = packers.find(p => p.id === packerPlant?.packerId);
    const grinder = grinders.find(g => g.uid === currentOrder.grinderUid);

    const payload = convertToCamelCase({
      packer,
      grinder,
      packerPlant,
    });

    return analyzeBasePOForm(payload);
  };

  /* Modal close */
  handleModalClose = () => {
    const { onClose } = this.props;
    // TODO - Uncomment when targeted task resolution is working again
    // Also be sure to test for how this impacts app performance.
    // this.props.dispatch(autoResolveTasksForOrder(token, currentOrder.id, currentOrder.grinderPoNumber));
    onClose();
  };

  /* Value change handling functions [generic] START */

  handlePPUChangeValue = (key, index, e) => {
    if (isNaN(e)) {
      this.onChangeValue(key, this.state.form[key]);
      return;
    }

    // validating and limitting input to 4 decimal places
    const rounded = correctDecimalRoundingChange(e, 4);
    this.handleUpdateLine(index, key, rounded);
  };

  handlePPURoundingDecimal = (key, index, e) => {
    // format PPU input to 4 decimal places
    const { result, roundedNumber } = correctDecimalRoundingBlur(e, parseFloat(e) === parseFloat(0) ? 0 : 4);
    if (!isNaN(roundedNumber)) {
      this.handleUpdateLine(index, key, result);
    }
  };

  handleDateChange = (key, value) => {
    const { form } = this.state;
    const formCopy = { ...form };
    formCopy[key] = value;
    this.setState({ form: formCopy });
  };

  onChangeValue = (key, e) => {
    const newState = { form: cloneDeep(this.state.form) };
    const { packerPlants, packers } = this.props;

    if (['packerPlant', 'grinder'].includes(key)) {
      if (key === 'packerPlant') {
        const currentPackerPlant = packerPlants.find(p => p.id === get(this.props, 'currentOrder.packerPlantId'));
        if (e) {
          newState[key] = this.props[`${key}s`].find(el => el.id === e.value);
        }
        newState.form.estId = get(newState, 'packerPlant.est', '');
        newState.form[`${key}Id`] = e ? e.value : '';
        newState.form.packerId = get(newState, 'packerPlant.packerId', '');

        const updatedPackerPlant = packerPlants.find(p => p.id === e.value);
        if (currentPackerPlant?.hasSpecialWtConversion !== updatedPackerPlant?.hasSpecialWtConversion) {
          newState.warnings = {
            packer: true,
          };
        } else {
          newState.warnings = {
            packer: false,
          };
        }
      }
      if (key === 'grinder') {
        if (e) {
          newState[key] = this.props[`${key}s`].find(el => el.uid === e.value);
        }
        newState.form[`${key}Uid`] = e ? e.value : '';
      }
    } else if (key === 'shipment') {
      newState.form.arrival = e;
      newState.form.shipment = !e;
    }
    // if est, assign respective packerPlant
    else if (key === 'estId') {
      const estPackerPlant = packerPlants.find(p => p.est === e);
      newState.form.estId = e;
      newState.form.packerPlantId = estPackerPlant ? estPackerPlant.id : '';
      newState.form.packerId = estPackerPlant ? estPackerPlant.packerId : '';
    }
    // if packer changes, reset est and packerPlant
    else if (key === 'packerId') {
      if (newState.form.packerId !== e) {
        newState.form.packerPlantId = '';
        newState.form.estId = '';
      }
      const estPacker = packers.find(p => p.id === e);
      newState.form.packerId = estPacker ? estPacker.id : '';
    }
    // Clear FEC value if either currencies change
    else if (['buyCurrency', 'sellCurrency'].includes(key)) {
      newState.form.fec = null;
      newState.form[key] = e ? get(e, 'value', e) : '';
    }
    // readyForPayment
    else if (key === 'readyForPayment') {
      newState.form.isReadyForPaymentUpdated = true;
      newState.form.readyForPayment = e;
    } else if (key === 'endUser') {
      newState.form.endUserId = e.value;
    } else if (key === 'grinderPoNumber') {
      newState.form.grinderPoNumber = e;
    }
    // simply update key with value
    else {
      this.setState(prevState => {
        return {
          form: {
            ...prevState.form,
            [key]: e ? get(e, 'value', e) : '',
          },
        };
      });
      return null;
    }

    this.setState(newState);
  };

  handleGrossShippedWeightChange = (index, key, e) => {
    if (isNaN(e)) {
      this.onChangeValue(key, this.state.form[index][key]);
      return;
    }

    // validating and limitting input to 2 decimal places
    const updatedEvent = correctDecimalRoundingChange(e);

    this.onObjectValueChange(index, key, updatedEvent);
  };

  handleGrossShippedWeightBlur = (index, key, e) => {
    // format PPU input to 2 decimal places
    const { result, roundedNumber } = correctDecimalRoundingBlur(e);

    if (!isNaN(roundedNumber)) {
      this.onObjectValueChange(index, key, result);
    }
  };

  onObjectValueChange = (objectName, key, value) => {
    const newState = cloneDeep(this.state);

    /* TODO
      currently there are more fields that needs validation -hence switch case to cater other new date inputs in near future
      aim is to get rid of switch and use function similar to shipmentDateValidations
    */
    switch (key) {
      case 'dateOfEntry':
        if (value < this.state.form.shipmentDateStart) {
          newState.dateValidationErrors.errorMsg = {
            text: 'Coldstore Date of Entry cannot happen before Shipment Start date',
            tab: objectName,
          };
        } else {
          newState.dateValidationErrors.errorMsg = { text: null, tab: null };
        }
        break;
      default:
        break;
    }

    if (!objectName || objectName === 'form') {
      set(newState, `form.${key}`, value || null);
    } else {
      set(newState, `form.${objectName}.${key}`, value || null);
    }
    this.setState(newState);
  };

  handleChangeObjectList = (parentKey, listName, key, index, value, setString = false) => {
    const { form } = this.state;
    let formattedValue = value || '';

    if (value === 0) {
      formattedValue = value;
    } else if (value && value.toString && setString === true) {
      formattedValue = value.toString();
    } else if (setString) {
      formattedValue = '';
    }

    let list = [];
    if (parentKey === 'form') {
      list = get(form, listName, []);
    } else {
      list = get(form, `${parentKey}.${listName}`, []);
    }

    const updatedList = list.map((item, idx) => {
      if (idx === index) {
        return {
          ...item,
          [key]: formattedValue,
        };
      }
      return item;
    });

    if (parentKey === 'form') {
      return this.setState({
        form: {
          ...form,
          [listName]: updatedList,
        },
      });
    }

    return this.setState({
      form: {
        ...form,
        [parentKey]: {
          ...form[parentKey],
          [listName]: updatedList,
        },
      },
    });
  };

  handleStartDateValueChange = (title, key, value) => {
    const stateCopy = cloneDeep(this.state);
    stateCopy.form[key] = value;
    // validation
    // shipment dates
    let errorMsg = null;
    if (value) {
      errorMsg = shipmentDateValidations(title, key, value, this.state.form);
      stateCopy.dateValidationErrors.errorMsg = { text: errorMsg, tab: 'orderDetails' };
    }
    // production date [different validation functions for production and shipment as components/view are different]
    // FIXME, can accomodate only one error at a time
    if (stateCopy.dateValidationErrors.errorMsg.text === null) {
      errorMsg = productionDateValidations(title, key, value, this.state.form);
      stateCopy.dateValidationErrors.errorMsg = { text: errorMsg, tab: 'purchaseOrderLines' };
    }

    if (errorMsg !== null && !errorMsg) {
      // Pre-populate end date to a two-week interval for shipment & arrival dates
      let shouldPrePopulate = false;
      let endFieldName = '';
      if (['arrivalDateStart', 'shipmentDateStart'].includes(key)) {
        endFieldName = key.replace('Start', 'End');
        // Only pre-populate field if it is empty
        shouldPrePopulate = !this.state.form[endFieldName];
      }

      if (shouldPrePopulate) {
        stateCopy.form[endFieldName] = moment(value).add(2, 'weeks').format('YYYY-MM-DD');
      }
    }
    this.setState(stateCopy);
  };

  onBooleanValueChange = (key, index) => {
    const { form } = this.state;

    if (index !== undefined) {
      const line = get(form, `linesObject.${index}`);
      if (key === 'frozen' || key === 'chilled') {
        return this.setState({
          form: {
            ...form,
            linesObject: {
              ...form.linesObject,
              [index]: {
                ...line,
                chilled: !line.chilled,
                frozen: !line.frozen,
              },
            },
          },
        });
      }

      return this.setState({
        form: {
          ...form,
          linesObject: {
            ...form.linesObject,
            [index]: {
              ...line,
              [key]: !line.key,
            },
          },
        },
      });
    }

    return this.setState({
      form: {
        ...form,
        [key]: !form[key],
      },
    });
  };

  handleToggleChange = value => {
    // can have either arrival or shipment values
    const toggleCounterValue = value === 'shipment' ? 'arrival' : 'shipment';

    const { form } = this.state;

    this.setState(
      {
        showShipment: value === 'shipment',
        form: {
          ...form,
          [value]: true,
          [toggleCounterValue]: false,
        },
      },
      () => {
        const updatedForm = { ...this.state.form };
        // clear value on counter part of the current toggle if available
        const startDate = `${toggleCounterValue}DateStart`;
        const endDate = `${toggleCounterValue}DateEnd`;
        this.setState({
          form: {
            ...updatedForm,
            [startDate]: null,
            [endDate]: null,
          },
        });
      }
    );
  };
  /* Value change handling functions [generic] END */

  /* PO Line functions start */
  handleAddLine = () => {
    const {
      form,
      form: { lineCounter, linesObject, lineIndices },
    } = this.state;

    const newLineIndex = lineCounter + 1;
    const newLine = createNewLine(newLineIndex);
    const updatedLinesObject = {
      ...linesObject,
      [newLineIndex]: newLine,
    };

    this.setState({
      form: {
        ...form,
        lineCounter: newLineIndex,
        linesObject: updatedLinesObject,
        lines: Object.values(updatedLinesObject),
        lineIndices: [...lineIndices, newLineIndex],
      },
    });
  };

  handleUpdateLine = (lineIndex, fieldName, value) => {
    const {
      form,
      form: { linesObject },
      newLines,
    } = this.state;
    const { inputProducts } = this.props;

    const line = linesObject[lineIndex];
    const updatedLine = { ...line };
    updatedLine.hedgeItems = { ...line.hedgeItems };
    set(updatedLine, fieldName, value);
    const copyUpdatedLines = cloneDeep(this.state.updatedLines);
    // reset packerIngredientDescription if ingredient changes
    if (fieldName === 'inputProductUid') {
      updatedLine.packerIngredientDescription = inputProducts.find(ip => ip.uid === value).description;
    }

    /* saves updated lines objects in a list on state
     this list will be referred on final save/submit to dispatch backend calls */
    if (updatedLine.id && !isEqual(line, updatedLine)) {
      const updatedLineIndex = copyUpdatedLines.findIndex(l => l.id === updatedLine.id);
      if (updatedLineIndex > -1) {
        copyUpdatedLines[updatedLineIndex] = updatedLine;
      } else {
        copyUpdatedLines.push(updatedLine);
      }
      this.setState({ updatedLines: copyUpdatedLines });
    }

    /* saves new lines objects in a list on state
     this list will be referred on final save/submit to dispatch backend calls */
    if (!updatedLine.id && updatedLine.inputProductUid && updatedLine.buyQuantity && updatedLine.buyPricePerUnit) {
      /* assign order packerPlantId to the newly created line
        place to change when lines on an order can be from
        different packerPlant establishment
      */
      let copyNewLines = cloneDeep(newLines);
      updatedLine.packerPlantId = form.packerPlantId;
      if (copyNewLines.some(l => l.inputProductUid === updatedLine.inputProductUid)) {
        // This line has already been added.
        copyNewLines = copyNewLines.map(l => {
          if (l.inputProductUid === updatedLine.inputProductUid) {
            return updatedLine;
          }
          return l;
        });
      } else {
        copyNewLines.push(updatedLine);
      }

      this.setState({ newLines: copyNewLines });
    }
    const updatedLinesObject = {
      ...linesObject,
      [lineIndex]: updatedLine,
    };
    this.setState({
      form: {
        ...form,
        linesObject: updatedLinesObject,
        lines: Object.values(updatedLinesObject),
      },
    });
  };

  handleRemoveLine = (lineIdx, deletedLine) => {
    const {
      form: { lines, lineIndices, linesObject },
      form,
      updatedLines,
    } = this.state;

    const updatedLineIndices = !deletedLine.id ? lineIndices.filter(idx => idx !== lineIdx) : lineIndices;
    const deletedLineIndex = lines.findIndex(line => line.idx === lineIdx);
    const linesObjectClone = cloneDeep(linesObject);

    if (lines[deletedLineIndex] && deletedLine.id) {
      lines[deletedLineIndex].active = false;
    } else {
      delete lines[deletedLineIndex];
    }

    if (linesObjectClone[lineIdx] && deletedLine.id) {
      linesObjectClone[lineIdx].active = false;
    } else {
      delete linesObjectClone[lineIdx];
    }

    // Remove line and shipment port mark map.
    if (form.billOfLading && form.billOfLading.shipmentPortMarks) {
      form.billOfLading.shipmentPortMarks.forEach((mark, markIdx) => {
        form.billOfLading.shipmentPortMarks[markIdx].purchaseOrderLineIds = form.billOfLading.shipmentPortMarks[
          markIdx
        ].purchaseOrderLineIds.filter(id => id !== deletedLine.id);
      });
    }
    this.setState(
      {
        form: {
          ...form,
          lines,
          lineIndices: updatedLineIndices,
          linesObject: linesObjectClone,
        },
      },
      () => {
        // this is for any lines that aren't yet added to db
        if (!deletedLine.id) return;

        const copyUpdatedLines = cloneDeep(updatedLines);
        const deletedUpdatedLineIndex = copyUpdatedLines.findIndex(line => line.id === deletedLine.id);
        if (deletedUpdatedLineIndex > -1) {
          copyUpdatedLines[deletedUpdatedLineIndex] = { ...deletedLine, active: false };
        } else {
          copyUpdatedLines.push({ ...deletedLine, active: false });
        }
        this.setState({ updatedLines: copyUpdatedLines });
      }
    );
  };

  handlePOLineUpdates = async () => {
    const { dispatch, token } = this.props;

    const updatedLines = cloneDeep(this.state.updatedLines);
    const newLines = cloneDeep(this.state.newLines);

    // sort lines to make sure that cancelled lines and lines with the least buy quantity values are updated first
    updatedLines.sort((a, b) => {
      if (!a.active && b.active) return -1;
      if (a.active && !b.active) return 1;
      return a.buyQuantity - b.buyQuantity;
    });

    // prevent race condition where the request to add a new line is handled before updating the existing lines

    for (const updatedLine of updatedLines) {
      await dispatch(
        editInternationalLine({
          purchaseOrderId: this.state.form.id,
          line: updatedLine,
          token,
        })
      );
    }

    for (const newLine of newLines) {
      await dispatch(addInternationalLine({ purchaseOrderId: this.state.form.id, line: newLine, token }));
    }

    // reset newLines once they are added to db
    // TODO handle it better
    this.setState({ newLines: [] });
    this.setState({ updatedLines: [] });
  };

  onProductionDetailChange = (lineIdx, lineId, productionDetails, toDeleteProductionDetailsIds) => {
    const {
      form,
      form: { linesObject },
    } = this.state;

    const line = linesObject[lineIdx];
    const updatedLine = { ...line, productionDetails };

    (prevState => {
      const prevStateUpdatedProductionDetails = prevState.updatedProductionDetails;
      const existingUpdateIndex = prevStateUpdatedProductionDetails.findIndex(
        updatedProductionDetail => updatedProductionDetail.lineId === lineId
      );

      const productionDetailUpdate = { lineId, productionDetails, toDeleteProductionDetailsIds };
      if (existingUpdateIndex > -1) {
        productionDetailUpdate.toDeleteProductionDetailsIds = uniq([
          ...prevStateUpdatedProductionDetails[existingUpdateIndex].toDeleteProductionDetailsIds,
          ...productionDetailUpdate.toDeleteProductionDetailsIds,
        ]);
        prevStateUpdatedProductionDetails.splice(existingUpdateIndex, 1);
      }

      this.setState({
        updatedProductionDetails: [...prevStateUpdatedProductionDetails, productionDetailUpdate],
      });
    })(this.state);

    const updatedLinesObject = {
      ...linesObject,
      [lineIdx]: updatedLine,
    };
    this.setState({
      form: {
        ...form,
        linesObject: updatedLinesObject,
        lines: Object.values(updatedLinesObject),
      },
    });
  };

  handleProductionDetailsUpdate = async () => {
    this.state.updatedProductionDetails.forEach(lineUpdate => {
      const toDeleteIds = get(lineUpdate, 'toDeleteProductionDetailsIds', []);
      if (toDeleteIds.length) {
        this.props.dispatch(deleteLineProductionDetails(lineUpdate.lineId, toDeleteIds, this.props.token));
      }

      const changedProductionDetails = get(lineUpdate, 'productionDetails', []);
      if (changedProductionDetails.length) {
        const snaked = convertToSnakeCase(changedProductionDetails).filter(x => !toDeleteIds?.includes(x.id));
        // Remove null value fiels from payload
        // Note - estimated_date is on schema, but not an editable field, so BE
        // will throw an error if it is not already present.
        snaked.forEach((pd, idx) => {
          Object.entries(pd).forEach(([key, value]) => {
            if (value === null) {
              delete snaked[idx][key];
            }
          });
        });
        this.props.dispatch(addOrUpdateLineProductionDetails(lineUpdate.lineId, snaked, this.props.token));
      }
    });
  };

  handleCLUpdate = async (lineIdx, fieldName, value) => {
    const {
      form,
      form: { linesObject },
    } = this.state;
    const line = linesObject[lineIdx];
    const updatedLine = { ...line, fieldName: value };

    const updatedLinesObject = {
      ...linesObject,
      [lineIdx]: updatedLine,
    };
    this.setState({
      form: {
        ...form,
        linesObject: updatedLinesObject,
        lines: Object.values(updatedLinesObject),
      },
    });
  };

  handleTemperatureUpdate = async (lineIdx, temperatures, opTempType) => {
    const {
      form,
      form: { linesObject },
    } = this.state;
    const line = linesObject[lineIdx];
    const updatedLine = { ...line, temperatures, opTempType };

    const updatedLinesObject = {
      ...linesObject,
      [lineIdx]: updatedLine,
    };
    this.setState({
      form: {
        ...form,
        linesObject: updatedLinesObject,
        lines: Object.values(updatedLinesObject),
      },
    });
  };
  /* PO Line functions end */

  /* BOL shipment mark change handling START */
  addMark = () => {
    const { form } = this.state;

    const firstLine = form.lines.find(line => line.id && line.active);
    const newMark = {
      mark: '',
      index: form.billOfLading.shipmentPortMarks.length,
      purchaseOrderLineIds: firstLine ? [firstLine.id] : [],
    };
    this.setState({
      form: {
        ...form,
        billOfLading: {
          ...form.billOfLading,
          shipmentPortMarks: [...(form.billOfLading.shipmentPortMarks || []), newMark],
        },
      },
    });
  };

  deleteMark = deletedMark => {
    const { form } = this.state;
    const updatedMarks = form.billOfLading.shipmentPortMarks.filter(marks => marks.id !== deletedMark.id);
    this.setState({
      form: {
        ...form,
        billOfLading: {
          ...form.billOfLading,
          shipmentPortMarks: [...(updatedMarks || [])],
        },
      },
    });
  };

  handleShipmentPortMarkDelete = async () => {
    const actualShipmentPortMarks = cloneDeep(get(this.props, 'currentOrder.billOfLading.shipmentPortMarks', []));
    const updatedShipmentPortMarks = cloneDeep(get(this.state, 'form.billOfLading.shipmentPortMarks', []));
    const removedPortMarks = actualShipmentPortMarks.filter(actual => {
      return !updatedShipmentPortMarks.some(updated => {
        return actual.id === updated.id;
      });
    });
    if (removedPortMarks.length) {
      this.props.dispatch(removeMarks(this.props.currentOrder, removedPortMarks, this.props.token));
    }
  };
  /* BOL shipment mark change handling END */

  /* Coldstore, rejected cartons START */

  handleAtColdstore = value => {
    const { form } = this.state;
    this.setState({ setColdstoreStatus: value });
    // inspection date removed and status is coldstore
    // update to Ordered status
    if (!value && this.state.form.status === Constants.ORDER_STATUSES.COLDSTORE) {
      this.setState({ form: { ...form, status: Constants.ORDER_STATUSES.ORDERED } });
    }
  };

  removeFromList = (listName, index) => {
    const { form } = this.state;
    const list = get(form, listName, []);
    list.splice(index, 1);
    this.setState({
      form: {
        ...form,
        [listName]: list.filter((itm, idx) => idx !== index),
      },
    });
  };

  handleAddRejectedCarton = () => {
    const { form } = this.state;
    const rejectedCartons = get(form, 'rejectedCartons', []);

    const updatedCartons = [
      ...rejectedCartons,
      {
        ...Constants.INITIAL_REJECTED_CARTON,
        index: rejectedCartons.length,
      },
    ];
    this.setState({
      form: {
        ...form,
        rejectedCartons: updatedCartons,
      },
    });
  };

  handleAddSurcharge = () => {
    const { form, warnings } = this.state;
    const surcharges = get(form, 'surcharges', []);
    if (surcharges.length < 3) {
      const updatedSurcharges = [
        ...surcharges,
        {
          ...Constants.INITIAL_SURCHARGES,
        },
      ];
      this.setState({
        form: {
          ...form,
          surcharges: updatedSurcharges,
        },
        warnings: {
          ...warnings,
          hasExceededSurchargeCount: false,
        },
      });
    } else {
      this.setState({ warnings: { ...warnings, hasExceededSurchargeCount: true } });
    }
  };

  handleRemoveObjectListItem = (objectName, listName, index) => {
    const { form, warnings } = this.state;
    if (objectName === 'form') {
      const list = get(this.state.form, listName, []);
      const updatedList = list.filter((item, idx) => idx !== index);
      if (listName === 'surcharges') {
        // check if warning has to be turned off
        if (updatedList.length <= 3) {
          this.setState({ warnings: { ...warnings, hasExceededSurchargeCount: false } });
        }
      }
      return this.setState({
        form: {
          ...form,
          [listName]: updatedList,
        },
      });
    }

    const list = objectName === 'form' ? form : get(form, `${objectName}.${listName}`, []);
    const updatedList = list.filter((item, idx) => idx !== index);
    return this.setState({
      form: {
        ...form,
        [objectName]: {
          ...form[objectName],
          [listName]: updatedList,
        },
      },
    });
  };
  /* Coldstore, rejected cartons END */

  /* Voyage, Voyage leg START */

  /* voyage leg  */
  onVoyageLegValueChange = (index, value, key) => {
    const { form } = this.state;
    const updatedVoyageLegs = [...form.voyageLegs];
    if (key) {
      updatedVoyageLegs[index][key] = value;
    } else {
      updatedVoyageLegs[index] = value;
    }
    this.setState({
      form: {
        ...form,
        voyageLegs: updatedVoyageLegs,
      },
    });
  };

  addVoyageLeg = () => {
    const { form } = this.state;
    const newVoyageLeg = {
      voyageNumber: '',
      vesselName: '',
      shippingLineId: '',
      originPortId: '',
      destinationPortId: '',
      eta: '',
      etd: '',
      portOfDischarge: '',
    };
    this.setState({
      form: {
        ...form,
        voyageLegs: [...form.voyageLegs, newVoyageLeg],
      },
    });
  };
  /* --- voyage leg not in use yet END --*/

  /* -- voyage --*/
  onVoyageValueChange = (voyage, value, key) => {
    // TODO handle this in a better way
    const { validationErrors } = this.state;
    if (key === 'eta' && voyage.etd && value < voyage.etd) {
      return this.setState({
        validationErrors: {
          ...validationErrors,
          dateError: true,
        },
      });
    }

    if (key === 'etd' && voyage.eta && value > voyage.eta) {
      return this.setState({
        validationErrors: {
          ...validationErrors,
          dateError: true,
        },
      });
    }

    if (key === 'ata' && voyage.atd && value < voyage.atd) {
      return this.setState({
        validationErrors: {
          ...validationErrors,
          atDateError: true,
          dateError: true,
        },
      });
    }

    if (key === 'atd' && voyage.ata && value > voyage.ata) {
      return this.setState({
        validationErrors: {
          ...validationErrors,
          atDateError: true,
          dateError: true,
        },
      });
    }

    return this.setState(prevState => {
      return {
        validationErrors: {
          ...validationErrors,
          dateError: false,
        },
        voyage: {
          ...prevState.voyage,
          [key]: value,
        },
        voyageUpdated: true,
      };
    });
  };

  deleteVoyage = () => {
    // eslint-disable-next-line no-alert
    const confirmation = window.confirm('Are you sure you want to delete voyage?');
    if (confirmation) {
      // for sending delete dispatch, callback to add delay for the form to refresh
      this.setState({
        voyageLegCreate: true,
        voyageUpdated: true,
        voyage: Constants.INITIAL_VOYAGE,
      });
    }
  };

  // returns if any errors encountered as boolean
  handleVoyageUpdates = async () => {
    const { voyageDeleted, voyageUpdated, voyage } = this.state;
    const { currentOrder, token, dispatch } = this.props;
    if (voyageDeleted) {
      this.props.dispatch(deleteVoyage(this.props.currentOrder.id, this.props.token));
      return;
    }

    if (voyageUpdated) {
      const {
        dischargePortId,
        eta,
        etd,
        ata,
        atd,
        id,
        loadPortId,
        shippingLineId,
        transhipPortId,
        vesselName,
        voyageNumber,
      } = voyage;

      const payload = convertToSnakeCase({
        id,
        vesselName,
        voyageNumber,
        shippingLineId,
        loadPortId,
        dischargePortId,
        eta,
        etd,
        ata,
        atd,
        transhipPortId,
        voyageLegId: null,
        voyageLegCreate: false,
      });

      dispatch(addOrUpdateVoyage(currentOrder.id, payload, token));
    }
  };
  /* Voyage, Voyage leg END */

  /* Form actions START */
  onEditClick = () => {
    const { dispatch, token, currentOrder } = this.props;

    // Re-fetch PO as some of their properties might have been changed else where
    dispatch(fetchEntitiesData());
    if (currentOrder?.id) {
      dispatch(fetchNetsuitePurchaseOrder(currentOrder?.id, token));
    }

    const { isInEditMode } = this.state;
    this.setState({ isInEditMode: !isInEditMode });
  };

  validateVoyageUpdates = () => {
    const { voyageUpdated, voyage } = this.state;
    if (voyageUpdated) {
      // Form is not valid if:
      if (voyage.eta < voyage.etd) {
        return true;
      }
    }
    return false;
  };

  validateForm = form => {
    /* Required field errors pattern for edit form
    validationErrors: {
      form: [
        'fieldName',
      ],
      lines:{
        idx_1: [lineFieldName, ],
        idx_2:[lineFieldName, ]
      }
     } */
    const { formErrors, linesErrors } = validateEditOrderForm(form, this.props.configs);
    const validationErrors = cloneDeep(this.state.validationErrors);
    const hasNoLinesErrors = Object.values(linesErrors).every(err => Array.isArray(err) && err.length === 0);

    if ((formErrors && formErrors.length) || !hasNoLinesErrors) {
      validationErrors.requiredFields = {
        form: formErrors,
        lines: linesErrors,
      };
      return validationErrors;
    }
    return false;
  };

  validateAndPreparePayload = () => {
    const form = { ...this.state.form };

    if (
      this.state.setColdstoreStatus ||
      // An order is considerd COLDSTORE if a inspection date has been entered. This is a workaround
      // to handle edge cases where the componentDidUpdate function sets the order status as a side effect of the
      // finished post request. For example during a document upload.
      form?.coldstoreDetails?.inspectionDate
    ) {
      if (form.status === Constants.ORDER_STATUSES.ORDERED || form.status === Constants.ORDER_STATUSES.ON_THE_WATER) {
        form.status = Constants.ORDER_STATUSES.COLDSTORE;
      }
    }
    const payload = prepareInternationalOrderPatch(form);

    /* validates form [includes requiredFields, po lines, voyage]
      PLACE HOLDER for any last checks
    */
    const validationErrors = this.validateForm(form);
    const voyageUpdatesHasError = this.validateVoyageUpdates();
    const hasErrors = voyageUpdatesHasError || validationErrors || this.state.dateValidationErrors.errorMsg.text;
    return {
      hasErrors,
      payload,
      validationErrors: {
        ...validationErrors,
        dateError: voyageUpdatesHasError,
      },
    };
  };

  handleIssueXcode = (index, field, value) => {
    const { purchaseOrder } = this.state;
    const { allIssuesXcodes: allIssuesXcodesOption } = this.props;
    const updatedPurchaseOrder = cloneDeep(purchaseOrder);

    if (field === 'code') {
      if (updatedPurchaseOrder.issues_xcodes[index][field] !== value) {
        const { category } = updatedPurchaseOrder.issues_xcodes[index];
        const issueXcode = allIssuesXcodesOption.find(ix => ix.code === value && ix.category === category);
        updatedPurchaseOrder.issues_xcodes[index].issue_xcode_id = issueXcode?.id;
      }
    }

    if (field === 'category') {
      updatedPurchaseOrder.issues_xcodes[index].issue_xcode_id = null;
      updatedPurchaseOrder.issues_xcodes[index].code = null;
    }

    updatedPurchaseOrder.issues_xcodes[index][field] = value;
    this.setState({ purchaseOrder: updatedPurchaseOrder, orderChanged: true });
  };

  handleAddIssueXcode = () => {
    const { purchaseOrder } = this.state;
    const updatedPurchaseOrder = cloneDeep(purchaseOrder);
    updatedPurchaseOrder.issues_xcodes.push({ issue_xcode_id: '', description: '' });
    this.setState({ purchaseOrder: updatedPurchaseOrder, orderChanged: true });
  };

  handleDeleteIssueXcode = index => {
    const { purchaseOrder } = this.state;
    const { deletedIssuesXcodesIds } = this.state;
    const updatedPurchaseOrder = cloneDeep(purchaseOrder);

    if (updatedPurchaseOrder.issues_xcodes[index].id) {
      this.setState({
        deletedIssuesXcodesIds: [...deletedIssuesXcodesIds, updatedPurchaseOrder.issues_xcodes[index].id],
      });
    }

    updatedPurchaseOrder.issues_xcodes.splice(index, 1);

    this.setState({
      purchaseOrder: updatedPurchaseOrder,
    });
  };

  saveDraftOrder = async () => {
    const { hasErrors, payload, validationErrors } = this.validateAndPreparePayload();
    const { token, dispatch } = this.props;
    const { form, purchaseOrder } = this.state;

    if (hasErrors) {
      this.setState({
        validationErrors,
      });
      return;
    }

    // validate issue xcodes
    const issuesXcodesErrors = [];
    purchaseOrder.issues_xcodes.forEach((issue, index) => {
      if (!issue.issue_xcode_id) {
        issuesXcodesErrors.push({ key: 'Load Notes', value: `${index + 1}` });
      }
    });

    if (issuesXcodesErrors.length) {
      validationErrors.requiredFields = {
        ...validationErrors.requiredFields,
        issuesXcodes: issuesXcodesErrors,
      };

      this.setState({
        validationErrors,
      });
      return;
    }

    // FixME, this is a temp solution
    await this.handlePOLineUpdates();
    await this.handleProductionDetailsUpdate();
    await this.handleShipmentPortMarkDelete();
    await this.handleVoyageUpdates();
    // TODO, add a refetch order logic
    await dispatch(draftOrder(payload, form.id, token));
  };

  saveEditedOrder = async () => {
    const { hasErrors, payload, validationErrors } = this.validateAndPreparePayload();
    const { token, dispatch } = this.props;
    const { form, purchaseOrder, deletedIssuesXcodesIds } = this.state;

    if (hasErrors) {
      this.setState({
        validationErrors,
      });
      return;
    }

    // Validate surcharges
    if (!this.validateSurcharges()) {
      this.setState({
        validationErrors: {
          ...validationErrors,
          surchargeError: 'Comment is mandatory for Miscellaneous fees.',
        },
      });
      return;
    }

    // validate issue xcodes
    const issuesXcodesErrors = [];
    purchaseOrder.issues_xcodes.forEach((issue, index) => {
      if (!issue.issue_xcode_id) {
        issuesXcodesErrors.push({ key: 'Load Notes', value: `${index + 1}` });
      }
    });

    if (issuesXcodesErrors.length) {
      validationErrors.requiredFields = {
        ...validationErrors.requiredFields,
        issuesXcodes: issuesXcodesErrors,
      };

      this.setState({
        validationErrors,
      });
      return;
    }

    // FixME, this is a temp solution
    await this.handlePOLineUpdates();
    await this.handleProductionDetailsUpdate();
    await this.handleShipmentPortMarkDelete();
    await this.handleVoyageUpdates();
    // TODO, add a refetch order logic
    let updated_payload = payload;

    if (form.status === Constants.ORDER_STATUSES.DRAFT) {
      updated_payload = {
        ...updated_payload,
        status: 'ordered',
      };
    }

    if (purchaseOrder.issues_xcodes && purchaseOrder.issues_xcodes.length > 0)
      await dispatch(
        addOrUpdatePurchaseOrderAssociatedModel({
          purchaseOrderId: purchaseOrder.id,
          data: purchaseOrder.issues_xcodes?.map(issue => ({
            ...(issue.id && { id: issue.id }),
            issue_xcode_id: issue.issue_xcode_id,
            description: issue.description || '',
          })),
          modelName: 'issues_xcodes',
          token,
        })
      );

    if (deletedIssuesXcodesIds && deletedIssuesXcodesIds.length > 0)
      await dispatch(
        deletePurchaseOrderAssociatedModel({
          purchaseOrderId: purchaseOrder.id,
          toDeleteIds: deletedIssuesXcodesIds,
          modelName: 'issues_xcodes',
          token,
        })
      );

    await dispatch(saveOrder(updated_payload, form.id, token));
    this.handleModalClose();
  };

  saveAndSend = () => {
    const { hasErrors, payload } = this.validateAndPreparePayload();
    payload.status = Constants.ORDER_STATUSES.ORDERED;
    if (hasErrors) {
      document.getElementById('scrollToTop').scrollIntoView({
        behavior: 'smooth',
      });
    } else {
      // FixME, this is a temp solution
      this.handlePOLineUpdates();
      this.handleProductionDetailsUpdate();
      this.handleShipmentPortMarkDelete();
      this.props.dispatch(submitOrder(payload, this.state.form.id, true, this.props.token));
    }
  };

  startSubmitOrder = () => {
    this.setState({ confirmationOpened: true });
  };

  cancelOrder = () => {
    // eslint-disable-next-line no-alert
    const confirm = window.confirm('Are you sure you want to cancel this order?');
    if (confirm) {
      this.props.dispatch(removeOrder(this.state.form, this.props.token));
      this.props.dispatch(resetModalOrder());
    }
  };

  exportToExcel = () => {
    exportToExcelFile(this.state.form, this.props);
  };

  closeConfirmationOrderModal = shouldReset => {
    this.setState({ confirmationOpened: false });

    if (shouldReset) {
      this.props.dispatch(resetModalOrder());
    }
  };

  handleChangeValue = event => {
    const { name, value, checked, type } = event.target;

    if (type === 'checkbox' || type === 'radio') {
      this.onChangeValue(name, checked);
    } else {
      this.onChangeValue(name, value);
    }
  };

  /* Form actions END */

  render() {
    const {
      confirmationOpened,
      dateValidationErrors,
      editing,
      emailModalIsOpen,
      form,
      isFormOptionsLoaded,
      isGeneratingDocument,
      isInEditMode,
      validationErrors,
      purchaseOrder,
      formAttributes,
      // grinderOptionsFIlteredByEndUser,
      // filteredGrinderOptions,
    } = this.state;
    const isFrozenProduct = !form.isChilled;
    const { requiredFields } = validationErrors;
    const {
      configs,
      containerDepots,
      containerReturns,
      containers,
      currentOrder,
      email,
      grinders,
      allGrinders,
      inputProducts,
      allInputProducts,
      loadSizes,
      orders,
      packerPlants,
      allPackerPlants,
      packers,
      allPackers,
      ports,
      shippingLines,
      settingsConfig,
      tags,
      users,
      user,
      endUsers,
      packageWeights,
      transporters,
      unitsOfMeasure,
      priceTypes,
      formFields,
      issuesXcode,
      allIssuesXcodes,
    } = this.props;

    const hasVoyageDetails = formAttributes.HAS_VOYAGE_DETAILS;

    const isAllowedToEdit =
      !editing &&
      form.status !== Constants.ORDER_STATUSES.CANCELLED &&
      user.organisation.orgType.indexOf('grinder') === -1;

    const isAllowedToManage =
      user.organisation.orgType.indexOf('grinder') === -1 && user.organisation.orgType.indexOf('packer') === -1;

    const isAllowedToCancel =
      isAllowedToManage && currentOrder && currentOrder.status !== Constants.ORDER_STATUSES.CANCELLED;

    const grinder = allGrinders.find(el => el.uid === form.grinderUid);

    const packerPlantOptions = packerPlants.map(el => {
      return { value: el.id, label: el.name };
    });

    const packerId = get(form, 'packerId');
    const packerPlantOptionsByPacker = packerId
      ? packerPlants
          .filter(pp => pp.packerId === packerId)
          .map(el => ({
            value: el.id,
            label: el.name,
          }))
      : packerPlants.map(el => ({ value: el.id, label: el.name }));

    const sortedPackerPlantOptionsByPacker = packerPlantOptionsByPacker?.sort((a, b) => (a.label > b.label ? 1 : -1));

    const packerOptions = packers.map(el => {
      return { value: el.id, label: el.name };
    });
    const sortedPackerOptions = packerOptions?.sort((a, b) => (a.label > b.label ? 1 : -1));
    const buyerOptions = constructBuyerOptions(buyersSelector(users), user);

    const orderPackerPlant = packerPlants.find(pp => pp.id === form.packerPlantId);
    const packerPlantOriginCountry = orderPackerPlant ? orderPackerPlant.originCountry : ''; // used to filter packerPlant product ingredients master data

    const grinderDestinationCountry = grinder ? grinder.destinationCountry : '';

    const rejectedCartons = get(form, 'rejectedCartons', []);

    const hasAsianGrinder = checkForAsianGrinder(grinderDestinationCountry?.toLowerCase());

    const grinderIsChinese = grinderDestinationCountry?.toLowerCase() === Constants.COUNTRIES.CHINA;

    const exportDetails = get(form, 'exportDetails');

    // rules for selecting other export details fields for certain grinder destination countries
    const isISFRequired = grinderDestinationCountry?.toLowerCase() === Constants.COUNTRIES.USA;
    const isPermitNumberRequired = grinderDestinationCountry?.toLowerCase() === Constants.COUNTRIES.PHILIPINES;

    const { isColdstoreRequired } = currentOrder;
    const isAUSDomestic = isAustraliaDomesticOrder({ grinder: grinder || {}, packerPlant: orderPackerPlant || {} });
    const isEUDomestic = isEuropeDomesticOrder({ grinder: grinder || {}, packerPlant: orderPackerPlant || {} });
    const isUSADomestic = isAmericanDomesticOrder({ grinder: grinder || {}, packerPlant: orderPackerPlant || {} });
    const isGeneralDomestic = isGeneralDomesticOrder({
      grinder: grinder || {},
      packerPlant: orderPackerPlant || {},
    });

    const isDomestic = isAUSDomestic || isUSADomestic || isGeneralDomestic;

    const defaultColdstoreID = get(this.props.currentOrder, 'grinder.defaultColdstoreId', null);
    const labelOptions = tags && tags?.map(l => ({ label: l.value, value: l.value }));

    const containerOptions = formatOptionsFromFormFields(containers);
    const transporterOptions = transporters?.map(element => ({
      value: element.id,
      label: element.name,
    }));

    if (orders.isSavingOrder) {
      TopLoaderService.start();
    } else {
      TopLoaderService.end();
    }

    const endUserOptionsFilteredByGrinder = filterEndUserOptionsByGrinder({ value: grinder?.uid }, endUsers);
    const endUserValue = endUsers.find(obj => obj.id === form.endUserId);

    const endUserCurrent = {
      label: endUserValue?.legalName,
      value: endUserValue?.id,
    };

    const internalPoLabel = getInternalPoLabel(configs);
    const deliveryDateEditable = isDeliveryDateEditable(configs);
    const hasManualSurchargeFee = checkIfManualSurchargesApply(configs);
    const IsValidUSDomesticOrderFlag = IsValidUSDomesticOrder(configs);
    const IsActiveCourierTrackingNo = showCourierTrackingNo(configs);
    const IsActiveIsfFileDate = showIsfFileDate(configs);
    const IsActiveUsCustomsEntryNumber = showUsCustomsEntryNumber(configs);
    const IsActiveActualDateOfArrival = showActualDateOfArrival(configs);
    const IsActiveActualDateOfDeparture = showActualDateOfDeparture(configs);
    const IsActiveDocsSentToBank = showDocsSentToBank(configs);
    const DocsSentCustomerFieldName = getDocsSentToCustomFieldName(configs);
    const isEditableGrinderPoNumber =
      grinder?.poStrategy === Constants.PO_STRATEGY.MANUAL ||
      grinder?.poStrategy === Constants.PO_STRATEGY.SEQUENCE_EDITABLE;
    const poHedgingEnabled = isPoHedgingEnabled(this.props.configs);
    const surchargeTypeOptions = getOptionsFromSettingsConfig(settingsConfig, 'SURCHARGES', null, true);
    // const transporterValue = transporterOptions.filter(trans => trans?.value === form.transporterId);
    const isReadOnlyField = isColdstoreRequired;
    const showUSADomesticFreshnessField = false;

    const filteredInputProducts = filterInputProducts(inputProducts, form);

    const requiredFieldsForm = getOr(requiredFields, 'form', []);

    const defaultOrderTypeName = getDefaultValueFromConfigs(configs, 'order_type_default');

    const officeOptions = getOptionsFromSettingsConfig(this.props.settingsConfig, 'CITIES');

    const priceTypeOptions = priceTypes?.map(el => ({
      value: el.value,
      label: capitalizeFirstLetter(el.value),
    }));

    let priceTypeValue = priceTypeOptions?.find(priceType => priceType.value === defaultOrderTypeName);
    if (form.priceType) {
      priceTypeValue = priceTypeOptions?.filter(priceType => priceType.value === form.priceType);
    } else {
      form.priceType = priceTypeValue?.value;
    }

    const productTyps = Object.values(Constants.PRODUCT_TYPE_LIST);

    const productOptions = productTyps?.map(el => ({
      value: el,
      label: el,
    }));

    return (
      <Box>
        {confirmationOpened ? (
          <InternationalConfirmation
            order={form}
            closeModal={this.closeConfirmationOrderModal}
            opened={confirmationOpened}
            save={this.saveAndSend}
            {...this.props}
          />
        ) : (
          false
        )}
        <Box display={form.id && !confirmationOpened ? 'block' : 'none'}>
          {isFormOptionsLoaded ? (
            <DialogRoot open={form.id && !confirmationOpened} onClose={this.handleModalClose} size="full">
              <DialogContent minWidth="5xl" maxW="90%">
                <DialogHeader
                  borderBottomWidth="1px"
                  marginBottom="31px"
                  style={{ backgroundColor: 'white', zIndex: 100, width: '100%' }}
                >
                  <Text as="p" fontSize="20px" fontWeight="bold">
                    Grinder PO# {form.grinderPoNumber ? form.grinderPoNumber : '-'} / {internalPoLabel}{' '}
                    {form.internalPoNumber ? form.internalPoNumber : '-'} / {grinder ? grinder.name : '-'}
                  </Text>
                  <DialogCloseTrigger asChild onClick={this.handleModalClose} />
                </DialogHeader>

                <DialogBody className="edit-international-order" marginTop={0} padding={0}>
                  <VStack gap="27px" align="stretch" padding="0px 27px 198px 27px">
                    <Flex height="40px">
                      {isUSADomestic && (
                        <PurchaseOrderInfo
                          {...{
                            isFrozenProduct,
                            grinderIsUnfunded: grinder.isUnfunded,
                            productType: form.productType,
                          }}
                        />
                      )}
                      {isAllowedToEdit && !isInEditMode && (
                        <Button
                          colorScheme="actionPrimary"
                          fontSize="14px"
                          fontWeight="semibold"
                          width="115px"
                          height="40px"
                          marginLeft="auto"
                          onClick={this.onEditClick}
                        >
                          Edit Order
                        </Button>
                      )}
                    </Flex>
                    <Tabs.Root defaultValue="orderStatus">
                      <Tabs.List>
                        <Tabs.Trigger value="orderStatus">Order Status</Tabs.Trigger>
                        <Tabs.Trigger value="orderDetails">Order Details</Tabs.Trigger>
                        <Tabs.Trigger value="productPricingDetails">Product & Pricing Details</Tabs.Trigger>
                        <Tabs.Trigger value="logisticsDetails">Logistics Details</Tabs.Trigger>
                        {!this.props.currentOrder.isAusDomestic && (
                          <Tabs.Trigger value="coldstoreRejectedCartons">ColdStore & Rejected Cartons</Tabs.Trigger>
                        )}
                        <Tabs.Trigger value="commentsAttachments">Comments and Attachments</Tabs.Trigger>
                        <Tabs.Trigger value="otherDetails">Other Details</Tabs.Trigger>
                        <Tabs.Trigger value="activityLogs">Activity Logs</Tabs.Trigger>
                      </Tabs.List>

                      {/* ORDER STATUS */}
                      <Tabs.Content value="orderStatus" className="tab-panel">
                        <Flex mb="1em" alignItems="center">
                          <Text as="b" mr="4px">
                            Order Status
                          </Text>
                          <StatusTag status={form.status} />
                        </Flex>
                        <VStack align="stretch" paddingX="27px" paddingY="25px" backgroundColor="white" gap="20px">
                          <PurchaseOrderStatusSection
                            {...{
                              isUSADomestic,
                              currentOrder,
                              isFrozenProduct,
                              endUserName: endUserCurrent.label ? endUserCurrent.label : '-',
                              grinderName: grinder ? grinder.name : '-',
                              handleChangeValue: this.handleChangeValue,
                              isInEditMode,
                            }}
                          />
                          <Separator />
                          {form.status !== Constants.ORDER_STATUSES.PENDING && (
                            <CustomFormControl
                              key="CustomFormControlOnHold"
                              changeableKey={changeableKeyCreator([isInEditMode, form.onHold])}
                            >
                              <HStack>
                                <Text fontWeight="bold">On Hold:</Text>
                                <Checkbox
                                  name="onHold"
                                  disabled={!isInEditMode}
                                  colorScheme="actionSecondary"
                                  onChange={this.handleChangeValue}
                                  defaultChecked={get(form, 'onHold', false)}
                                />
                              </HStack>
                            </CustomFormControl>
                          )}
                          <Separator />
                          {get(form, 'onHold', false) && (
                            <>
                              <HStack gap="42px" width="100%">
                                <Box width="100%">
                                  <Field htmlFor="on_hold_reason" label="On Hold Reason">
                                    <Textarea
                                      backgroundColor="white"
                                      className={`form-control ${
                                        requiredFieldsForm.includes('onHoldReason') ? 'error' : ''
                                      }`}
                                      disabled={!isInEditMode}
                                      id="onHoldReason"
                                      name="onHoldReason"
                                      value={form.onHoldReason}
                                      onChange={this.handleChangeValue}
                                      rows="4"
                                    />
                                  </Field>
                                  {requiredFieldsForm.includes('onHoldReason') && (
                                    <Alert title="On Hold Reason is Required." status="error" />
                                  )}
                                </Box>
                              </HStack>
                            </>
                          )}
                          <VStack align="stretch" gap="10px">
                            <SummaryOfCosts order={form} user={user} />
                          </VStack>
                        </VStack>
                      </Tabs.Content>
                      {/* ORDER DETAILS */}
                      <Tabs.Content value="orderDetails" className="tab-panel">
                        <VStack align="stretch">
                          <Grid gap="18px" templateColumns="repeat(4, 1fr)" width="100%">
                            {form.status === Constants.ORDER_STATUSES.DRAFT && (
                              <>
                                <SelectField
                                  label={
                                    <>
                                      Office&nbsp;
                                      <Tooltip
                                        content="Specifies the office managing the order for coordination."
                                        positioning={{ placement: 'right-end' }}
                                      >
                                        <IconButton
                                          width="14px"
                                          height="14px"
                                          padding="0"
                                          minW="auto"
                                          borderRadius="50%"
                                          color="#878787"
                                          variant="unstyled"
                                        >
                                          <IoInformationCircleOutline size="14px" />
                                        </IconButton>
                                      </Tooltip>
                                    </>
                                  }
                                  id="select-purchasingOffice"
                                  name="purchasingOffice"
                                  options={officeOptions?.sort((a, b) => (a.label > b.label ? 1 : -1))}
                                  placeholder="Purchasing Office"
                                  disabled={!isInEditMode}
                                  value={officeOptions.find(office => office.value === form.purchasingOffice)}
                                  className={requiredFieldsForm.includes('purchasingOffice') ? 'error' : ''}
                                  onChange={e => this.onChangeValue('purchasingOffice', e)}
                                />

                                <SelectField
                                  label={
                                    <>
                                      Product Type&nbsp;
                                      <Tooltip
                                        content="Specifies whether the order is for Beef or Pork to categorize the meat product."
                                        positioning={{ placement: 'right-end' }}
                                      >
                                        <IconButton
                                          width="14px"
                                          height="14px"
                                          padding="0"
                                          minW="auto"
                                          borderRadius="50%"
                                          color="#878787"
                                          variant="unstyled"
                                        >
                                          <IoInformationCircleOutline size="14px" />
                                        </IconButton>
                                      </Tooltip>
                                    </>
                                  }
                                  id="select-product-type"
                                  name="productType"
                                  options={productOptions?.sort((a, b) => (a.label > b.label ? 1 : -1))}
                                  placeholder="Product Type"
                                  disabled={!isInEditMode}
                                  value={
                                    productOptions?.find(productType => productType?.value === form?.productType) ||
                                    null
                                  }
                                  className={requiredFieldsForm.includes('productType') ? 'error' : ''}
                                  onChange={e => this.onChangeValue('productType', e)}
                                />
                              </>
                            )}

                            <CustomFormControl
                              key="CustomFormControlGrinderPO"
                              changeableKey={changeableKeyCreator([isInEditMode, form.grinderPoNumber])}
                              label={
                                <>
                                  Grinder PO#&nbsp;
                                  <Tooltip
                                    content="Displays the purchase order number assigned by the grinder."
                                    positioning={{ placement: 'right-end' }}
                                  >
                                    <IconButton
                                      width="14px"
                                      height="14px"
                                      padding="0"
                                      minW="auto"
                                      borderRadius="50%"
                                      color="#878787"
                                      variant="unstyled"
                                    >
                                      <IoInformationCircleOutline size="14px" />
                                    </IconButton>
                                  </Tooltip>
                                </>
                              }
                            >
                              <Input
                                id="grinderPO"
                                name="grinderPoNumber"
                                type="text"
                                className="form-control"
                                disabled={!isInEditMode || !isEditableGrinderPoNumber}
                                value={form.grinderPoNumber || ''}
                                onChange={this.handleChangeValue}
                              />
                            </CustomFormControl>

                            <SelectField
                              label={
                                <>
                                  End User&nbsp;
                                  <Tooltip
                                    content="Identifies the final recipient to ensure the product meets specific requirements."
                                    positioning={{ placement: 'right-end' }}
                                  >
                                    <IconButton
                                      width="14px"
                                      height="14px"
                                      padding="0"
                                      minW="auto"
                                      borderRadius="50%"
                                      color="#878787"
                                      variant="unstyled"
                                    >
                                      <IoInformationCircleOutline size="14px" />
                                    </IconButton>
                                  </Tooltip>
                                </>
                              }
                              id="select-end_user"
                              name="endUser"
                              options={endUserOptionsFilteredByGrinder?.sort((a, b) => (a.label > b.label ? 1 : -1))}
                              placeholder="End User"
                              disabled={!isInEditMode}
                              value={endUserCurrent}
                              className={requiredFieldsForm.includes('endUser') ? 'error' : ''}
                              onChange={e => this.onChangeValue('endUser', e)}
                            />

                            <SelectField
                              label={
                                <>
                                  Packer&nbsp;
                                  <Tooltip
                                    content="Specifies the packer responsible for handling and tracking."
                                    positioning={{ placement: 'right-end' }}
                                  >
                                    <IconButton
                                      width="14px"
                                      height="14px"
                                      padding="0"
                                      minW="auto"
                                      borderRadius="50%"
                                      color="#878787"
                                      variant="unstyled"
                                    >
                                      <IoInformationCircleOutline size="14px" />
                                    </IconButton>
                                  </Tooltip>
                                </>
                              }
                              id="select-packer"
                              name="packerId"
                              value={
                                (this.state.isInEditMode
                                  ? packerOptions
                                  : allPackers?.map(el => ({ value: el.id, label: el.name }))
                                ).find(option => option.value === get(form, 'packerId')) || null
                              }
                              options={sortedPackerOptions}
                              disabled={!isInEditMode}
                              className={requiredFieldsForm.includes('packerId') ? 'error' : ''}
                              onChange={e => this.onChangeValue('packerId', e ? e.value : '')}
                            />

                            <SelectField
                              label={
                                <>
                                  Establishment&nbsp;
                                  <Tooltip
                                    content="Identifies the establishment associated with the order."
                                    positioning={{ placement: 'right-end' }}
                                  >
                                    <IconButton
                                      width="14px"
                                      height="14px"
                                      padding="0"
                                      minW="auto"
                                      borderRadius="50%"
                                      color="#878787"
                                      variant="unstyled"
                                    >
                                      <IoInformationCircleOutline size="14px" />
                                    </IconButton>
                                  </Tooltip>
                                </>
                              }
                              id="select-est"
                              name="packerPlant"
                              value={
                                (this.state.isInEditMode
                                  ? packerPlantOptionsByPacker
                                  : allPackerPlants?.map(el => ({ value: el.id, label: el.name }))
                                ).find(option => option.value === get(form, 'packerPlantId')) || null
                              }
                              options={sortedPackerPlantOptionsByPacker}
                              disabled={!isInEditMode}
                              className={requiredFieldsForm.includes('packerPlantId') ? 'error' : ''}
                              onChange={e => this.onChangeValue('packerPlant', e)}
                            />

                            <CustomFormControl
                              key="CustomFormControlEstId"
                              changeableKey={changeableKeyCreator([isInEditMode, get(form, 'estId')])}
                              label={
                                <>
                                  Establishment Number&nbsp;
                                  <Tooltip
                                    content="Records the official number for traceability and compliance."
                                    positioning={{ placement: 'right-end' }}
                                  >
                                    <IconButton
                                      width="14px"
                                      height="14px"
                                      padding="0"
                                      minW="auto"
                                      borderRadius="50%"
                                      color="#878787"
                                      variant="unstyled"
                                    >
                                      <IoInformationCircleOutline size="14px" />
                                    </IconButton>
                                  </Tooltip>
                                </>
                              }
                            >
                              <Input
                                id="estId"
                                name="estId"
                                value={
                                  isInEditMode &&
                                  allPackerPlants?.find(option => option.id === get(form, 'packerPlantId'))?.delisted
                                    ? ''
                                    : get(form, 'estId')
                                }
                                disabled={!isInEditMode}
                                className={requiredFieldsForm.includes('estId') ? 'error' : ''}
                                onChange={this.handleChangeValue}
                              />
                            </CustomFormControl>
                            {/* <!-- BUYER --> */}

                            <SelectField
                              label={
                                <>
                                  Buyer&nbsp;
                                  <Tooltip
                                    content="Identifies the individual or organization purchasing the product."
                                    positioning={{ placement: 'right-end' }}
                                  >
                                    <IconButton
                                      width="14px"
                                      height="14px"
                                      padding="0"
                                      minW="auto"
                                      borderRadius="50%"
                                      color="#878787"
                                      variant="unstyled"
                                    >
                                      <IoInformationCircleOutline size="14px" />
                                    </IconButton>
                                  </Tooltip>
                                </>
                              }
                              id="select-buyer"
                              name="buyerId"
                              value={buyerOptions.find(option => option.value === get(form, 'buyerId')) || null}
                              options={buyerOptions}
                              disabled={!isInEditMode}
                              placeholder="Salesman"
                              className={requiredFieldsForm.includes('buyerId') ? 'error' : ''}
                              onChange={e => this.onChangeValue('buyerId', e)}
                              isClearable={false}
                            />

                            {(isEUDomestic || isAUSDomestic || IsValidUSDomesticOrderFlag) && (
                              <>
                                <SelectField
                                  label={
                                    <>
                                      Container Type&nbsp;
                                      <Tooltip
                                        content="Specifies the type of container used for shipping."
                                        positioning={{ placement: 'right-end' }}
                                      >
                                        <IconButton
                                          width="14px"
                                          height="14px"
                                          padding="0"
                                          minW="auto"
                                          borderRadius="50%"
                                          color="#878787"
                                          variant="unstyled"
                                        >
                                          <IoInformationCircleOutline size="14px" />
                                        </IconButton>
                                      </Tooltip>
                                    </>
                                  }
                                  id="container-type"
                                  name="loadContainerDetails"
                                  defaultValue={form?.loadContainerDetails}
                                  options={containerOptions}
                                  disabled={!isInEditMode}
                                  inputProps={{
                                    className: 'm-input',
                                    disabled: !isInEditMode,
                                  }}
                                  placeholder="Container/Load Combo"
                                  className={requiredFieldsForm.includes('loadContainerDetails') ? 'error' : ''}
                                  onChange={e => this.onChangeValue('loadContainerDetails', e)}
                                />
                                {/* Removed trucking company as per task #4716 Requirement */}
                                {/* <CustomFormControl
                                    key="CustomFormControlTruckingCompany"
                                    changeableKey={changeableKeyCreator([isInEditMode, transporterValue])}
                                  >
                                    <Field label="Trucking Company">
                                      <SelectField
                                        id="truckingCompany"
                                        name="transporterId"
                                        disabled={!isInEditMode || isReadOnlyField}
                                        value={transporterValue || ''}
                                        options={transporterOptions}
                                        onChange={e => this.onChangeValue('transporterId', e ? e.value : '')}
                                      />
                                    </Field>
                                  </CustomFormControl> */}
                                <CustomFormControl
                                  key="CustomFormControlDeliveryDate"
                                  changeableKey={changeableKeyCreator([isInEditMode, form.deliveryDate])}
                                >
                                  <Text as="label" htmlFor="delivery-date">
                                    {isReadOnlyField ? 'Delivery Date (add it in the inventory)' : 'Delivery Date'}
                                    &nbsp;
                                    <Tooltip
                                      content="Indicates the date when the shipment is expected to be delivered."
                                      positioning={{ placement: 'right-end' }}
                                    >
                                      <IconButton
                                        width="14px"
                                        height="14px"
                                        padding="0"
                                        minW="auto"
                                        borderRadius="50%"
                                        color="#878787"
                                        variant="unstyled"
                                      >
                                        <IoInformationCircleOutline size="14px" />
                                      </IconButton>
                                    </Tooltip>
                                  </Text>
                                  <DateTimeWithTrappedFocus
                                    id="delivery-date"
                                    name="deliveryDate"
                                    inputProps={{
                                      className: 'form-control m-input',
                                      disabled: !isInEditMode || isReadOnlyField,
                                    }}
                                    closeOnSelect
                                    value={form.deliveryDate}
                                    onChange={e => {
                                      this.handleDateChange(
                                        'deliveryDate',
                                        e._isAMomentObject ? e.format('YYYY-MM-DD') : null // eslint-disable-line no-underscore-dangle
                                      );
                                    }}
                                    // TODO check for deliveryDate validation from edit form
                                    className={requiredFields?.po?.includes('deliveryDate') ? 'error' : ''}
                                    timeFormat={false}
                                    dateFormat="YYYY-MM-DD"
                                  />
                                </CustomFormControl>
                                <CustomFormControl
                                  key="CustomFormControlDeliveryTime"
                                  changeableKey={changeableKeyCreator([isInEditMode, form.scheduledDeliveryTime])}
                                  label={
                                    <>
                                      Delivery Time&nbsp;
                                      <Tooltip
                                        content="Captures the expected delivery time for scheduling and coordination."
                                        positioning={{ placement: 'right-end' }}
                                      >
                                        <IconButton
                                          width="14px"
                                          height="14px"
                                          padding="0"
                                          minW="auto"
                                          borderRadius="50%"
                                          color="#878787"
                                          variant="unstyled"
                                        >
                                          <IoInformationCircleOutline size="14px" />
                                        </IconButton>
                                      </Tooltip>
                                    </>
                                  }
                                >
                                  <TimePicker
                                    use12Hours
                                    disabled={!isInEditMode || isReadOnlyField}
                                    format="h:mm a"
                                    value={form.scheduledDeliveryTime && moment(form.scheduledDeliveryTime, 'HH:mm')}
                                    onChange={value =>
                                      this.handleDateChange('scheduledDeliveryTime', value && value.format('HH:mm'))
                                    }
                                    className="form-control m-input"
                                  />
                                </CustomFormControl>
                                <CustomFormControl
                                  key="CustomFormControlADeliveryDate"
                                  changeableKey={changeableKeyCreator([this.state.isInEditMode, form.recvDeliveryDate])}
                                  label={
                                    <>
                                      Actual Delivery Date&nbsp;
                                      <Tooltip
                                        content="Captures the actual delivery time for scheduling and coordination."
                                        positioning={{ placement: 'right-end' }}
                                      >
                                        <IconButton
                                          width="14px"
                                          height="14px"
                                          padding="0"
                                          minW="auto"
                                          borderRadius="50%"
                                          color="#878787"
                                          variant="unstyled"
                                        >
                                          <IoInformationCircleOutline size="14px" />
                                        </IconButton>
                                      </Tooltip>
                                    </>
                                  }
                                >
                                  <DateTimeWithTrappedFocus
                                    id="actual-delivery-date"
                                    inputProps={{
                                      className: 'form-control m-input',
                                      disabled: !isInEditMode || !deliveryDateEditable,
                                    }}
                                    closeOnSelect
                                    value={form.recvDeliveryDate}
                                    onChange={e => {
                                      this.handleDateChange(
                                        'recvDeliveryDate',
                                        e._isAMomentObject ? e.format('YYYY-MM-DD') : null // eslint-disable-line no-underscore-dangle
                                      );
                                    }}
                                    timeFormat={false}
                                    dateFormat="YYYY-MM-DD"
                                  />
                                </CustomFormControl>
                              </>
                            )}
                            {isFrozenProduct && isUSADomestic && showUSADomesticFreshnessField && (
                              <CustomFormControl
                                key="CustomFormControlFrozenOrderType"
                                changeableKey={changeableKeyCreator([isInEditMode, form.frozenOrderType])}
                                label="Type"
                              >
                                <CFSwitch
                                  // onClick={value => onToggleChange(value ? 'arrival' : 'shipment')}
                                  onText="Frozen Coldstore Domestic"
                                  disabled={!isInEditMode}
                                  offText="Frozen Domestic"
                                  // isActive={!showShipment}
                                  style={{ borderRadius: '10px !important' }}
                                />
                              </CustomFormControl>
                            )}
                            <CustomFormControl
                              key="CustomFormControlPackerPaidDate"
                              changeableKey={changeableKeyCreator([isInEditMode, form.packerPaidDate])}
                              label={
                                <>
                                  Packer Paid Date&nbsp;
                                  <Tooltip
                                    content="Displays the date when the packer made the payment."
                                    positioning={{ placement: 'right-end' }}
                                  >
                                    <IconButton
                                      width="14px"
                                      height="14px"
                                      padding="0"
                                      minW="auto"
                                      borderRadius="50%"
                                      color="#878787"
                                      variant="unstyled"
                                    >
                                      <IoInformationCircleOutline size="14px" />
                                    </IconButton>
                                  </Tooltip>
                                </>
                              }
                            >
                              <DateTimeWithTrappedFocus
                                id="packerPaidDate"
                                inputProps={{
                                  className: 'form-control m-input',
                                  disabled: !isInEditMode,
                                }}
                                closeOnSelect
                                value={form.packerPaidDate}
                                onChange={e => {
                                  this.handleDateChange(
                                    'packerPaidDate',
                                    e._isAMomentObject ? e.format('YYYY-MM-DD') : null // eslint-disable-line no-underscore-dangle
                                  );
                                }}
                                timeFormat={false}
                                dateFormat="YYYY-MM-DD"
                              />
                            </CustomFormControl>
                            {grinderDestinationCountry && hasAsianGrinder && (
                              <CustomFormControl
                                key="CustomFormControlPreferredEta"
                                changeableKey={changeableKeyCreator([isInEditMode, form.preferredEta])}
                                label="Preferred ETA"
                              >
                                <DateTimeWithTrappedFocus
                                  id="preferredEta"
                                  inputProps={{
                                    className: 'form-control m-input',
                                    disabled: !isInEditMode,
                                  }}
                                  closeOnSelect
                                  value={form.preferredEta || null}
                                  onChange={e =>
                                    this.onChangeValue(
                                      'preferredEta',
                                      e._isAMomentObject ? e.format('YYYY-MM-DD') : null // eslint-disable-line no-underscore-dangle
                                    )
                                  }
                                  timeFormat={false}
                                  dateFormat="YYYY-MM-DD"
                                />
                              </CustomFormControl>
                            )}
                            <CustomFormControl
                              key="CustomFormControlPackerInvoiceNumber"
                              changeableKey={changeableKeyCreator([this.state.isInEditMode, form.packerInvoiceNumber])}
                              label={
                                <>
                                  Packer Invoice #&nbsp;
                                  <Tooltip
                                    content="Displays the invoice number assigned by the packer."
                                    positioning={{ placement: 'right-end' }}
                                  >
                                    <IconButton
                                      width="14px"
                                      height="14px"
                                      padding="0"
                                      minW="auto"
                                      borderRadius="50%"
                                      color="#878787"
                                      variant="unstyled"
                                    >
                                      <IoInformationCircleOutline size="14px" />
                                    </IconButton>
                                  </Tooltip>
                                </>
                              }
                            >
                              <Input
                                name="packerInvoiceNumber"
                                type="text"
                                disabled={!isInEditMode}
                                value={form.packerInvoiceNumber || ''}
                                onChange={e => this.onChangeValue('packerInvoiceNumber', e.target.value)}
                              />
                            </CustomFormControl>
                            <CustomFormControl
                              key="CustomFormControlPackerInvoiceDate"
                              changeableKey={changeableKeyCreator([isInEditMode, form.packerInvoiceDate])}
                              label={
                                <>
                                  Packer Invoice Date&nbsp;
                                  <Tooltip
                                    content="Displays the date when the packer made the payment."
                                    positioning={{ placement: 'right-end' }}
                                  >
                                    <IconButton
                                      width="14px"
                                      height="14px"
                                      padding="0"
                                      minW="auto"
                                      borderRadius="50%"
                                      color="#878787"
                                      variant="unstyled"
                                    >
                                      <IoInformationCircleOutline size="14px" />
                                    </IconButton>
                                  </Tooltip>
                                </>
                              }
                            >
                              <DateTimeWithTrappedFocus
                                id="packerInvoiceDate"
                                inputProps={{
                                  className: 'form-control m-input',
                                  disabled: !isInEditMode,
                                }}
                                closeOnSelect
                                value={form.packerInvoiceDate}
                                onChange={e => {
                                  this.handleDateChange(
                                    'packerInvoiceDate',
                                    e._isAMomentObject ? e.format('YYYY-MM-DD') : null // eslint-disable-line no-underscore-dangle
                                  );
                                }}
                                timeFormat={false}
                                dateFormat="YYYY-MM-DD"
                              />
                            </CustomFormControl>

                            <CustomFormControl
                              key="CustomFormControlAPackerSoNumber"
                              changeableKey={changeableKeyCreator([isInEditMode, form.packerSoNumber])}
                              label={
                                <>
                                  Packer SO #&nbsp;
                                  <Tooltip
                                    content="Displays the sales order number assigned by the packer."
                                    positioning={{ placement: 'right-end' }}
                                  >
                                    <IconButton
                                      width="14px"
                                      height="14px"
                                      padding="0"
                                      minW="auto"
                                      borderRadius="50%"
                                      color="#878787"
                                      variant="unstyled"
                                    >
                                      <IoInformationCircleOutline size="14px" />
                                    </IconButton>
                                  </Tooltip>
                                </>
                              }
                            >
                              <Input
                                id="packerSoNumber"
                                name="packerSoNumber"
                                type="text"
                                disabled={!isInEditMode}
                                value={form.packerSoNumber || ''}
                                onChange={this.handleChangeValue}
                              />
                            </CustomFormControl>
                            {isFrozenProduct && isUSADomestic ? (
                              <>
                                <CustomFormControl
                                  key="CustomFormControlColdstorePlannedDeliveryDate"
                                  changeableKey={changeableKeyCreator([
                                    isInEditMode,
                                    form?.coldstorePlannedDeliveryDate,
                                  ])}
                                  label={
                                    <>
                                      Planned Delivery Date to Coldstore&nbsp;
                                      <Tooltip
                                        content="Displays the planned delivery date to the cold storage."
                                        positioning={{ placement: 'right-end' }}
                                      >
                                        <IconButton
                                          width="14px"
                                          height="14px"
                                          padding="0"
                                          minW="auto"
                                          borderRadius="50%"
                                          color="#878787"
                                          variant="unstyled"
                                        >
                                          <IoInformationCircleOutline size="14px" />
                                        </IconButton>
                                      </Tooltip>
                                    </>
                                  }
                                >
                                  <DateTimeWithTrappedFocus
                                    name="coldstorePlannedDeliveryDate"
                                    value={form?.coldstorePlannedDeliveryDate}
                                    inputProps={{
                                      className: 'form-control m-input',
                                      disabled: !isInEditMode,
                                    }}
                                    closeOnSelect
                                    onChange={e => {
                                      this.handleDateChange(
                                        'coldstorePlannedDeliveryDate',
                                        e._isAMomentObject ? e.format('YYYY-MM-DD') : null // eslint-disable-line no-underscore-dangle
                                      );
                                    }}
                                    timeFormat={false}
                                    dateFormat="YYYY-MM-DD"
                                  />
                                </CustomFormControl>
                              </>
                            ) : (
                              ''
                            )}
                            {(hasVoyageDetails || isColdstoreRequired) && (
                              <DateSection
                                {...{
                                  arrivalDateStart: form.arrivalDateStart,
                                  arrivalDateEnd: form.arrivalDateEnd,
                                  shipmentDateStart: form.shipmentDateStart,
                                  shipmentDateEnd: form.shipmentDateEnd,
                                  showShipment: this.state.showShipment,
                                  onToggleChange: this.handleToggleChange,
                                  onDateChange: this.handleStartDateValueChange,
                                  onChangeFieldValue: this.onChangeValue,
                                  requiredFields,
                                  isInEditMode,
                                  isShipmentDateRequiredPrompt: form.isShipmentDateRequiredPrompt,
                                }}
                              />
                            )}
                            {formFields?.find(
                              field => field?.type === 'show_estimated_delivery_date' && field?.value === '1'
                            ) &&
                              !isUSADomestic &&
                              !isAUSDomestic && (
                                <>
                                  <CustomFormControl
                                    key="CustomFormControlEstimatedDeliveryDate"
                                    changeableKey={changeableKeyCreator([isInEditMode, form?.estimatedDeliveryDate])}
                                    label="Estimated Delivery Date"
                                  >
                                    <DateTimeWithTrappedFocus
                                      name="estimatedDeliveryDate"
                                      value={form?.estimatedDeliveryDate}
                                      inputProps={{
                                        className: 'form-control m-input',
                                        disabled: !isInEditMode,
                                      }}
                                      closeOnSelect
                                      onChange={e => {
                                        this.handleDateChange(
                                          'estimatedDeliveryDate',
                                          e._isAMomentObject ? e.format('YYYY-MM-DD') : null // eslint-disable-line no-underscore-dangle
                                        );
                                      }}
                                      timeFormat={false}
                                      dateFormat="YYYY-MM-DD"
                                    />
                                  </CustomFormControl>
                                </>
                              )}
                            {isFrozenProduct && isUSADomestic && showUSADomesticFreshnessField && (
                              <CustomFormControl
                                key="CustomFormControlIsStanding"
                                changeableKey={changeableKeyCreator([isInEditMode, form.isStanding])}
                              >
                                <Checkbox
                                  name="isStanding"
                                  colorScheme="actionSecondary"
                                  onChange={this.handleChangeValue}
                                  disabled={!isInEditMode}
                                >
                                  Standing
                                </Checkbox>
                              </CustomFormControl>
                            )}
                            {poHedgingEnabled && (
                              <CustomFormControl
                                key="CustomFormControlHedgeContractNumber"
                                changeableKey={changeableKeyCreator([
                                  this.state.isInEditMode,
                                  form.hedgeContractNumber,
                                ])}
                                label="Hedge Contract Number"
                              >
                                <Input
                                  id="hedgeContractNumber"
                                  name="hedgeContractNumber"
                                  type="text"
                                  disabled={!isInEditMode}
                                  value={form.hedgeContractNumber || ''}
                                  onChange={this.handleChangeValue}
                                />
                              </CustomFormControl>
                            )}

                            <SelectField
                              label={
                                <>
                                  Tags&nbsp;
                                  <Tooltip
                                    content="Displays the tags added for the order."
                                    positioning={{ placement: 'right-end' }}
                                  >
                                    <IconButton
                                      width="14px"
                                      height="14px"
                                      padding="0"
                                      minW="auto"
                                      borderRadius="50%"
                                      color="#878787"
                                      variant="unstyled"
                                    >
                                      <IoInformationCircleOutline size="14px" />
                                    </IconButton>
                                  </Tooltip>
                                </>
                              }
                              {...{
                                className: 'edit-international-order__select',
                                isClearable: true,
                                disabled: !isInEditMode,
                                isSearchable: true,
                                closeMenuOnSelect: false,
                                removeSelected: true,
                                isMulti: true,
                                name: 'select-tags',
                                value: form.tags ? form.tags.map(t => ({ label: t, value: t })) : [],
                                onChange: e => this.onChangeValue('tags', e && e.length ? e.map(lbl => lbl.value) : []),
                                options: labelOptions?.sort((a, b) => (a.label > b.label ? 1 : -1)),
                              }}
                            />
                            {isFrozenProduct && isUSADomestic && (
                              <CustomFormControl
                                key="CustomFormControlPriceType"
                                changeableKey={changeableKeyCreator([isInEditMode, form.priceType])}
                                label={
                                  <>
                                    Order Type&nbsp;
                                    <Tooltip
                                      content="Specifies the nature of the order (Spot, Contract, Formula, Other, NOF)."
                                      positioning={{ placement: 'right-end' }}
                                    >
                                      <IconButton
                                        width="14px"
                                        height="14px"
                                        padding="0"
                                        minW="auto"
                                        borderRadius="50%"
                                        color="#878787"
                                        variant="unstyled"
                                      >
                                        <IoInformationCircleOutline size="14px" />
                                      </IconButton>
                                    </Tooltip>
                                  </>
                                }
                              >
                                <Input id="orderType" type="text" disabled value={form.priceType || ''} />
                              </CustomFormControl>
                            )}

                            <CustomFormControl
                              key="CustomFormControladditinalGrn"
                              changeableKey={changeableKeyCreator([this.state.isInEditMode, form.additinalGrn])}
                              label="Additional Reference"
                            >
                              <Input
                                id="additinalGrn"
                                name="additinalGrn"
                                type="text"
                                disabled={!isInEditMode}
                                value={form.additinalGrn || ''}
                                onChange={this.handleChangeValue}
                              />
                            </CustomFormControl>
                            <CustomFormControl
                              key="CustomFormControlentrapment"
                              changeableKey={changeableKeyCreator([this.state.isInEditMode, form.entrapment])}
                              label="Entrapment"
                            >
                              <Input
                                id="entrapment"
                                name="entrapment"
                                type="number"
                                disabled={!isInEditMode || form?.sellUnitOfMeasure?.name === 'bin'}
                                value={form.entrapment || ''}
                                onChange={this.handleChangeValue}
                              />
                            </CustomFormControl>
                          </Grid>
                          <InternationalOrderDetails
                            onChangeValue={this.onChangeValue}
                            onFXChangeValue={this.onFXChangeValue}
                            onFXRoundingDecimal={this.onFXRoundingDecimal}
                            onBooleanValueChange={this.onBooleanValueChange}
                            form={form}
                            requiredFields={requiredFields}
                            editMode={isInEditMode}
                            user={this.props.user}
                            users={this.props.users}
                            showToggles={!(isFrozenProduct && isUSADomestic)}
                            {...this.props}
                          />
                        </VStack>
                      </Tabs.Content>
                      {/* PRODUCT & PRICING DETAILS */}
                      <Tabs.Content value="productPricingDetails" className="tab-panel">
                        <VStack align="stretch" backgroundColor="inherit">
                          {hasManualSurchargeFee && (
                            <>
                              <Text fontWeight="bold" my="1em">
                                Fees
                              </Text>
                              <VStack align="stretch" style={{ marginBottom: '30px' }}>
                                {this.state.warnings.hasExceededSurchargeCount === true && (
                                  <Alert status="warning">Note: Cannot have more than 3 Fees.</Alert>
                                )}
                                <Surcharges
                                  surchargeTypeOptions={surchargeTypeOptions}
                                  unitsOfMeasure={unitsOfMeasure}
                                  isDisabled={!isInEditMode}
                                  onRemoveSurcharge={this.handleRemoveObjectListItem}
                                  onSurchargeChange={this.handleChangeObjectList}
                                  onAddSurcharge={this.handleAddSurcharge}
                                  form={
                                    form.surcharges ? form : { ...form, surcharges: [Constants.INITIAL_SURCHARGES] }
                                  }
                                  sellCurrency={form.sellCurrency}
                                  requiredFields={requiredFields}
                                />
                              </VStack>
                            </>
                          )}
                          {dateValidationErrors.errorMsg.text &&
                            dateValidationErrors.errorMsg.tab === 'purchaseOrderLines' && (
                              <Alert status="error" title={dateValidationErrors.errorMsg.text} />
                            )}
                          {form.id && (
                            <InternationalOrderLines
                              {...{
                                buyCurrency: form.buyCurrency,
                                buyUnitOfMeasureId: form.buyUnitOfMeasureId,
                                form,
                                formType: 'editForm',
                                grinderDestinationCountry,
                                inputProducts: isInEditMode ? filteredInputProducts : allInputProducts,
                                isChilled: form.isChilled,
                                isInEditMode,
                                hasExpectedProductionDate: hasVoyageDetails,
                                lineIndices: form.lineIndices,
                                lines: form.linesObject,
                                loadSizes,
                                onAddLine: this.handleAddLine,
                                onPPUChangeValue: this.handlePPUChangeValue,
                                onPPURoundingDecimal: this.handlePPURoundingDecimal,
                                onRemoveLine: this.handleRemoveLine,
                                onUpdateLine: this.handleUpdateLine,
                                packerId,
                                packerPlantOriginCountry,
                                packerPlantId: get(form, 'packerPlantId'),
                                packerPlantOptions,
                                packerPlants,
                                grinders,
                                purchasingOffice: currentOrder.purchasingOffice,
                                purchaseOrderId: form.id,
                                purchaseOrderIndex: form.id,
                                requiredFields,
                                sellCurrency: form.sellCurrency,
                                sellUnitOfMeasureId: form.sellUnitOfMeasureId,
                                shipmentDateEnd: form.shipmentDateEnd,
                                submitProductionDetails: this.onProductionDetailChange,
                                submitCLResults: this.handleCLUpdate,
                                submitTemperatures: this.handleTemperatureUpdate,
                                tags,
                                packageWeights,
                                isColdstoreRequired,
                                isUSADomestic,
                              }}
                            />
                          )}
                        </VStack>
                      </Tabs.Content>
                      {/* LOGISTICS DETAILS */}
                      <Tabs.Content value="logisticsDetails" className="tab-panel">
                        {!isDomestic && (
                          <VStack align="stretch" gap="21px" backgroundColor="inherit">
                            {/* <!-- VOYAGE DETAILS --> */}
                            {hasVoyageDetails && (
                              <VoyageDetails
                                key="voyage-details"
                                shippingLines={shippingLines}
                                ports={ports?.sort((a, b) => (a.name > b.name ? 1 : -1))}
                                deleteVoyage={this.deleteVoyage}
                                validationErrors={this.state.validationErrors}
                                isActualDateOfArrivalCfc={IsActiveActualDateOfArrival}
                                isActualDateofDepartureCfc={IsActiveActualDateOfDeparture}
                                onVoyageValueChange={this.onVoyageValueChange}
                                onChangeValue={this.onChangeValue}
                                onObjectValueChange={this.onObjectValueChange}
                                finalDestination={form.finalDestination}
                                exportDetails={{ ...exportDetails }}
                                isISFRequired={isISFRequired}
                                isUsCustomsNumberCfc={IsActiveUsCustomsEntryNumber}
                                isPermitNumberRequired={isPermitNumberRequired}
                                editMode={isInEditMode}
                                voyage={this.state.voyage}
                                documents={form.documents}
                              />
                            )}
                            {hasVoyageDetails &&
                              (form.buyIncoterms === 'FOB' ||
                                form.buyIncoterms === 'FAS' ||
                                form.buyIncoterms === 'DCT') && (
                                <FOBInformation
                                  containerDepots={containerDepots?.sort((a, b) => (a.value > b.value ? 1 : -1))}
                                  containerReturns={containerReturns?.sort((a, b) => (a.value > b.value ? 1 : -1))}
                                  editMode={isInEditMode}
                                  onObjectValueChange={this.onObjectValueChange}
                                  form={form}
                                />
                              )}
                            <BillOfLading
                              containers={containers}
                              transporters={transporters}
                              addMark={this.addMark}
                              deleteMark={this.deleteMark}
                              handleRemoveObjectListItem={this.handleRemoveObjectListItem}
                              editMode={isInEditMode}
                              handleChangeObjectList={this.handleChangeObjectList}
                              onObjectValueChange={this.onObjectValueChange}
                              handleGrossShippedWeightChange={this.handleGrossShippedWeightChange}
                              handleGrossShippedWeightBlur={this.handleGrossShippedWeightBlur}
                              form={form}
                            />
                            {dateValidationErrors.errorMsg.text &&
                              dateValidationErrors.errorMsg.tab === 'coldstoreDetails' && (
                                <Alert status="error" title={dateValidationErrors.errorMsg.text} />
                              )}
                          </VStack>
                        )}
                        <Text fontWeight="bold" mb="1em">
                          Important Dates
                        </Text>
                        <VStack align="stretch" gap="21px" backgroundColor="inherit">
                          <ShipmentImportantDetails
                            name="shipment-important-date"
                            editMode={isInEditMode}
                            exportDetails={exportDetails}
                            onChangeValue={this.onChangeValue}
                            onObjectValueChange={this.onObjectValueChange}
                            isCourierForFmg={IsActiveCourierTrackingNo}
                            isIsfFileDateCfc={IsActiveIsfFileDate}
                            isActiveDocsSentToBank={IsActiveDocsSentToBank}
                            isActiveDocsSentToCustomer={DocsSentCustomerFieldName}
                            showToggles={!(isFrozenProduct && isUSADomestic)}
                          />
                        </VStack>
                      </Tabs.Content>
                      {/* COLDSTORE & REJECTED CARTONS */}
                      {!this.props.currentOrder.isAusDomestic && (
                        <Tabs.Content value="coldstoreRejectedCartons" className="tab-panel">
                          {isColdstoreRequired && (
                            <VStack align="stretch" gap="35px" backgroundColor="inherit">
                              <ColdStoreDetails
                                disabled={!isInEditMode}
                                onObjectValueChange={this.onObjectValueChange}
                                handleDateChange={this.handleDateChange}
                                coldStoreDetails={form.coldstoreDetails}
                                coldStores={this.props.coldStores}
                                transporterOptions={transporterOptions}
                                defaultColdstoreID={defaultColdstoreID}
                                form={form}
                                handleAtColdstore={this.handleAtColdstore}
                                setColdstoreStatus={this.state.setColdstoreStatus}
                                validationErrors={this.state.validationErrors}
                                isUSADomestic={isUSADomestic}
                                isFrozenProduct={isFrozenProduct}
                              />
                            </VStack>
                          )}
                          <Text fontWeight="bold" my="1.5em">
                            Rejected Cartons
                          </Text>
                          <VStack align="stretch" gap="35px" backgroundColor="inherit">
                            <RejectedCartons
                              shipmentDate={get(form, 'shipmentDate')}
                              rejectionReasons={this.props.reasonForRejection}
                              lotNumbers={get(form, 'coldstoreDetails.lotNumbers') || []}
                              packerLotNumbers={
                                Object.values(form.lines)
                                  ?.map(l => l.productionDetails?.map(d => d.packerLotNumber))
                                  .flat() || []
                              }
                              portMarks={get(form, 'billOfLading.shipmentPortMarks', []) || []}
                              rejectedCartons={
                                rejectedCartons.length ? rejectedCartons : [Constants.INITIAL_REJECTED_CARTON]
                              }
                              onAddCarton={this.handleAddRejectedCarton}
                              onChangeCarton={this.handleChangeObjectList}
                              onRemoveCarton={this.handleRemoveObjectListItem}
                              isInEditMode={isInEditMode}
                              orderId={form.id}
                              lines={Object.values(form.lines).filter(line => line.active)}
                              showToggles={!(isFrozenProduct && isUSADomestic)}
                            />
                          </VStack>
                        </Tabs.Content>
                      )}
                      {/* COMMENTS AND ATTACHMENTS */}
                      <Tabs.Content value="commentsAttachments" className="tab-panel">
                        {/* <Text fontWeight="bold" mb="1em">
                            References
                          </Text>
                          <References
                            {...{
                              isInEditMode,
                              onChange: value => this.onChangeValue('references', value),
                              references: get(form, 'references', []),
                            }}
                          /> */}
                        <Text fontWeight="bold" my="1.5em">
                          Comments
                        </Text>
                        <VStack align="stretch">
                          <HStack gap="42px" width="100%">
                            <Box width="100%">
                              <Field
                                label={
                                  <>
                                    Internal Comments&nbsp;
                                    <Tooltip
                                      content="Used for internal notes or remarks on orders or processes."
                                      positioning={{ placement: 'right-end' }}
                                    >
                                      <IconButton
                                        width="14px"
                                        height="14px"
                                        padding="0"
                                        minW="auto"
                                        borderRadius="50%"
                                        color="#878787"
                                        variant="unstyled"
                                      >
                                        <IoInformationCircleOutline size="14px" />
                                      </IconButton>
                                    </Tooltip>
                                  </>
                                }
                              >
                                <Textarea
                                  backgroundColor="white"
                                  className="form-control"
                                  disabled={!isInEditMode}
                                  id="internalComments"
                                  name="internalComments"
                                  value={form.internalComments}
                                  onChange={this.handleChangeValue}
                                  rows="4"
                                />
                              </Field>
                            </Box>
                            <Box width="100%">
                              <Field
                                label={
                                  <>
                                    External Comments&nbsp;
                                    <Tooltip
                                      content="Notes or remarks for external stakeholders, like customers or suppliers."
                                      positioning={{ placement: 'right-end' }}
                                    >
                                      <IconButton
                                        width="14px"
                                        height="14px"
                                        padding="0"
                                        minW="auto"
                                        borderRadius="50%"
                                        color="#878787"
                                        variant="unstyled"
                                      >
                                        <IoInformationCircleOutline size="14px" />
                                      </IconButton>
                                    </Tooltip>
                                  </>
                                }
                              >
                                <Textarea
                                  backgroundColor="white"
                                  className="form-control"
                                  disabled={!isInEditMode}
                                  id="externalComments"
                                  name="externalComments"
                                  value={form.externalComments}
                                  onChange={this.handleChangeValue}
                                  rows="4"
                                />
                              </Field>
                            </Box>
                          </HStack>
                        </VStack>

                        <Text fontWeight="bold" my="1.5em">
                          Load Notes
                        </Text>
                        <IssuesXcodes
                          isInEditMode={isInEditMode}
                          issuesXcodesOption={issuesXcode}
                          allIssuesXcodesOption={allIssuesXcodes}
                          onChange={this.handleIssueXcode}
                          onAdd={this.handleAddIssueXcode}
                          onDelete={this.handleDeleteIssueXcode}
                          issuesXcodes={get(purchaseOrder, 'issues_xcodes', [])}
                        />

                        <Text fontWeight="bold" my="1em">
                          Documents
                        </Text>
                        <VStack align="stretch" gap="8px">
                          {/* <!-- DOCUMENTS --> */}
                          {/* NOTE -- since currentOrder.documents is used to populate both the
                        Documents and the Generated Documents section, I am filtering out generated
                        documents here */}
                          <PurchaseOrderDocuments
                            disabled={!isInEditMode}
                            documents={form.documents.filter(
                              doc => !Constants.GENERATED_DOCUMENT_TYPES_LIST.includes(doc.docType)
                            )}
                            currentOrder={currentOrder}
                            autoSave={false}
                            stateForm={cloneDeep(this.state)}
                            {...this.props}
                          />
                        </VStack>
                        <Text fontWeight="bold" my="1em">
                          Generated Documents
                        </Text>
                        <GeneratedDocuments
                          {...{
                            form,
                            currentOrder,
                            isInEditMode,
                            internalPoLabel,
                            user,
                            email,
                            grinderIsChinese,
                            setIsGeneratingDocument: value => {
                              this.setState({
                                isGeneratingDocument: value,
                              });
                            },
                            isGeneratingDocument,
                            setEmailModalIsOpen: value => {
                              this.setState({
                                emailModalIsOpen: value,
                              });
                            },
                            emailModalIsOpen,
                            ...this.props,
                          }}
                        />
                      </Tabs.Content>
                      {/* OTHER DETAILS */}
                      <Tabs.Content value="otherDetails" className="tab-panel">
                        <OrderDetails {...this.props} />
                      </Tabs.Content>

                      {/* Activity Logs */}
                      <Tabs.Content value="activityLogs" className="tab-panel">
                        <VStack align="stretch" backgroundColor="inherit">
                          <OrderActivityLog
                            grinderPoNumber={form.grinderPoNumber || form.internalPoNumber}
                            entityType={form.grinderPoNumber ? 'grinder_po_number' : 'internal_po_number'}
                          />
                        </VStack>
                      </Tabs.Content>
                    </Tabs.Root>
                    {this.state.validationErrors?.form?.partiallyFilledComp.length && (
                      <Box>
                        <Alert status="error">
                          {this.state.validationErrors.form.partiallyFilledComp.map(err => {
                            return `Missing values for ${err.key} on row ${err.value}`;
                          })}
                        </Alert>
                      </Box>
                    )}

                    {validationErrors?.requiredFields?.issuesXcodes?.length > 0 && (
                      <Box>
                        <Alert status="error">
                          {validationErrors.requiredFields.issuesXcodes.map(err => {
                            return `Missing values for ${err.key} on Line ${err.value}. `;
                          })}
                        </Alert>
                      </Box>
                    )}
                  </VStack>
                </DialogBody>
                {validationErrors?.surchargeError && (
                  <Box>
                    <Alert status="error">{validationErrors.surchargeError}</Alert>
                  </Box>
                )}
                <DialogFooter padding="18px 32px" borderTopWidth="1px">
                  <Group gap="20px">
                    {isAllowedToCancel && (
                      <Button
                        type="button"
                        colorScheme="red"
                        variant="outline"
                        fontSize="16px"
                        fontWeight="semibold"
                        // marginRight="10px"
                        width="163px"
                        height="43px"
                        onClick={this.cancelOrder}
                      >
                        Cancel Order
                      </Button>
                    )}
                    {currentOrder.status !== Constants.ORDER_STATUSES.CANCELLED &&
                      form.status !== Constants.ORDER_STATUSES.DRAFT && (
                        <TransferOrderModal
                          purchaseOrderId={form.id}
                          onTransferSuccess={() => {
                            this.setState({
                              form: { ...form, status: Constants.ORDER_STATUSES.TRANSFERRED },
                            });
                          }}
                        >
                          {({ onOpen }) => (
                            <Button
                              variant="outline"
                              colorScheme="actionPrimary"
                              fontSize="16px"
                              height="43px"
                              width="163px"
                              onClick={onOpen}
                              disabled={form.status === Constants.ORDER_STATUSES.TRANSFERRED}
                            >
                              Transfer
                            </Button>
                          )}
                        </TransferOrderModal>
                      )}
                    {!this.state.loading && isAllowedToManage && (
                      <IconButton
                        colorScheme="actionPrimary"
                        variant="outline"
                        width="70px"
                        height="43px"
                        onClick={this.exportToExcel}
                      >
                        <DownloadIcon />
                      </IconButton>
                    )}
                  </Group>
                  <Group marginLeft="auto" gap="20px">
                    <Flex height="40px">
                      {isAllowedToEdit && !isInEditMode && (
                        <Button
                          colorScheme="actionPrimary"
                          fontSize="16px"
                          fontWeight="semibold"
                          width="163px"
                          height="43px"
                          marginLeft="auto"
                          onClick={this.onEditClick}
                        >
                          Edit Order
                        </Button>
                      )}
                    </Flex>
                    <Button
                      colorScheme="actionPrimary"
                      variant="outline"
                      fontSize="16px"
                      fontWeight="semibold"
                      width="163px"
                      height="43px"
                      onClick={this.handleModalClose}
                    >
                      Close
                    </Button>
                    {isAllowedToManage && (
                      <>
                        {!this.state.loading &&
                          form.status === Constants.ORDER_STATUSES.PENDING &&
                          this.state.isInEditMode && (
                            <Button
                              colorScheme="actionPrimary"
                              variant="solid"
                              fontSize="16px"
                              fontWeight="semibold"
                              width="163px"
                              height="43px"
                              onClick={this.startSubmitOrder}
                            >
                              Submit Order
                            </Button>
                          )}
                        {this.state.isInEditMode && form.status === Constants.ORDER_STATUSES.DRAFT && (
                          <Button
                            colorScheme="actionPrimary"
                            variant="solid"
                            fontSize="16px"
                            fontWeight="semibold"
                            width="163px"
                            height="43px"
                            onClick={this.saveDraftOrder}
                            disabled={isGeneratingDocument}
                          >
                            Save Draft Order
                          </Button>
                        )}
                        {this.state.isInEditMode &&
                          form.status !== Constants.ORDER_STATUSES.CANCELLED &&
                          (form.status === Constants.ORDER_STATUSES.DRAFT ? (
                            <Confirm
                              onConfirm={this.saveEditedOrder}
                              title="Are you sure, do you want to submit this order?"
                            >
                              <Button
                                colorScheme="actionPrimary"
                                variant="solid"
                                fontSize="16px"
                                fontWeight="semibold"
                                width="163px"
                                height="43px"
                                disabled={isGeneratingDocument}
                              >
                                Submit Order
                              </Button>
                            </Confirm>
                          ) : (
                            <Button
                              colorScheme="actionPrimary"
                              variant="solid"
                              fontSize="16px"
                              fontWeight="semibold"
                              width="163px"
                              height="43px"
                              onClick={this.saveEditedOrder}
                              disabled={isGeneratingDocument || !this.validateSurcharges()}
                            >
                              Save Edited Order
                            </Button>
                          ))}
                      </>
                    )}
                  </Group>
                </DialogFooter>
              </DialogContent>
            </DialogRoot>
          ) : (
            <Loading />
          )}
        </Box>
      </Box>
    );
  }
}

export default EditInternationalOrder;
