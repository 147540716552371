import React, { Component } from 'react';
import Blob from 'blob';
import { saveAs } from 'file-saver';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import Datetime from 'react-datetime';
import {
  Box,
  Button,
  Group,
  Flex,
  Grid,
  GridItem,
  HStack,
  IconButton,
  Input,
  Separator,
  Tabs,
  Text,
  Textarea,
  VStack,
} from '@chakra-ui/react';
import { cloneDeep, isEqual, isString, set, uniq, uniqBy } from 'lodash';
import { connect } from 'react-redux';
import Constants from '../../../Constants';
import { GENERATED_DOCUMENT_ERROR } from '../../../actions/actions_generated_documents';
import {
  addInternationalLine,
  addOrUpdateLineAssociatedModel,
  addOrUpdateLineProductionDetails,
  addOrUpdatePurchaseOrderAssociatedModel,
  cancelOrder,
  deleteLineAssociatedModel,
  deleteLineProductionDetails,
  deletePurchaseOrderAssociatedModel,
  editInternationalLine,
  resetModalOrder,
  updateOrder,
} from '../../../actions/actions_orders';
import { fetchAllTransport } from '../../../actions/actions_transport';
import { ReactComponent as DownloadIcon } from '../../../assets/download-icon.svg';
import { Workbook, commify, s2ab, sheetFromArrayOfArrays } from '../../../functions';
import {
  constructBuyerOptions,
  convertToCamelCase,
  convertToSnakeCase,
  correctDecimalRoundingBlur,
  correctDecimalRoundingChange,
  toSnake,
} from '../../../helpers';
import { buyersSelector } from '../../../reducers/reducer_users';
import {
  findAllEntitiesWithoutFilterInState,
  findEntitiesInState,
} from '../../../slices/masterData/entityManagerSlice';
import { getInternalPoLabel } from '../../../utils';
import Loading from '../../basic/Loading';
import SelectField from '../../basic/SelectField';
import CustomFormControl from '../../core/CustomFormControl';
import DateTimeWithTrappedFocus from '../../core/DateTimeWithTrappedFocus';
import { isAmericanDomesticOrder } from '../../lib/PurchaseOrderFunctions';
import GeneratedDocuments from '../GeneratedDocuments';
import OrderResults from '../OrderResults/OrderResults';
import PurchaseOrderDocuments from '../PurchaseOrderDocuments';
import PurchaseOrderInfo from '../PurchaseOrderInfo';
import PurchaseOrderStatusSection from '../PurchaseOrderStatusSection';
import { filterOptionsByPurchasingOffice } from '../order-form/CreatePurchaseOrderForm/helpers';
import { adaptServerPurchaseOrderToClient } from '../order-form/InternationalOrderForm/helpers';
import InternationalOrderLines from '../order-form/InternationalOrderLines';
import StatusTag from '../order-form/StatusTag';
import InternationalOrderDetails from '../order-form/edit-form/InternationalOrderDetails/InternationalOrderDetails';
import OrderDetails from './OrderDetails/OrderDetails';
import './order-table-modal.scss';
import TransferOrderModal from '../TransferOrderModal/TransferOrderModal';
import Confirm from '../../utilities/Confirm';
import { IoInformationCircleOutline } from 'react-icons/io5';
import { Tooltip } from '@/components/ui/tooltip';
import OrderActivityLog from './OrderActivityLog';
import IssuesXcodes from '../IssuesXcodes/IssuesXcodes';
import { getTenantFromDomain } from '../../../helpers/tenant';
import {
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
  DialogCloseTrigger,
} from '@/components/ui/dialog';
import { Alert } from '../../ui/alert';
import { Checkbox } from '@/components/ui/checkbox';
import { Field } from '@/components/ui/field';

const CustomFormControlWrapper = ({ children, ...rest }) => {
  return <CustomFormControl {...rest}>{children}</CustomFormControl>;
};

CustomFormControlWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};
class OrderTableModal extends Component {
  static propTypes = {
    current_order: PropTypes.shape({
      edit_mode: PropTypes.bool,
    }),
    dispatch: PropTypes.func,
    freight_rate: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        cost: PropTypes.number,
        grinder_uid: PropTypes.string,
        packer_plant_id: PropTypes.number,
      })
    ),
    grinders: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        uid: PropTypes.string,
      })
    ),
    allGrinders: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        uid: PropTypes.string,
      })
    ),
    input_products: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        uid: PropTypes.string,
      })
    ),
    issues_xcode: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        code: PropTypes.string,
        category: PropTypes.string,
      })
    ),
    allIssuesXcodes: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        code: PropTypes.string,
        category: PropTypes.string,
      })
    ),
    allInputProducts: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        uid: PropTypes.string,
      })
    ),
    load_sizes: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        weight: PropTypes.number,
      })
    ),
    packer_plants: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        origin_country: PropTypes.string,
      })
    ),
    allPackerPlants: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        origin_country: PropTypes.string,
      })
    ),
    po_doc_types: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        description: PropTypes.string,
      })
    ),
    selectCurrentModalDetail: PropTypes.func,
    token: PropTypes.string,
    transporters: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        scac: PropTypes.string.isRequired,
      })
    ),
    user: PropTypes.shape({
      id: PropTypes.number,
      email: PropTypes.string,
    }),
    endUsers: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        legal_name: PropTypes.string,
        grinders_uids: PropTypes.arrayOf(PropTypes.string),
        is_active: PropTypes.bool,
      })
    ),
    users: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
      })
    ),
    error: PropTypes.string,
    configs: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        value: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
          PropTypes.bool,
          PropTypes.object,
          PropTypes.array,
        ]),
      })
    ),
    packers: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        origin_country: PropTypes.string,
      })
    ).isRequired,
    allPackers: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        origin_country: PropTypes.string,
      })
    ).isRequired,
    tags: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        value: PropTypes.string.isRequired,
      })
    ).isRequired,
    inco_terms: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
      })
    ).isRequired,
    package_weights: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        weight: PropTypes.number.isRequired,
        unit: PropTypes.string.isRequired,
      })
    ).isRequired,
    email: PropTypes.shape({
      isSendingEmail: PropTypes.bool,
      sendEmailError: PropTypes.string,
    }),
    poDocuments: PropTypes.shape({
      documents: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          name: PropTypes.string.isRequired,
          url: PropTypes.string.isRequired,
        })
      ).isRequired,
      purchaseOrder: PropTypes.instanceOf(Object),
    }),
    groupHousedPremiumConfigs: PropTypes.arrayOf(
      PropTypes.shape({
        packerPlantId: PropTypes.number,
        grinderUid: PropTypes.string,
        productCategory: PropTypes.string,
        productSubCategory: PropTypes.string,
        cost: PropTypes.number,
        deliveryDateFrom: PropTypes.string,
      })
    ).isRequired,
    hasError: PropTypes.bool,
    order_history: PropTypes.instanceOf(Object),
  };

  constructor(props) {
    super(props);
    this.handleSelectValue = this.handleSelectValue.bind(this);
    this.onOrderLinesChange = this.onOrderLinesChange.bind(this);

    this.state = {
      loading: false,
      editing: true,
      saving: false,
      orderChanged: false,
      purchaseOrder: null,
      updatedProductionDetails: [],
      updatedEstimatedProductionDetails: [],
      form: {},
      updatedLines: [],
      newLines: [],
      deletedLoadNotesIds: [],
      deletedIssuesXcodesIds: [],
      updatedPriceAdjustments: [],
      isGeneratingDocument: false,
      emailModalIsOpen: false,
      requiredFields: [],
    };
  }

  setDefaultUOM(po) {
    const { inco_terms: incoTerms } = this.props;
    return {
      ...po,
      buy_unit_of_measure_id: po.buy_unit_of_measure_id || Constants.UNIT_OF_MEASURE.find(i => i.value === 'POUND').id,
      sell_unit_of_measure_id:
        po.sell_unit_of_measure_id || Constants.UNIT_OF_MEASURE.find(i => i.value === 'POUND').id,
      buy_incoterms:
        po.buy_incoterms ||
        get(
          incoTerms?.find(incoTerm => incoTerm.value === 'DAP'),
          'value',
          null
        ),
      sell_incoterms:
        po.sell_incoterms ||
        get(
          incoTerms?.find(incoTerm => incoTerm.value === 'DAP'),
          'value',
          null
        ),
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      current_order,
      input_products,
      po_doc_types,
      transporters,
      load_sizes,
      error,
      email,
      poDocuments,
      hasError,
    } = this.props;
    const { purchaseOrder: ActualPurchaseOrder, saving } = this.state;
    const purchaseOrder = cloneDeep(ActualPurchaseOrder);

    if (hasError && saving) {
      this.setState({ saving: false });
    }

    const isFormOptionsLoaded =
      purchaseOrder && input_products.length && po_doc_types.length && transporters.length && load_sizes.length;

    if (isFormOptionsLoaded !== prevState.isFormOptionsLoaded) {
      this.setState({
        isFormOptionsLoaded,
      });
    }

    if (current_order && !isEqual(current_order, prevProps.current_order)) {
      this.setState({
        // TODO fix this, take out snake case
        purchaseOrder: this.setDefaultUOM(current_order),
        orderChanged: true,
      });
    }

    if (poDocuments.documents.length !== prevProps.poDocuments.documents.length) {
      if (poDocuments.purchaseOrder.lines !== undefined) {
        const poLines = poDocuments.purchaseOrder.lines.map(l => {
          return { id: l.id, sellPricePerUnit: l.sellPricePerUnit };
        });
        purchaseOrder.lines = purchaseOrder.lines.map(l => {
          const line = l;
          const poLine = poLines.filter(pl => pl.id === line.id);
          if (poLine.length > 0) {
            line.sellPricePerUnit = poLine[0].sellPricePerUnit;
          }
          return line;
        });
        Object.keys(purchaseOrder.linesObject).forEach(i => {
          const poLineObject = poLines.filter(pl => pl.id === purchaseOrder.linesObject[i].id);
          if (poLineObject.length > 0) {
            purchaseOrder.linesObject[i].sellPricePerUnit = poLineObject[0].sellPricePerUnit;
          }
        });
      }

      this.setState({
        isGeneratingDocument: false,
        purchaseOrder: {
          // TODO fix this, take out snake case
          ...purchaseOrder,
          documents: uniqBy([...get(purchaseOrder, 'documents', []), ...get(poDocuments, 'documents', [])], 'url'),
          orderChanged: true,
        },
      });
    }
    if (!email.isSendingEmail && prevProps.email.isSendingEmail && !email.sendEmailError) {
      this.setState({
        emailModalIsOpen: false,
      });
    }

    if (error !== prevProps.error && isString(error) && error.includes(GENERATED_DOCUMENT_ERROR)) {
      this.setState({
        isGeneratingDocument: false,
      });
    }
  }

  componentDidMount() {
    const {
      current_order: currentOrder,
      order_history: orderHistory,
      dispatch,
      token,
      // packer_plants: packerPlants,
      allPackerPlants,
      allIssuesXcodes,
    } = this.props;
    if (currentOrder) {
      let purchaseOrder = this.setDefaultUOM(currentOrder);
      let adaptedPurchaseOrder = adaptServerPurchaseOrderToClient(convertToCamelCase(purchaseOrder));
      if (adaptedPurchaseOrder.packerPlantId) {
        const packerPlant = allPackerPlants.find(el => el.id === adaptedPurchaseOrder.packerPlantId);
        adaptedPurchaseOrder = {
          ...adaptedPurchaseOrder,
          estId: packerPlant ? packerPlant.est : '',
          packer_id: packerPlant ? packerPlant.packer_id || packerPlant.packerId : '',
        };
        purchaseOrder = {
          ...purchaseOrder,
          ...adaptedPurchaseOrder,
        };
      }

      if (purchaseOrder.issues_xcodes && purchaseOrder.issues_xcodes.length > 0) {
        purchaseOrder.issues_xcodes = purchaseOrder.issues_xcodes.map(issue => {
          const issueXcode = allIssuesXcodes.find(ix => ix.id === issue.issue_xcode_id);
          return {
            ...issue,
            code: issueXcode?.code || '',
            category: issueXcode?.category || '',
          };
        });
      }

      const isInEditMode = currentOrder.edit_mode || false;
      this.setState({
        isFormOptionsLoaded: false,
        loading: false,
        editing: isInEditMode,
        orderChanged: isInEditMode,
        purchaseOrder,
      });
    }
    if (orderHistory) {
      this.setState({ editing: false, saving: false });
    }
    dispatch(fetchAllTransport(token));
  }

  handleEditClick() {
    this.setState({ editing: true });
  }

  async updateOrder(order, submit = false) {
    const { deletedLoadNotesIds, deletedIssuesXcodesIds, purchaseOrder } = this.state;
    const { lines } = purchaseOrder;
    const { dispatch, token } = this.props;
    this.setState({ saving: true, validationErrors: [] });

    // Added validation for price adjustment lines. 'reason' and 'notes' fields if they are required
    if (lines && lines.length > 0) {
      const vErrors = [];
      lines.forEach(line => {
        line.priceAdjustments.forEach((priceAdjustment, idx) => {
          if (
            !priceAdjustment.value ||
            priceAdjustment.value === '0' ||
            !priceAdjustment.reason ||
            !priceAdjustment.notes
          ) {
            vErrors.push({
              key: 'Price Adjustment',
              value: `${line.line_number || line.lineNumber} and row ${idx + 1}`,
            });
          }
        });
      });

      if (vErrors.length > 0) {
        this.setState({ validationErrors: vErrors, saving: false });
        return;
      }
    }

    // Added validation for issues and xcodes. 'issue_xcode_id' field if it is required
    if (order.issues_xcodes && order.issues_xcodes.length > 0) {
      const vErrors = [];
      order.issues_xcodes.forEach((issue, index) => {
        if (!issue.issue_xcode_id) {
          vErrors.push({ key: 'Load Notes', value: `${index + 1}` });
        }
      });

      if (vErrors.length > 0) {
        this.setState({ validationErrors: vErrors, saving: false });
        return;
      }
    }

    const thisRevisedOrder = JSON.parse(JSON.stringify(order));

    if (thisRevisedOrder.on_hold && !thisRevisedOrder.on_hold_reason) {
      this.setState({ requiredFields: ['on_hold_reason'], saving: false });
      return;
      // eslint-disable-next-line no-else-return
    } else if (!thisRevisedOrder.on_hold || thisRevisedOrder.on_hold_reason) {
      const { requiredFields } = this.state;
      const filteredRequiredFields = requiredFields.filter(field => field !== 'on_hold_reason');
      this.setState({ requiredFields: filteredRequiredFields });
    }

    const packerPlantId = thisRevisedOrder.packer_plant_id || null;
    const modifiedOrder = {
      // pending to ordered
      additinal_grn: thisRevisedOrder.additinal_grn || null,
      entrapment: thisRevisedOrder.entrapment || null,
      buy_unit_of_measure_id: thisRevisedOrder.buy_unit_of_measure_id || null,
      buy_unit_of_measure: thisRevisedOrder.buy_unit_of_measure || null,
      delivery_date: thisRevisedOrder.delivery_date || null,
      documents: thisRevisedOrder.documents || null,
      drop_trailer: thisRevisedOrder.drop_trailer || null,
      lines: thisRevisedOrder.lines,
      packer_plant_id: packerPlantId,
      packer_so_number: thisRevisedOrder.packer_so_number || null,
      on_hold: thisRevisedOrder.on_hold || false,
      on_hold_reason: thisRevisedOrder.on_hold_reason || null,
      production_date: thisRevisedOrder.production_date,
      scheduled_delivery_time: thisRevisedOrder.scheduled_delivery_time || null,
      sell_unit_of_measure_id: thisRevisedOrder.sell_unit_of_measure_id || null,
      sell_unit_of_measure: thisRevisedOrder.sell_unit_of_measure || null,
      shipment_date: thisRevisedOrder.shipment_date || null,
      transport_cost_per_unit: parseFloat(thisRevisedOrder.transport_cost_per_unit) || 0.0,
      transporter_id: thisRevisedOrder.transporter_id || null,
      truck_load_no: thisRevisedOrder.truck_load_no || null,
      end_user_id: thisRevisedOrder.end_user_id || null,
      grinder_po_number: thisRevisedOrder?.grinder_po_number || '',
      freight_rate: parseFloat(thisRevisedOrder?.freight_rate) || null,
      buyer_id: thisRevisedOrder?.buyer_id || null,
      packer_delivery_date: thisRevisedOrder?.packer_delivery_date || null,
      recv_scheduled_delivery_date: thisRevisedOrder?.recv_scheduled_delivery_date || null,
      recv_delivery_date: thisRevisedOrder?.recv_delivery_date || null,
      packer_paid_date: thisRevisedOrder.packer_paid_date || null,
      packer_invoice_date: thisRevisedOrder.packer_invoice_date || null,
      packer_invoice_number: thisRevisedOrder.packer_invoice_number || null,
      tags: thisRevisedOrder?.tags || null,
      buy_incoterms: thisRevisedOrder?.buy_incoterms || null,
      sell_incoterms: thisRevisedOrder?.sell_incoterms || null,
      buy_currency: thisRevisedOrder?.buy_currency || null,
      sell_currency: thisRevisedOrder?.sell_currency || null,
      fec_id: thisRevisedOrder?.fec ? thisRevisedOrder?.fec.id : null,
      internal_comments: thisRevisedOrder?.internal_comments || null,
      external_comments: thisRevisedOrder?.external_comments || null,
      packer_invoice_status: {
        packer_plant_id: packerPlantId,
        ready_for_payment: thisRevisedOrder.readyForPayment || false,
      },
    };

    if (submit === true) {
      modifiedOrder.status = Constants.ORDER_STATUSES.ORDERED;
    } else if (thisRevisedOrder.on_hold) {
      modifiedOrder.status = 'on_hold';
    } else if (thisRevisedOrder.prev_status) {
      modifiedOrder.status = thisRevisedOrder.prev_status;
    } else {
      modifiedOrder.status = thisRevisedOrder.status;
    }

    try {
      // update model
      await this.handlePOLineUpdates();
      await this.handleProductionDetailsUpdate();
      await this.handleEstimatedProductionDetailsUpdate();
      await this.handlePriceAdjustmentsUpdate();
      await dispatch(
        addOrUpdatePurchaseOrderAssociatedModel({
          purchaseOrderId: thisRevisedOrder.id,
          data: thisRevisedOrder.load_notes,
          modelName: 'load_notes',
          token,
        })
      );
      if (thisRevisedOrder.issues_xcodes && thisRevisedOrder.issues_xcodes.length > 0)
        await dispatch(
          addOrUpdatePurchaseOrderAssociatedModel({
            purchaseOrderId: thisRevisedOrder.id,
            data: thisRevisedOrder.issues_xcodes?.map(issue => ({
              ...(issue.id && { id: issue.id }),
              issue_xcode_id: issue.issue_xcode_id,
              description: issue.description || '',
            })),
            modelName: 'issues_xcodes',
            token,
          })
        );
      if (deletedLoadNotesIds)
        await dispatch(
          deletePurchaseOrderAssociatedModel({
            purchaseOrderId: thisRevisedOrder.id,
            toDeleteIds: deletedLoadNotesIds,
            modelName: 'load_notes',
            token,
          })
        );
      if (deletedIssuesXcodesIds && deletedIssuesXcodesIds.length > 0)
        await dispatch(
          deletePurchaseOrderAssociatedModel({
            purchaseOrderId: thisRevisedOrder.id,
            toDeleteIds: deletedIssuesXcodesIds,
            modelName: 'issues_xcodes',
            token,
          })
        );
      // remove lines without ID to prevent them from being created twice
      // since (await this.handlePOLineUpdates()) has already been called
      // to handle newly added lines
      modifiedOrder.lines = modifiedOrder.lines.filter(line => line.id);
      await dispatch(updateOrder(modifiedOrder, thisRevisedOrder.id, submit, token));
    } catch (error) {
      this.setState({ saving: false });
    }

    this.setState(
      {
        loading: true,
        editing: false,
        orderChanged: false,
      },
      () => {
        dispatch(resetModalOrder());
        this.handleModalClose();
      }
    );
  }

  excelExport() {
    const { purchaseOrder } = this.state;
    const tenant = getTenantFromDomain();
    const domain = tenant.includes('cfc') ? 'CFCO' : 'FMG';
    const poLabel = domain === 'CFCO' ? 'CFC PO#' : 'FMG PO#';
    const soLabel = domain === 'CFCO' ? 'CFC SO#' : 'FMG SO#';
    const { user, allGrinders, allPackerPlants } = this.props;
    const worksheetName = 'Purchase Order';
    const data = [
      [
        'User',
        poLabel,
        soLabel,

        'Packer',
        'Packer Plant',
        'Packer SO#',

        'Grinder',
        'Grinder Plant',
        'Grinder PO#',

        'Order Date',
        'Shipment Date',
        'Delivery Date',
        'Production Date',

        'Status',

        'Transport Cost Per Unit',
        'PO Price',
        'PO Quantity',
        'PO Bins',
        'PO Transport Cost',
        'PO All In Cost',

        'PO Modified',

        'Line Number',
        'Line Product',

        'Line Bins',
        'Line Quantity',
        'Line Price Per Unit',
        'Line Order Type',
        'Line Price',

        'Line Expected Production Date',
        'Line Contract Start',
        'Line Contract End',

        'Line COA Link',
        'Line Modified',
        'Line Active',
      ],
    ];
    if (typeof purchaseOrder.lines !== 'undefined') {
      let thisPacker = false;
      allPackerPlants.forEach(pp => {
        if (purchaseOrder.packer_plant_id === pp.id) {
          thisPacker = pp;
        }
      });
      let thisGrinder = false;
      allGrinders.forEach(grinder => {
        if (purchaseOrder?.grinder_uid === grinder.uid) {
          thisGrinder = grinder;
        }
      });
      purchaseOrder.lines.forEach(element2 => {
        // commented out because it is not used
        // let beefInput = false;
        // allInputProducts.forEach(input => {
        //   if (element2.inputProductUid === input.uid) {
        //     beefInput = input;
        //   }
        // });
        const thisRow = [
          user.email,
          purchaseOrder.internal_po_number,
          purchaseOrder.internal_so_number,

          thisPacker.name,
          thisPacker.id,
          purchaseOrder.packer_so_number,

          thisGrinder.name,
          thisGrinder.uid,
          purchaseOrder.grinder_po_number,

          purchaseOrder.order_date.split('T')[0],
          purchaseOrder.shipment_date,
          purchaseOrder.delivery_date,
          element2.productionDetails[0]?.productionDate,

          purchaseOrder.status,

          purchaseOrder.transport_cost_per_unit,
          purchaseOrder.price,
          purchaseOrder.buy_quantity,
          purchaseOrder.number_of_bins,
          Math.round(purchaseOrder.transport_cost, 2),
          Math.round(purchaseOrder.all_in_cost, 2),

          purchaseOrder.modified,

          element2.lineNumber,
          element2.inputProduct?.uid,

          element2.numberOfBins,
          element2.buyQuantity,
          element2.pricePerUnit,
          element2.priceType,
          Math.round(element2.price, 2),

          element2.estimatedProductionDetails[0]?.productionDate,
          element2.contractStartDate,
          element2.contractEndDate,

          element2.coaLink,

          element2.modified,
          element2.active,
        ];
        data.push(thisRow);
      });
    }
    const excelWorkbook = new Workbook();

    const excelWorksheet = sheetFromArrayOfArrays(data);
    excelWorkbook.SheetNames.push(worksheetName);
    excelWorkbook.Sheets[worksheetName] = excelWorksheet;
    const aboutWorkbook = window.XLSX.write(excelWorkbook, {
      bookType: 'xlsx',
      bookSST: true,
      type: 'binary',
    });
    saveAs(new Blob([s2ab(aboutWorkbook)], { type: 'application/octet-stream' }), 'Single Ingredient Order.xlsx');
  }

  deleteOrder() {
    const { dispatch, token } = this.props;
    const { purchaseOrder } = this.state;
    // eslint-disable-next-line no-alert
    const confirm = window.confirm('Are you sure you want to cancel this order?');
    if (confirm) {
      dispatch(cancelOrder(purchaseOrder, token));
      dispatch(resetModalOrder());
    }
  }

  handleChangeValue = event => {
    const { name, value, checked, type } = event.target;

    if (type === 'checkbox' || type === 'radio') {
      this.handleSelectValue(name, checked);
    } else {
      this.handleSelectValue(name, value);
    }
  };

  handleIssueXcode = (index, field, value) => {
    const { purchaseOrder } = this.state;
    const { allIssuesXcodes: allIssuesXcodesOption } = this.props;
    const updatedPurchaseOrder = cloneDeep(purchaseOrder);

    if (field === 'code') {
      if (updatedPurchaseOrder.issues_xcodes[index][field] !== value) {
        const { category } = updatedPurchaseOrder.issues_xcodes[index];
        const issueXcode = allIssuesXcodesOption.find(ix => ix.code === value && ix.category === category);
        updatedPurchaseOrder.issues_xcodes[index].issue_xcode_id = issueXcode?.id;
      }
    }

    if (field === 'category') {
      updatedPurchaseOrder.issues_xcodes[index].issue_xcode_id = null;
      updatedPurchaseOrder.issues_xcodes[index].code = null;
    }

    updatedPurchaseOrder.issues_xcodes[index][field] = value;
    this.setState({ purchaseOrder: updatedPurchaseOrder, orderChanged: true });
  };

  handleAddIssueXcode = () => {
    const { purchaseOrder } = this.state;
    const updatedPurchaseOrder = cloneDeep(purchaseOrder);
    updatedPurchaseOrder.issues_xcodes.push({ issue_xcode_id: '', description: '' });
    this.setState({ purchaseOrder: updatedPurchaseOrder, orderChanged: true });
  };

  handleDeleteIssueXcode = index => {
    const { purchaseOrder } = this.state;
    const { deletedIssuesXcodesIds } = this.state;
    const updatedPurchaseOrder = cloneDeep(purchaseOrder);

    if (updatedPurchaseOrder.issues_xcodes[index].id) {
      this.setState({
        deletedIssuesXcodesIds: [...deletedIssuesXcodesIds, updatedPurchaseOrder.issues_xcodes[index].id],
      });
    }

    updatedPurchaseOrder.issues_xcodes.splice(index, 1);

    this.setState({
      purchaseOrder: updatedPurchaseOrder,
    });
  };

  handleSelectValue(name, e) {
    const { freight_rate: freightRate, packers, packer_plants: packerPlants } = this.props;
    const { purchaseOrder } = this.state;
    const editedPendingOrder = JSON.parse(JSON.stringify(purchaseOrder));
    let value = false;
    const otherChanges = {};
    if (e) {
      const targetValue = e.target ? e.target.value : e.value;
      if (typeof e.target !== 'undefined') {
        value = targetValue;
      } else if (typeof e.value !== 'undefined') {
        value = targetValue;
        // eslint-disable-next-line no-underscore-dangle
      } else if (e._isAMomentObject) {
        if (name.indexOf('date') > -1) {
          value = e.format('YYYY-MM-DD');
        } else {
          value = e.format('HH:mm');
        }
      } else {
        value = e;
      }
    } else {
      value = '';
    }

    if (name === 'transport_cost_per_unit') {
      const { newLines } = this.state;
      const { lines } = purchaseOrder;
      const clonedLines = cloneDeep(lines);
      const clonedNewLines = cloneDeep(newLines);
      editedPendingOrder.transport_cost = editedPendingOrder.buy_quantity * editedPendingOrder.transport_cost_per_unit;
      editedPendingOrder.all_in_cost = editedPendingOrder.price + editedPendingOrder.transport_cost;

      const handleUpdateLineItm = line => {
        const clonedLine = cloneDeep(line);
        const fobPrice = parseFloat(line.pricePerUnit);
        const groupHousedPremiumRate = parseFloat(line.groupHousedPremiumRate);
        if (!isNaN(fobPrice) && !isNaN(groupHousedPremiumRate)) {
          // Calculate and set the Buy Price Per Unit with 4 decimal places
          // Handle freightRate as 0 if it is NaN
          const validFreightRate = isNaN(e.target.value) ? 0 : +e.target.value;
          clonedLine.buyPricePerUnit = (fobPrice + groupHousedPremiumRate + validFreightRate).toFixed(4);
        }

        return clonedLine;
      };

      const newUpdatedLines = clonedLines.map(handleUpdateLineItm);
      const newNewLines = clonedNewLines.map(handleUpdateLineItm);

      this.setState({ updatedLines: newUpdatedLines, newLines: newNewLines });
    }

    if (name.indexOf('line_items') > -1) {
      const property = name.split('-')[1];
      editedPendingOrder.line_items.forEach(line_item => {
        line_item[property] = value;
      });
    } else if (name === 'estId') {
      const estPackerPlant = packerPlants
        ?.filter(option => Constants.AMERICA_DOMESTIC_COUNTRIES.includes(option?.origin_country?.toLowerCase()))
        .find(p => p.est === value);
      editedPendingOrder.estId = value;
      editedPendingOrder.packer_plant_id = estPackerPlant ? estPackerPlant.id : '';
      editedPendingOrder.packer_id = estPackerPlant ? estPackerPlant.packer_id : '';

      if (estPackerPlant) {
        const transportList = freightRate || [];
        const transportCost = transportList.find(item => {
          return (
            item.grinder_uid === editedPendingOrder.grinder_uid &&
            item.packer_plant_id === editedPendingOrder.packer_plant_id
          );
        });

        if (transportCost) {
          editedPendingOrder.transport_cost_per_unit = transportCost.cost.toFixed(4);
          editedPendingOrder.transport_cost =
            editedPendingOrder.buy_quantity * editedPendingOrder.transport_cost_per_unit;
          editedPendingOrder.all_in_cost = editedPendingOrder.price + editedPendingOrder.transport_cost;
        } else {
          editedPendingOrder.transport_cost_per_unit = '';
          editedPendingOrder.transport_cost = 0;
          editedPendingOrder.all_in_cost = editedPendingOrder.price || 0;
        }
      }
    } else if (name === 'packer_id') {
      if (editedPendingOrder.packer_id !== value) {
        editedPendingOrder.packer_plant_id = '';
        editedPendingOrder.estId = '';
      }
      const estPacker = packers.find(p => p.id === value);
      editedPendingOrder.packer_id = estPacker ? estPacker.id : '';
    } else if (name === 'tags') {
      editedPendingOrder[name] = value && value.length ? value.map(lbl => lbl.value) : [];
    } else if (name === 'deletedLoadNotesIds') {
      const { deletedLoadNotesIds } = this.state;
      const { load_notes: loadNotes } = editedPendingOrder;
      const index = e;
      const loadNoteToBeDeleted = loadNotes[index];
      editedPendingOrder.load_notes = loadNotes.filter((loadNote, idx) => idx !== index);
      if (loadNoteToBeDeleted.id) otherChanges[name] = [...deletedLoadNotesIds, loadNoteToBeDeleted.id];
    } else if (name === 'deletedIssuesXcodesIds') {
      const { deletedIssuesXcodesIds } = this.state;
      const { issues_xcodes: issuesXcodes } = editedPendingOrder;
      const index = e;
      const isseXcodesToBeDeleted = issuesXcodes[index];
      editedPendingOrder.issues_xcodes = issuesXcodes.filter((loadNote, idx) => idx !== index);
      if (isseXcodesToBeDeleted.id) otherChanges[name] = [...deletedIssuesXcodesIds, isseXcodesToBeDeleted.id];
    } else {
      editedPendingOrder[name] = value;
      editedPendingOrder[toSnake(name)] = value;
    }
    this.setState({ purchaseOrder: editedPendingOrder, ...otherChanges, orderChanged: true });
  }

  onPackerPlantChange(e) {
    const { freight_rate: freightRate, packer_plants: packerPlants } = this.props;
    const { purchaseOrder } = this.state;
    const editedPendingOrder = cloneDeep(purchaseOrder);

    editedPendingOrder.packer_plant_id = e ? e.value : null;
    const estPackerPlant = packerPlants.find(p => p.id === e.value);
    editedPendingOrder.estId = estPackerPlant.est;

    const transportList = freightRate || [];
    const transportCost = transportList.find(item => {
      return (
        item?.grinder_uid === editedPendingOrder?.grinder_uid &&
        item?.packer_plant_id === editedPendingOrder?.packer_plant_id
      );
    });

    if (transportCost) {
      editedPendingOrder.transport_cost_per_unit = transportCost.cost.toFixed(4);
      editedPendingOrder.transport_cost = editedPendingOrder.buy_quantity * editedPendingOrder.transport_cost_per_unit;
      editedPendingOrder.all_in_cost = editedPendingOrder.price + editedPendingOrder.transport_cost;
    } else {
      editedPendingOrder.transport_cost_per_unit = '';
    }

    this.setState({ purchaseOrder: editedPendingOrder, orderChanged: true });
  }

  onOrderLinesChange(lines) {
    const { purchaseOrder } = this.state;
    const updatedOrder = cloneDeep(purchaseOrder);
    const linesObject = lines.reduce(
      (acc, curr) => ({
        ...acc,
        [curr.idx]: convertToCamelCase(curr),
      }),
      {}
    );
    updatedOrder.lines = lines;
    updatedOrder.linesObject = linesObject;

    this.setState({
      orderChanged: true,
      purchaseOrder: updatedOrder,
    });
  }

  handleModalClose = () => {
    const { selectCurrentModalDetail } = this.props;
    // Also be sure to test for how this impacts app performance.
    // this.props.dispatch(autoResolveTasksForOrder(token, current_order.id, current_order.grinder_po_number));
    selectCurrentModalDetail(false);
  };

  handleUpdateLine = (lineIndex, fieldName, value) => {
    const {
      purchaseOrder,
      purchaseOrder: { linesObject },
      newLines,
    } = this.state;
    const { input_products: inputProducts, groupHousedPremiumConfigs } = this.props;

    const line = linesObject[lineIndex];
    const updatedLine = { ...line };
    // const updatedChanges = {};
    updatedLine.hedgeItems = { ...line.hedgeItems };
    set(updatedLine, fieldName, value);
    const copyUpdatedLines = cloneDeep(this.state.updatedLines);
    // reset packerIngredientDescription if ingredient changes
    if (fieldName === 'inputProductUid') {
      updatedLine.packerIngredientDescription = inputProducts.find(ip => ip.uid === value)?.description;
      // Group Housed Premium Calculation ON UPdate Screen
      const productUid = value;
      const product = inputProducts.find(prdct => prdct.uid === productUid);
      // updatedLine.groupHousedPremiumRate =900
      if (product) {
        const purchaseOrderDeliveryDate = new Date(purchaseOrder.deliveryDate);
        const config = groupHousedPremiumConfigs.find(
          confg =>
            confg.packerPlantId === purchaseOrder.packerPlantId &&
            confg.grinderUid === purchaseOrder.grinderUid &&
            confg.productCategory === product.category &&
            confg.productSubCategory === product.sub_category &&
            new Date(confg.deliveryDateFrom) <= purchaseOrderDeliveryDate
        );

        if (config) {
          updatedLine.groupHousedPremiumRate = config.cost;
        } else {
          updatedLine.groupHousedPremiumRate = 0;
        }
      }
    }
    // Calculate Buy Price Unit Calculation Update the calculation for Buy Price Per Unit in the Purchase Order (PO) card.
    // The calculation is based on the formula: FOB + Freight + IF GHP = Buy Price Per Unit (Packer Delivered Price)
    if (fieldName === 'groupHousedPremiumRate' || fieldName === 'pricePerUnit') {
      const freightRate = parseFloat(purchaseOrder.transport_cost_per_unit); // Freight Rate
      const fobPrice = parseFloat(updatedLine.pricePerUnit); // Parse the FOB price
      const groupHousedPremiumRate = parseFloat(updatedLine.groupHousedPremiumRate); // GHP
      if (!isNaN(fobPrice) && !isNaN(groupHousedPremiumRate)) {
        // Calculate and set the Buy Price Per Unit with 4 decimal places
        // Handle freightRate as 0 if it is NaN
        const validFreightRate = isNaN(freightRate) ? 0 : freightRate;
        updatedLine.buyPricePerUnit = (fobPrice + groupHousedPremiumRate + validFreightRate).toFixed(4);
      } else {
        // Log an error if any value is not a valid number
        console.error('One of the values is not a valid number:', fobPrice, groupHousedPremiumRate);
      }
    }

    /* saves updated lines objects in a list on state
     this list will be referred on final save/submit to dispatch backend calls */
    if (updatedLine.id && !isEqual(line, updatedLine)) {
      const updatedLineIndex = copyUpdatedLines.findIndex(l => l.id === updatedLine.id);
      if (updatedLineIndex > -1) {
        copyUpdatedLines[updatedLineIndex] = updatedLine;
      } else {
        copyUpdatedLines.push(updatedLine);
      }
      this.setState({ updatedLines: copyUpdatedLines });
    }

    /* saves new lines objects in a list on state
     this list will be referred on final save/submit to dispatch backend calls */
    if (!updatedLine.id && updatedLine.inputProductUid && updatedLine.buyQuantity) {
      /* assign order packerPlantId to the newly created line
        place to change when lines on an order can be from
        different packerPlant establishment
      */
      let copyNewLines = cloneDeep(newLines);
      updatedLine.packerPlantId = purchaseOrder.packerPlantId;
      if (copyNewLines.some(l => l.inputProductUid === updatedLine.inputProductUid)) {
        // This line has already been added.
        copyNewLines = copyNewLines.map(l => {
          if (l.inputProductUid === updatedLine.inputProductUid) {
            return updatedLine;
          }
          return l;
        });
      } else {
        copyNewLines.push(updatedLine);
      }

      this.setState({ newLines: copyNewLines });
    }
    const updatedLinesObject = {
      ...linesObject,
      [lineIndex]: updatedLine,
    };
    this.setState({
      purchaseOrder: {
        ...purchaseOrder,
        linesObject: updatedLinesObject,
        lines: Object.values(updatedLinesObject),
      },
    });
  };

  onProductionDetailChange = (
    lineIdx,
    lineId,
    productionDetails,
    toDeleteProductionDetailsIds,
    productionDetailsKey = 'productionDetails'
  ) => {
    const updatedProductionDetailsKey =
      productionDetailsKey === 'productionDetails' ? 'updatedProductionDetails' : 'updatedEstimatedProductionDetails';
    const {
      purchaseOrder,
      purchaseOrder: { linesObject },
    } = this.state;
    if (!linesObject) return;
    const line = linesObject[lineIdx];
    const updatedLine = { ...line, [productionDetailsKey]: productionDetails };
    (prevState => {
      const prevStateUpdatedProductionDetails = prevState[updatedProductionDetailsKey];
      const existingUpdateIndex = prevStateUpdatedProductionDetails.findIndex(
        updatedProductionDate => updatedProductionDate.lineId === lineId
      );

      const productionDetailUpdate = {
        lineId,
        [productionDetailsKey]: productionDetails,
        toDeleteProductionDetailsIds,
      };
      if (existingUpdateIndex > -1) {
        productionDetailUpdate.toDeleteProductionDetailsIds = uniq([
          ...prevStateUpdatedProductionDetails[existingUpdateIndex].toDeleteProductionDetailsIds,
          ...productionDetailUpdate.toDeleteProductionDetailsIds,
        ]);
        prevStateUpdatedProductionDetails.splice(existingUpdateIndex, 1);
      }

      this.setState({
        [updatedProductionDetailsKey]: [...prevStateUpdatedProductionDetails, productionDetailUpdate],
      });
    })(this.state);

    const updatedLinesObject = {
      ...linesObject,
      [lineIdx]: updatedLine,
    };
    this.setState({
      orderChanged: true,
      purchaseOrder: {
        ...purchaseOrder,
        linesObject: updatedLinesObject,
        lines: Object.values(updatedLinesObject),
      },
    });
  };

  handleCLUpdate = async (lineIdx, fieldName, value) => {
    const {
      purchaseOrder,
      purchaseOrder: { linesObject },
    } = this.state;
    const line = linesObject[lineIdx];
    const updatedLine = { ...line, fieldName: value };

    const updatedLinesObject = {
      ...linesObject,
      [lineIdx]: updatedLine,
    };
    this.setState({
      orderChanged: true,
      purchaseOrder: {
        ...purchaseOrder,
        linesObject: updatedLinesObject,
        lines: Object.values(updatedLinesObject),
      },
    });
  };

  handleTemperatureUpdate = async (lineIdx, temperatures, opTempType) => {
    const {
      purchaseOrder,
      purchaseOrder: { linesObject },
    } = this.state;
    const line = linesObject[lineIdx];
    const updatedLine = { ...line, temperatures, opTempType };

    const updatedLinesObject = {
      ...linesObject,
      [lineIdx]: updatedLine,
    };
    this.setState({
      orderChanged: true,
      purchaseOrder: {
        ...purchaseOrder,
        linesObject: updatedLinesObject,
        lines: Object.values(updatedLinesObject),
      },
    });
  };

  onPriceAdjustmentChange = (lineIdx, lineId, priceAdjustments, toDeletePriceAdjustmentsIds) => {
    const {
      purchaseOrder,
      purchaseOrder: { linesObject },
    } = this.state;
    const line = linesObject[lineIdx];
    const updatedLine = { ...line, priceAdjustments };
    (prevState => {
      const prevStateUpdatedPriceAdjustments = prevState.updatedPriceAdjustments;
      const existingUpdateIndex = prevStateUpdatedPriceAdjustments.findIndex(
        updatedProductionDate => updatedProductionDate.lineId === lineId
      );

      const priceAdjustmentUpdate = { lineId, priceAdjustments, toDeletePriceAdjustmentsIds };
      if (existingUpdateIndex > -1) {
        priceAdjustmentUpdate.toDeletePriceAdjustmentsIds = uniq([
          ...prevStateUpdatedPriceAdjustments[existingUpdateIndex].toDeletePriceAdjustmentsIds,
          ...priceAdjustmentUpdate.toDeletePriceAdjustmentsIds,
        ]);
        prevStateUpdatedPriceAdjustments.splice(existingUpdateIndex, 1);
      }

      this.setState({
        updatedPriceAdjustments: [...prevStateUpdatedPriceAdjustments, priceAdjustmentUpdate],
      });
    })(this.state);

    const updatedLinesObject = {
      ...linesObject,
      [lineIdx]: updatedLine,
    };
    this.setState({
      orderChanged: true,
      purchaseOrder: {
        ...purchaseOrder,
        linesObject: updatedLinesObject,
        lines: Object.values(updatedLinesObject),
      },
    });
  };

  handleProductionDetailsUpdate = async () => {
    this.state.updatedProductionDetails.forEach(lineUpdate => {
      const toDeleteIds = get(lineUpdate, 'toDeleteProductionDetailsIds', []);
      if (toDeleteIds.length) {
        this.props.dispatch(deleteLineProductionDetails(lineUpdate.lineId, toDeleteIds, this.props.token));
      }

      const changedProductionDetails = get(lineUpdate, 'productionDetails', []);
      if (changedProductionDetails.length) {
        const snaked = convertToSnakeCase(changedProductionDetails).filter(x => !toDeleteIds?.includes(x.id));
        // Remove null value fiels from payload
        // Note - estimated_date is on schema, but not an editable field, so BE
        // will throw an error if it is not already present.
        snaked.forEach((pd, idx) => {
          Object.entries(pd).forEach(([key, value]) => {
            if (value === null) {
              delete snaked[idx][key];
            }
          });
        });
        this.props.dispatch(addOrUpdateLineProductionDetails(lineUpdate.lineId, snaked, this.props.token));
      }
    });
  };

  handlePriceAdjustmentsUpdate = async () => {
    const { token } = this.props;
    this.state.updatedPriceAdjustments.forEach(lineUpdate => {
      const toDeleteIds = get(lineUpdate, 'toDeletePriceAdjustmentsIds', []);
      if (toDeleteIds.length > 0) {
        this.props.dispatch(
          deleteLineAssociatedModel({
            purchaseOrderLineId: lineUpdate.lineId,
            toDeleteIds,
            modelName: 'price_adjustments',
            token,
          })
        );
      }

      const changedPriceAdjustments = get(lineUpdate, 'priceAdjustments', []);
      if (changedPriceAdjustments.length) {
        const snaked = convertToSnakeCase(changedPriceAdjustments).filter(x => !toDeleteIds?.includes(x.id));
        // Remove null value fiels from payload
        // Note - estimated_date is on schema, but not an editable field, so BE
        // will throw an error if it is not already present.
        snaked.forEach((pd, idx) => {
          Object.entries(pd).forEach(([key, value]) => {
            if (value === null || key === 'purchase_order_line_id') {
              delete snaked[idx][key];
            }
          });
        });

        this.props.dispatch(
          addOrUpdateLineAssociatedModel({
            purchaseOrderLineId: lineUpdate.lineId,
            data: snaked.filter(x => x.id === undefined),
            modelName: 'price_adjustments',
            token,
          })
        );
      }
    });
  };

  handleEstimatedProductionDetailsUpdate = async () => {
    const { token } = this.props;
    this.state.updatedEstimatedProductionDetails.forEach(lineUpdate => {
      const toDeleteIds = get(lineUpdate, 'toDeleteProductionDetailsIds', []);
      if (toDeleteIds.length) {
        this.props.dispatch(
          deleteLineAssociatedModel({
            purchaseOrderLineId: lineUpdate.lineId,
            toDeleteIds,
            modelName: 'production_details',
            paramsObject: {
              estimated: true,
            },
            token,
          })
        );
      }

      const changedEstimatedProductionDetails = get(lineUpdate, 'estimatedProductionDetails', []);
      if (changedEstimatedProductionDetails.length) {
        const snaked = convertToSnakeCase(changedEstimatedProductionDetails).filter(x => !toDeleteIds?.includes(x.id));
        // Remove null value fiels from payload
        // Note - estimated_date is on schema, but not an editable field, so BE
        // will throw an error if it is not already present.
        snaked.forEach((pd, idx) => {
          Object.entries(pd).forEach(([key, value]) => {
            if (value === null) {
              delete snaked[idx][key];
            }
          });
        });

        this.props.dispatch(
          addOrUpdateLineAssociatedModel({
            purchaseOrderLineId: lineUpdate.lineId,
            data: snaked,
            modelName: 'production_details',
            paramsObject: {
              estimated: true,
            },
            token,
          })
        );
      }
    });
  };

  handlePPUChangeValue = (key, index, e) => {
    if (isNaN(e)) {
      return;
    }

    // validating and limitting input to 4 decimal places
    const rounded = correctDecimalRoundingChange(e, 4);
    this.handleUpdateLine(index, key, rounded);
  };

  handlePPURoundingDecimal = (key, index, e) => {
    // format PPU input to 4 decimal places
    const { result, roundedNumber } = correctDecimalRoundingBlur(e, parseFloat(e) === parseFloat(0) ? 0 : 4);
    if (!isNaN(roundedNumber)) {
      this.handleUpdateLine(index, key, result);
    }
  };

  handleAddUSADomesticLine = () => {
    const {
      purchaseOrder,
      purchaseOrder: { lineCounter, linesObject, lineIndices },
    } = this.state;

    const newLineIndex = lineCounter + 1;
    const newLine = {
      ...convertToCamelCase(Constants.PO_LINE_OBJ),
      idx: newLineIndex,
      line_number: newLineIndex,
      price_adjustments: [],
    };
    const updatedLinesObject = {
      ...linesObject,
      [newLineIndex]: newLine,
    };

    this.setState({
      purchaseOrder: {
        ...purchaseOrder,
        lineCounter: newLineIndex,
        linesObject: updatedLinesObject,
        lines: Object.values(updatedLinesObject),
        lineIndices: [...lineIndices, newLineIndex],
      },
    });
  };

  handleAddLine = () => {
    const currentLines = get(this.state, 'purchaseOrder.lines') || [];
    const linesObject = get(this.state, 'purchaseOrder.linesObject') || {};
    const newLineNumber = currentLines.length + 1;
    const newLine = { ...Constants.PO_LINE_OBJ, idx: newLineNumber, line_number: newLineNumber };
    const newCurrentLines = [...currentLines, newLine];

    const updatedLinesObject = {
      ...linesObject,
      [newLine.line_number]: newLine,
    };

    this.setState(prevState => ({
      purchaseOrder: {
        ...prevState.purchaseOrder,
        lines: newCurrentLines,
        linesObject: updatedLinesObject,
      },
    }));
  };

  handleDeleteUSADomesticLine = (lineIdx, deletedLine) => {
    const {
      purchaseOrder: { lines, lineIndices, linesObject },
      purchaseOrder,
      updatedLines,
    } = this.state;
    const updatedLineIndices = !deletedLine.id ? lineIndices.filter(idx => idx !== lineIdx) : lineIndices;
    const deletedLineIndex = lines.findIndex(line => line.idx === lineIdx);
    const linesObjectClone = cloneDeep(linesObject);

    if (lines[deletedLineIndex] && deletedLine.id) {
      lines[deletedLineIndex].active = false;
    } else {
      delete lines[deletedLineIndex];
    }

    if (linesObjectClone[lineIdx] && deletedLine.id) {
      linesObjectClone[lineIdx].active = false;
    } else {
      delete linesObjectClone[lineIdx];
    }

    // Remove line and shipment port mark map.
    if (purchaseOrder.billOfLading && purchaseOrder.billOfLading.shipmentPortMarks) {
      purchaseOrder.billOfLading.shipmentPortMarks.forEach((mark, markIdx) => {
        purchaseOrder.billOfLading.shipmentPortMarks[markIdx].purchaseOrderLineIds =
          purchaseOrder.billOfLading.shipmentPortMarks[markIdx].purchaseOrderLineIds.filter(
            id => id !== deletedLine.id
          );
      });
    }
    this.setState(
      {
        purchaseOrder: {
          ...purchaseOrder,
          lines,
          lineIndices: updatedLineIndices,
          linesObject: linesObjectClone,
        },
      },
      () => {
        // this is for any lines that aren't yet added to db
        if (!deletedLine.id) return;

        const copyUpdatedLines = cloneDeep(updatedLines);
        const deletedUpdatedLineIndex = copyUpdatedLines.findIndex(line => line.id === deletedLine.id);
        if (deletedUpdatedLineIndex > -1) {
          copyUpdatedLines[deletedUpdatedLineIndex] = { ...deletedLine, active: false };
        } else {
          copyUpdatedLines.push({ ...deletedLine, active: false });
        }
        this.setState({ updatedLines: copyUpdatedLines });
      }
    );
  };

  handleDeleteLine = (line, index) => {
    const currentLines = get(this.state, 'purchaseOrder.lines') || [];
    if (line.id) {
      const confirm = window.confirm('Are you sure you want to delete order line?');
      if (confirm) {
        const revisedOrderLines = currentLines.map(l => (l.id === line.id ? { ...l, active: false } : l));
        const deletedLine = document.getElementById('deletedLine');
        deletedLine.classList.add('deletedLineStyle');
        this.setState(prevState => ({
          purchaseOrder: {
            ...prevState.purchaseOrder,
            lines: revisedOrderLines,
          },
        }));
      }
    } else {
      const newLines = [];
      currentLines.forEach((thisLine, i) => {
        if (i !== index) {
          newLines.push(thisLine);
        }
      });

      this.setState(prevState => ({
        purchaseOrder: {
          ...prevState.purchaseOrder,
          lines: newLines,
        },
      }));
    }
  };

  handlePOLineUpdates = async () => {
    const { dispatch, token } = this.props;

    const updatedLines = cloneDeep(this.state.updatedLines);
    const newLines = cloneDeep(this.state.newLines);

    // sort lines to make sure that cancelled lines and lines with the least buy quantity values are updated first
    updatedLines.sort((a, b) => {
      if (!a.active && b.active) return -1;
      if (a.active && !b.active) return 1;
      return a.buyQuantity - b.buyQuantity;
    });

    // prevent race condition where the request to add a new line is handled before updating the existing lines
    // and edit lines requests are not sent in correct order
    for (const updatedLine of updatedLines) {
      await dispatch(
        editInternationalLine({
          purchaseOrderId: this.state.purchaseOrder.id,
          line: updatedLine,
          token,
        })
      );
    }

    for (const newLine of newLines) {
      await dispatch(addInternationalLine({ purchaseOrderId: this.state.purchaseOrder.id, line: newLine, token }));
    }

    // reset newLines once they are added to db
    // TODO handle it better
    this.setState({ newLines: [] });
  };

  render() {
    const {
      purchaseOrder,
      editing,
      isFormOptionsLoaded,
      loading,
      orderChanged,
      isGeneratingDocument,
      emailModalIsOpen,
      saving,
      validationErrors,
    } = this.state;
    const {
      current_order,
      dispatch,
      grinders,
      allGrinders,
      input_products,
      issues_xcode,
      allIssuesXcodes,
      allInputProducts,
      po_doc_types: poDocTypes,
      packer_plants,
      allPackerPlants,
      token,
      transporters,
      user,
      endUsers,
      error,
      configs,
      packers,
      allPackers,
      users,
      tags,
      package_weights: packageWeights,
      load_sizes: loadSizes,
      price_agreements: priceAgreements,
    } = this.props;

    const currentOrderCameled = convertToCamelCase(current_order);
    const purchaseOrderCameled = convertToCamelCase(purchaseOrder);

    const orgType = get(user, 'organisation.org_type', '');
    const isPackerUser = orgType.indexOf('packer') > -1;
    const isGrinderUser = orgType.indexOf('grinder') > -1;
    const inputIsDisabled = !editing && !isPackerUser;
    const purchasingOffice = purchaseOrderCameled?.purchasingOffice || Constants.BUSINESS_OFFICES.CHICAGO.value;

    const valid = current => {
      return current.isAfter(Datetime.moment().subtract(8, 'day'));
    };

    const ingredientOptions = [];

    input_products?.forEach(element => {
      ingredientOptions.push({ value: element.id, label: element.name });
    });

    const filteredPriceAgreements = priceAgreements?.filter(pa => pa.product_type === currentOrderCameled.productType);

    const filteredPriceAgreementOptions = filteredPriceAgreements
      .filter(item => !item.delisted) // Exclude delisted items
      .map(item => ({
        delisted: item.delisted,
        label: item.price_agreement,
        value: item.id,
      }));
    const packerOptions = filterOptionsByPurchasingOffice(
      packers.map(el => {
        return { value: el.id, label: el.name, purchasingOffice: el.purchasing_office || el.purchasingOffice };
      }),
      purchasingOffice
    );
    const sortedPackerOptions = packerOptions?.sort((a, b) => (a.label > b.label ? 1 : -1));

    const packerId = get(purchaseOrder, 'packer_id');
    const packerPlantOptionsByPacker = packerId
      ? packer_plants
          .filter(pp => pp.packer_id === packerId)
          .map(el => ({
            value: el.id,
            label: el.name,
            origin_country: el.origin_country,
          }))
      : packer_plants.map(el => ({ value: el.id, label: el.name, origin_country: el.origin_country }));

    const sortedPackerPlantOptionsByPacker = packerPlantOptionsByPacker
      ?.filter(option => Constants.AMERICA_DOMESTIC_COUNTRIES.includes((option.origin_country || '').toLowerCase()))
      .sort((a, b) => (a.label > b.label ? 1 : -1));

    const grinderOptions = [];
    grinders?.forEach(element => {
      grinderOptions.push({ value: element.uid, label: element.name });
    });

    const transporterOptions = [];
    transporters?.forEach(element => {
      transporterOptions.push({
        value: element.id,
        label: `${element.name} (${element.scac})`,
      });
    });

    if (purchaseOrder) {
      let allIngredientCosts = 0;
      const lines = get(purchaseOrder, 'lines', []);
      lines.forEach(line => {
        allIngredientCosts += line.price;
      });

      const costPerUnit = purchaseOrder.all_in_cost / purchaseOrder.buy_quantity;

      let deliveryTime = null;
      const TIME_STRING_LEN = 5;

      if (purchaseOrder.scheduled_delivery_time) {
        if (purchaseOrder.scheduled_delivery_time.length > TIME_STRING_LEN) {
          deliveryTime = purchaseOrder.scheduled_delivery_time.slice(0, TIME_STRING_LEN);
        } else {
          deliveryTime = purchaseOrder.scheduled_delivery_time;
        }
      }

      const dropTrailerOptions = [
        { value: '', label: 'None' },
        { value: true, label: 'Yes' },
        {
          value: false,
          label: 'No',
        },
      ];

      const grinder = allGrinders.find(el => el.uid === currentOrderCameled.grinderUid);
      const endUserOptions = endUsers
        // eslint-disable-next-line no-shadow
        .filter(e => (e.is_active || e.isActive) === true)
        .map(_endUser => ({
          label: _endUser.legal_name || _endUser.legalName,
          value: _endUser.id,
          grinders_uids: _endUser.grinders_uids || _endUser.grindersUids,
        }));
      const internalPoLabel = getInternalPoLabel(configs);

      const isEditableGrinderPoNumber =
        grinder?.po_strategy === Constants.PO_STRATEGY.MANUAL ||
        grinder?.po_strategy === Constants.PO_STRATEGY.SEQUENCE_EDITABLE;

      const orderPackerPlant = packer_plants.find(opt => opt.id === purchaseOrder.packer_plant_id);
      const packerPlantOriginCountry = orderPackerPlant ? orderPackerPlant.origin_country : '';
      const endUserValue = endUsers.find(obj => obj.id === purchaseOrder.end_user_id);
      const isUSADomestic = isAmericanDomesticOrder({
        grinder: convertToCamelCase(grinder) || {},
        packerPlant: convertToCamelCase(orderPackerPlant) || {},
      });

      const endUserCurrent = {
        label: endUserValue?.legal_name,
        value: endUserValue?.id,
      };

      const buyerOptions = constructBuyerOptions(buyersSelector(users), user);
      const tagOptions = tags && tags.map(l => ({ label: l.value, value: l.value }));

      const isFrozenProduct = !purchaseOrderCameled.isChilled;
      let filteredInputProducts = input_products;

      if (purchaseOrder.product_type) {
        filteredInputProducts = input_products.filter(ip => ip.product_type === purchaseOrder.product_type);
      }

      const baseDateInputProps = {
        className: 'form-control m-input',
        disabled: inputIsDisabled,
      };

      const shippingDateInputProps = {
        ...baseDateInputProps,
        placeholder: 'Shipping date',
      };

      const deliveryDateInputProps = {
        ...baseDateInputProps,
        placeholder: isUSADomestic && !isFrozenProduct ? 'Scheduled Delivery Date' : 'Delivery date',
        className: `${baseDateInputProps.className} required`,
      };
      const deliveryTimeInputProps = {
        ...baseDateInputProps,
        placeholder: 'Time',
        className: `${baseDateInputProps.className} required`,
      };

      const productionDateInputProps = {
        ...baseDateInputProps,
        placeholder: 'Production date',
      };

      const packerDeliveryDateInputProps = {
        ...baseDateInputProps,
        placeholder: 'Packer Delivery Date',
      };
      const isProductAndPricingDetails = isUSADomestic && !isFrozenProduct && purchaseOrderCameled.id;
      return (
        <DialogRoot modal open={purchaseOrder.id} onClose={this.handleModalClose} placement="center">
          <DialogContent minWidth="5xl" maxW="90%" width="100%" className="order-table-modal">
            <DialogHeader
              style={{ backgroundColor: 'white', zIndex: 100, width: '100%' }}
              borderBottomWidth="1px"
              paddingLeft="24px"
            >
              <Text as="p" fontSize="20px" fontWeight="bold">
                Grinder PO# {currentOrderCameled.grinderPoNumber ? currentOrderCameled.grinderPoNumber : '-'} /{' '}
                {internalPoLabel} {currentOrderCameled.internalPoNumber ? currentOrderCameled.internalPoNumber : '-'} /{' '}
                {grinder ? grinder.name : '-'}
              </Text>
              <DialogCloseTrigger asChild onClick={this.handleModalClose} />
            </DialogHeader>

            <DialogBody padding={0} marginTop={0}>
              <VStack spacing="27px" align="stretch" padding="35px 27px 111px 27px">
                <Flex height="40px">
                  {isUSADomestic ? (
                    <PurchaseOrderInfo
                      {...{
                        isFrozenProduct,
                        grinderIsUnfunded: grinder?.isUnfunded,
                        productType: purchaseOrder.product_type,
                        isLocked: purchaseOrder.po_locked,
                      }}
                    />
                  ) : (
                    ''
                  )}
                  {!editing && purchaseOrder.status !== 'cancelled' && !isGrinderUser && (
                    <Button
                      colorScheme="actionPrimary"
                      fontSize="14px"
                      fontWeight="semibold"
                      width="115px"
                      height="40px"
                      marginLeft="auto"
                      onClick={this.handleEditClick.bind(this)}
                    >
                      Edit Order
                    </Button>
                  )}
                </Flex>
                {isFormOptionsLoaded ? (
                  <Tabs.Root defaultValue="orderStatus">
                    <Tabs.List>
                      <Tabs.Trigger value="orderStatus">Order Status</Tabs.Trigger>
                      <Tabs.Trigger value="orderDetails">Order Details</Tabs.Trigger>
                      <Tabs.Trigger value="productPricingDetails">
                        {isProductAndPricingDetails ? 'Product & Pricing Details' : 'Lines'}
                      </Tabs.Trigger>
                      <Tabs.Trigger value="commentsAttachments">Comments and Attachments</Tabs.Trigger>
                      <Tabs.Trigger value="orderDetailsSummary">Order Details Summary</Tabs.Trigger>
                      <Tabs.Trigger value="activityLogs">Activity Logs</Tabs.Trigger>
                    </Tabs.List>

                    {/* ORDER STATUS */}
                    <Tabs.Content value="orderStatus" className="tab-panel">
                      <Flex mb="1em" alignItems="center">
                        <Text as="b" mr="4px">
                          Order Status
                        </Text>
                        <StatusTag status={currentOrderCameled.status} />
                      </Flex>
                      <VStack align="stretch" paddingX="27px" paddingY="25px" backgroundColor="white" gap="20px">
                        <PurchaseOrderStatusSection
                          {...{
                            isUSADomestic: true,
                            currentOrder: currentOrderCameled,
                            isFrozenProduct,
                            endUserName: endUserCurrent.label ? endUserCurrent.label : '-',
                            grinderName: grinder ? grinder.name : '-',
                            handleChangeValue: this.handleChangeValue,
                            isInEditMode: editing,
                          }}
                        />
                        <Separator />

                        {purchaseOrder.status !== Constants.ORDER_STATUSES.PENDING && (
                          <CustomFormControl key="CustomFormControlOnHold">
                            <HStack>
                              <Text fontWeight="bold">On Hold:</Text>
                              <Checkbox
                                name="on_hold"
                                id="on_hold"
                                disabled={!editing}
                                colorScheme="actionSecondary"
                                onChange={this.handleChangeValue}
                                defaultChecked={get(purchaseOrder, 'on_hold', false)}
                                checked={get(purchaseOrder, 'on_hold', false)}
                              />
                            </HStack>
                          </CustomFormControl>
                        )}
                        <Separator />
                        {get(purchaseOrder, 'on_hold', false) && (
                          <Box width="100%">
                            <Field htmlFor="on_hold_reason" label="On Hold Reason">
                              <Textarea
                                backgroundColor="white"
                                className={`form-control ${
                                  this.state.requiredFields.indexOf('on_hold_reason') !== -1 ? 'error' : ''
                                }`}
                                disabled={!editing}
                                id="on_hold_reason"
                                name="on_hold_reason"
                                value={purchaseOrder.on_hold_reason || ''}
                                onChange={this.handleChangeValue}
                                rows="4"
                              />
                            </Field>
                            {this.state.requiredFields.indexOf('on_hold_reason') !== -1 && (
                              <Alert title="On Hold Reason is Required." status="error" />
                            )}
                          </Box>
                        )}
                        <Box>
                          <Text as="p" color="accent.one.default" fontWeight="bold" marginBottom="10px">
                            Summary
                          </Text>
                          <Grid templateColumns="repeat(3, 1fr)" width="max-content" columnGap="84px">
                            <GridItem colSpan={2}>
                              {!isGrinderUser ? (
                                <VStack align="stretch" spacing="10px">
                                  <Text as="p" fontWeight="bold">
                                    Total Cost of Products
                                  </Text>
                                  <Text as="p" fontWeight="bold">
                                    Total Cost of Transport
                                  </Text>
                                  <Text as="p" fontWeight="bold">
                                    Total Cost (Products + Transport)
                                  </Text>
                                  <Text as="p" color="accent.one.default" fontWeight="bold">
                                    Cost per Unit
                                  </Text>
                                  {purchaseOrder.status === 'invoiced' && (
                                    <Text as="p" fontWeight="bold">
                                      Total Invoiced Amount
                                    </Text>
                                  )}
                                </VStack>
                              ) : (
                                purchaseOrder.status === 'invoiced' && (
                                  <Text as="p" fontWeight="bold">
                                    Total Invoiced Amount
                                  </Text>
                                )
                              )}
                            </GridItem>
                            <GridItem colSpan={1}>
                              {!isGrinderUser && (
                                <VStack align="stretch" spacing="10px">
                                  <Text as="p">${commify(Math.round(allIngredientCosts))}</Text>
                                  <Text as="p">${commify(Math.round(purchaseOrder.transport_cost))}</Text>
                                  <Text as="p">${commify(Math.round(purchaseOrder.all_in_cost))}</Text>
                                  <Text as="p" color="accent.one.default" fontWeight="bold">
                                    ${commify(costPerUnit || 0, 4)}
                                  </Text>
                                </VStack>
                              )}
                            </GridItem>
                          </Grid>
                        </Box>
                      </VStack>
                    </Tabs.Content>
                    {/* ORDER DETAILS */}
                    <Tabs.Content value="orderDetails" className="tab-panel">
                      {/* {purchaseOrder.status !== 'cancelled' && ( */}
                      <VStack align="stretch">
                        <Grid gap="18px" templateColumns="repeat(4, 1fr)" width="100%">
                          <CustomFormControlWrapper
                            key="CustomFormControlGrinderPO"
                            label={
                              <>
                                Grinder PO#&nbsp;
                                <Tooltip
                                  content="Displays the purchase order number assigned by the grinder."
                                  positioning={{ placement: 'right-end' }}
                                >
                                  <IconButton
                                    width="14px"
                                    height="14px"
                                    padding="0"
                                    minW="auto"
                                    borderRadius="50%"
                                    color="#878787"
                                    variant="unstyled"
                                  >
                                    <IoInformationCircleOutline size="14px" />
                                  </IconButton>
                                </Tooltip>
                              </>
                            }
                          >
                            <Input
                              id="grinderPO"
                              name="grinderPoNumber"
                              type="text"
                              disabled={!editing || !isEditableGrinderPoNumber}
                              className="form-control"
                              value={purchaseOrder.grinder_po_number || ''}
                              onChange={this.handleSelectValue.bind(this, 'grinder_po_number')}
                            />
                          </CustomFormControlWrapper>

                          {purchaseOrder?.status === Constants.ORDER_STATUSES.DRAFT && (
                            <SelectField
                              key="CustomFormControlProductType"
                              label="Product Type"
                              name="product-type"
                              value={
                                Constants.PRODUCT_TYPES.find(option => option.value === purchaseOrder.productType) ||
                                Constants.PRODUCT_TYPE_LIST.BEEF
                              }
                              options={Constants.PRODUCT_TYPES}
                              placeholder="Product Type"
                              disabled={!editing}
                              onChange={this.handleSelectValue.bind(this, 'productType')}
                            />
                          )}

                          <SelectField
                            label={
                              <>
                                End User&nbsp;
                                <Tooltip
                                  content="Identifies the final recipient to ensure the product meets specific requirements."
                                  positioning={{ placement: 'right-end' }}
                                >
                                  <IconButton
                                    width="14px"
                                    height="14px"
                                    padding="0"
                                    minW="auto"
                                    borderRadius="50%"
                                    color="#878787"
                                    variant="unstyled"
                                  >
                                    <IoInformationCircleOutline size="14px" />
                                  </IconButton>
                                </Tooltip>
                              </>
                            }
                            name="select-end-user"
                            value={endUserOptions.find(e => e.value === purchaseOrder.end_user_id)}
                            options={endUserOptions}
                            placeholder="End User"
                            disabled={!editing}
                            onChange={this.handleSelectValue.bind(this, 'end_user_id')}
                          />

                          <SelectField
                            key="CustomFormControlPackerId"
                            label={
                              <>
                                Packer&nbsp;
                                <Tooltip
                                  content="Specifies the packer responsible for handling and tracking."
                                  positioning={{ placement: 'right-end' }}
                                >
                                  <IconButton
                                    width="14px"
                                    height="14px"
                                    padding="0"
                                    minW="auto"
                                    borderRadius="50%"
                                    color="#878787"
                                    variant="unstyled"
                                  >
                                    <IoInformationCircleOutline size="14px" />
                                  </IconButton>
                                </Tooltip>
                              </>
                            }
                            id="select-packer"
                            name="packer_id"
                            value={
                              (editing
                                ? packerOptions
                                : allPackers?.map(el => ({ value: el.id, label: el.name }))
                              ).find(option => option.value === get(purchaseOrder, 'packer_id')) || null
                            }
                            options={sortedPackerOptions}
                            disabled={!editing}
                            // className={requiredFields.form.includes('packerId') ? 'error' : ''}
                            onChange={this.handleSelectValue.bind(this, 'packer_id')}
                          />

                          <SelectField
                            label={
                              <>
                                Establishment&nbsp;
                                <Tooltip
                                  content="Identifies the establishment associated with the order."
                                  positioning={{ placement: 'right-end' }}
                                >
                                  <IconButton
                                    width="14px"
                                    height="14px"
                                    padding="0"
                                    minW="auto"
                                    borderRadius="50%"
                                    color="#878787"
                                    variant="unstyled"
                                  >
                                    <IoInformationCircleOutline size="14px" />
                                  </IconButton>
                                </Tooltip>
                              </>
                            }
                            name="select-packer"
                            // defaultValue={purchaseOrder.packer_plant_id}
                            value={
                              (editing
                                ? sortedPackerPlantOptionsByPacker
                                : allPackerPlants?.map(el => ({ value: el.id, label: el.name }))
                              ).find(option => option.value === get(purchaseOrder, 'packer_plant_id')) || null
                            }
                            options={sortedPackerPlantOptionsByPacker}
                            placeholder="Establishment"
                            disabled={!editing && !isPackerUser}
                            onChange={this.onPackerPlantChange.bind(this)}
                          />

                          <CustomFormControlWrapper
                            key="CustomFormControlEstId"
                            label={
                              <>
                                Establishment Number&nbsp;
                                <Tooltip
                                  content="Records the official number for traceability and compliance."
                                  positioning={{ placement: 'right-end' }}
                                >
                                  <IconButton
                                    width="14px"
                                    height="14px"
                                    padding="0"
                                    minW="auto"
                                    borderRadius="50%"
                                    color="#878787"
                                    variant="unstyled"
                                  >
                                    <IoInformationCircleOutline size="14px" />
                                  </IconButton>
                                </Tooltip>
                              </>
                            }
                          >
                            <Input
                              id="estId"
                              name="estId"
                              value={
                                editing &&
                                allPackerPlants?.find(option => option.id === get(purchaseOrder, 'packer_plant_id'))
                                  ?.delisted
                                  ? ''
                                  : purchaseOrder.estId
                              }
                              disabled={!editing}
                              className="form-control"
                              onChange={this.handleSelectValue.bind(this, 'estId')}
                            />
                          </CustomFormControlWrapper>

                          <CustomFormControlWrapper
                            key="CustomFormControlFreightRate"
                            label={
                              <>
                                Freight Rate&nbsp;
                                <Tooltip
                                  content="Indicates the cost of transporting goods to aid in logistics planning."
                                  positioning={{ placement: 'right-end' }}
                                >
                                  <IconButton
                                    width="14px"
                                    height="14px"
                                    padding="0"
                                    minW="auto"
                                    borderRadius="50%"
                                    color="#878787"
                                    variant="unstyled"
                                  >
                                    <IoInformationCircleOutline size="14px" />
                                  </IconButton>
                                </Tooltip>
                              </>
                            }
                          >
                            <Input
                              id="freight_rate"
                              name="transport_cost_per_unit"
                              type="number"
                              onWheelCapture={e => {
                                e.target.blur();
                              }}
                              disabled={!editing}
                              className="form-control"
                              value={purchaseOrder.transport_cost_per_unit || ''}
                              onChange={this.handleSelectValue.bind(this, 'transport_cost_per_unit')}
                            />
                          </CustomFormControlWrapper>

                          <SelectField
                            key="CustomFormControlBuyerId"
                            label={
                              <>
                                Buyer&nbsp;
                                <Tooltip
                                  content="Identifies the individual or organization purchasing the product."
                                  positioning={{ placement: 'right-end' }}
                                >
                                  <IconButton
                                    width="14px"
                                    height="14px"
                                    padding="0"
                                    minW="auto"
                                    borderRadius="50%"
                                    color="#878787"
                                    variant="unstyled"
                                  >
                                    <IoInformationCircleOutline size="14px" />
                                  </IconButton>
                                </Tooltip>
                              </>
                            }
                            id="select-buyer"
                            name="buyer_id"
                            defaultValue={purchaseOrder.buyer_id}
                            // value={buyerOptions.find(option => option.value === get(form, 'buyer_id')) || null}
                            options={buyerOptions}
                            disabled={!editing}
                            // className={requiredFields.form.includes('buyer_id') ? 'error' : ''}
                            onChange={this.handleSelectValue.bind(this, 'buyer_id')}
                            isClearable={false}
                          />

                          {/* Check was wrong, Shipment date should only show for Domestic(US) */}
                          {isUSADomestic && purchaseOrder?.status === Constants.ORDER_STATUSES.DRAFT && (
                            <CustomFormControlWrapper label="Shipment Date">
                              <DateTimeWithTrappedFocus
                                id="shipment_date"
                                isValidDate={valid}
                                inputProps={shippingDateInputProps}
                                closeOnSelect
                                value={purchaseOrder.shipment_date}
                                onChange={this.handleSelectValue.bind(this, 'shipment_date')}
                                timeFormat={false}
                                dateFormat="YYYY-MM-DD"
                              />
                            </CustomFormControlWrapper>
                          )}

                          <CustomFormControlWrapper
                            label={<>{isUSADomestic && !isFrozenProduct ? 'Scheduled' : ''} Delivery Date</>}
                          >
                            <DateTimeWithTrappedFocus
                              id="delivery_date"
                              isValidDate={valid}
                              inputProps={deliveryDateInputProps}
                              closeOnSelect
                              value={purchaseOrder.delivery_date}
                              onChange={this.handleSelectValue.bind(this, 'delivery_date')}
                              timeFormat={false}
                              dateFormat="YYYY-MM-DD"
                              className="full-width"
                            />
                          </CustomFormControlWrapper>
                          <CustomFormControlWrapper label="Delivery Time">
                            <DateTimeWithTrappedFocus
                              id="delivery_time"
                              inputProps={deliveryTimeInputProps}
                              closeOnSelect
                              value={deliveryTime}
                              onChange={this.handleSelectValue.bind(this, 'scheduled_delivery_time')}
                              timeConstraints={{ minutes: { step: 5 } }}
                              timeFormat="HH:mm"
                              dateFormat={false}
                              className="full-width"
                            />
                          </CustomFormControlWrapper>
                          <CustomFormControlWrapper label="Packer Delivery Date">
                            <DateTimeWithTrappedFocus
                              id="packer_delivery_date"
                              isValidDate={valid}
                              inputProps={packerDeliveryDateInputProps}
                              closeOnSelect
                              value={purchaseOrder.packer_delivery_date}
                              onChange={this.handleSelectValue.bind(this, 'packer_delivery_date')}
                              timeFormat={false}
                              dateFormat="YYYY-MM-DD"
                              className="full-width"
                            />
                          </CustomFormControlWrapper>
                          {isUSADomestic && !isFrozenProduct && (
                            <CustomFormControlWrapper label="Grinder Received Date">
                              <DateTimeWithTrappedFocus
                                id="recv_delivery_date"
                                isValidDate={valid}
                                inputProps={{
                                  ...baseDateInputProps,
                                  placeholder: 'Grinder Received Date',
                                }}
                                closeOnSelect
                                value={purchaseOrder.recv_delivery_date}
                                onChange={this.handleSelectValue.bind(this, 'recv_delivery_date')}
                                timeFormat={false}
                                dateFormat="YYYY-MM-DD"
                                className="full-width"
                              />
                            </CustomFormControlWrapper>
                          )}

                          {/* Check was wrong it should only show for Domestic(US) */}
                          {isUSADomestic && purchaseOrder?.status === Constants.ORDER_STATUSES.DRAFT && (
                            <CustomFormControlWrapper label="Production Date">
                              <DateTimeWithTrappedFocus
                                id="production_date"
                                isValidDate={valid}
                                inputProps={productionDateInputProps}
                                closeOnSelect
                                value={purchaseOrder.production_date}
                                onChange={this.handleSelectValue.bind(this, 'production_date')}
                                timeFormat={false}
                                dateFormat="YYYY-MM-DD"
                                className="full-width"
                              />
                            </CustomFormControlWrapper>
                          )}

                          <CustomFormControlWrapper
                            label={
                              <>
                                Packer SO#&nbsp;
                                <Tooltip
                                  content="Displays the sales order number assigned by the packer."
                                  positioning={{ placement: 'right-end' }}
                                >
                                  <IconButton
                                    width="14px"
                                    height="14px"
                                    padding="0"
                                    minW="auto"
                                    borderRadius="50%"
                                    color="#878787"
                                    variant="unstyled"
                                  >
                                    <IoInformationCircleOutline size="14px" />
                                  </IconButton>
                                </Tooltip>
                              </>
                            }
                          >
                            <Input
                              id="packer_so_number"
                              className="form-control"
                              type="text"
                              disabled={!editing && !isPackerUser}
                              placeholder="Packer SO#"
                              onChange={this.handleSelectValue.bind(this, 'packer_so_number')}
                              value={purchaseOrder.packer_so_number || ''}
                            />
                          </CustomFormControlWrapper>
                          {isUSADomestic && !isFrozenProduct && (
                            <>
                              <CustomFormControlWrapper
                                label={
                                  <>
                                    Packer Paid Date&nbsp;
                                    <Tooltip
                                      content="Displays the date when the packer made the payment."
                                      positioning={{ placement: 'right-end' }}
                                    >
                                      <IconButton
                                        width="14px"
                                        height="14px"
                                        padding="0"
                                        minW="auto"
                                        borderRadius="50%"
                                        color="#878787"
                                        variant="unstyled"
                                      >
                                        <IoInformationCircleOutline size="14px" />
                                      </IconButton>
                                    </Tooltip>
                                  </>
                                }
                              >
                                <DateTimeWithTrappedFocus
                                  id="packer_paid_date"
                                  isValidDate={valid}
                                  inputProps={{
                                    ...baseDateInputProps,
                                    placeholder: 'Packer Paid Date',
                                  }}
                                  closeOnSelect
                                  value={purchaseOrder.packer_paid_date}
                                  onChange={this.handleSelectValue.bind(this, 'packer_paid_date')}
                                  timeFormat={false}
                                  dateFormat="YYYY-MM-DD"
                                  className="full-width"
                                />
                              </CustomFormControlWrapper>
                              <CustomFormControlWrapper
                                label={
                                  <>
                                    Packer Invoice#&nbsp;
                                    <Tooltip
                                      content="Displays the invoice number assigned by the packer."
                                      positioning={{ placement: 'right-end' }}
                                    >
                                      <IconButton
                                        width="14px"
                                        height="14px"
                                        padding="0"
                                        minW="auto"
                                        borderRadius="50%"
                                        color="#878787"
                                        variant="unstyled"
                                      >
                                        <IoInformationCircleOutline size="14px" />
                                      </IconButton>
                                    </Tooltip>
                                  </>
                                }
                              >
                                <Input
                                  id="packer_invoice_number"
                                  className="form-control"
                                  type="text"
                                  disabled={!editing && !isPackerUser}
                                  placeholder="Packer Invoice#"
                                  onChange={this.handleSelectValue.bind(this, 'packer_invoice_number')}
                                  value={purchaseOrder.packer_invoice_number || ''}
                                />
                              </CustomFormControlWrapper>
                              <CustomFormControlWrapper label="Packer Invoice Date">
                                <DateTimeWithTrappedFocus
                                  id="packer_invoice_date"
                                  isValidDate={valid}
                                  inputProps={{
                                    ...baseDateInputProps,
                                    placeholder: 'Packer Invoice Date',
                                  }}
                                  closeOnSelect
                                  value={purchaseOrder.packer_invoice_date}
                                  onChange={this.handleSelectValue.bind(this, 'packer_invoice_date')}
                                  timeFormat={false}
                                  dateFormat="YYYY-MM-DD"
                                  className="full-width"
                                />
                              </CustomFormControlWrapper>

                              <SelectField
                                label="Tags"
                                {...{
                                  className: 'edit-international-order__select',
                                  isClearable: true,
                                  disabled: !editing,
                                  isSearchable: true,
                                  closeMenuOnSelect: false,
                                  removeSelected: true,
                                  isMulti: true,
                                  name: 'select-tags',
                                  value: purchaseOrder.tags
                                    ? purchaseOrder.tags.map(t => ({ label: t, value: t }))
                                    : [],
                                  onChange: this.handleSelectValue.bind(this, 'tags'),
                                  options: tagOptions?.sort((a, b) => (a.label > b.label ? 1 : -1)),
                                }}
                              />

                              <CustomFormControlWrapper label="Additional Reference">
                                <Input
                                  id="additinal_grn"
                                  className="form-control"
                                  type="text"
                                  disabled={!editing && !isPackerUser}
                                  placeholder="Additional Reference"
                                  onChange={this.handleSelectValue.bind(this, 'additinal_grn')}
                                  value={purchaseOrder.additinal_grn || ''}
                                />
                              </CustomFormControlWrapper>
                              <CustomFormControlWrapper label="Entrapment">
                                <Input
                                  id="entrapment"
                                  className="form-control"
                                  type="text"
                                  disabled={!editing && !isPackerUser}
                                  placeholder="Entrapment"
                                  onChange={this.handleSelectValue.bind(this, 'entrapment')}
                                  value={purchaseOrder.entrapment || ''}
                                />
                              </CustomFormControlWrapper>
                            </>
                          )}

                          {/* Condition was wrong, As we need to show these fields for Domestic(US) only not for Internation (AU/NZ) */}
                          {isUSADomestic && purchaseOrder?.status === Constants.ORDER_STATUSES.DRAFT && (
                            <>
                              {!isGrinderUser && (
                                <CustomFormControlWrapper label="Freight Rate">
                                  <Input
                                    backgroundColor="white"
                                    id="freight_rate"
                                    className="form-control"
                                    type="number"
                                    disabled={!editing && !isPackerUser}
                                    onWheelCapture={e => {
                                      e.target.blur();
                                    }}
                                    placeholder="Freight rate"
                                    onChange={this.handleSelectValue.bind(this, 'transport_cost_per_unit')}
                                    value={purchaseOrder.transport_cost_per_unit}
                                  />
                                </CustomFormControlWrapper>
                              )}

                              <SelectField
                                label="Carrier"
                                name="select-carrier"
                                disabled={!editing}
                                value={transporterOptions.find(opt => opt.value === purchaseOrder.transporter_id)}
                                options={transporterOptions}
                                placeholder="Carrier"
                                onChange={this.handleSelectValue.bind(this, 'transporter_id')}
                              />

                              <SelectField
                                label="Drop Trailer"
                                name="drop_trailer"
                                disabled={!editing}
                                value={dropTrailerOptions.find(
                                  trailer => trailer.value === (purchaseOrder.drop_trailer || '')
                                )}
                                options={dropTrailerOptions}
                                placeholder="Drop Trailer"
                                onChange={this.handleSelectValue.bind(this, 'drop_trailer')}
                              />

                              <CustomFormControlWrapper label="Truck Load Number">
                                <Input
                                  backgroundColor="white"
                                  id="truck_load_no"
                                  className="form-control"
                                  type="text"
                                  disabled={!editing && !isPackerUser}
                                  placeholder="Truck Load Number"
                                  onChange={this.handleSelectValue.bind(this, 'truck_load_no')}
                                  value={purchaseOrder.truck_load_no}
                                />
                              </CustomFormControlWrapper>
                            </>
                          )}
                        </Grid>
                        {isUSADomestic && !isFrozenProduct && (
                          <InternationalOrderDetails
                            form={purchaseOrderCameled}
                            requiredFields={{
                              form: [],
                            }}
                            editMode={editing}
                            user={user}
                            users={users}
                            {...convertToCamelCase(this.props)}
                            onChangeValue={this.handleSelectValue.bind(this)}
                          />
                        )}
                      </VStack>
                      {/* )} */}
                    </Tabs.Content>
                    {/* PRODUCT & PRICING DETAILS */}
                    <Tabs.Content value="productPricingDetails" className="tab-panel">
                      {
                        // purchaseOrder.status !== 'cancelled' &&
                        isProductAndPricingDetails ? (
                          <InternationalOrderLines
                            {...{
                              buyCurrency: purchaseOrderCameled.buyCurrency,
                              buyUnitOfMeasureId: purchaseOrderCameled.buyUnitOfMeasureId,
                              form: purchaseOrderCameled,
                              formType: 'editForm',
                              grinderDestinationCountry: grinder ? grinder.destination_country : '',
                              inputProducts: editing ? filteredInputProducts : allInputProducts,
                              isChilled: purchaseOrderCameled.isChilled,
                              isInEditMode: editing,
                              hasExpectedProductionDate: purchaseOrder?.status === Constants.ORDER_STATUSES.DRAFT,
                              lineIndices: purchaseOrderCameled.lineIndices,
                              lines: purchaseOrderCameled.linesObject,
                              loadSizes,
                              onAddLine: this.handleAddUSADomesticLine,
                              onPPUChangeValue: this.handlePPUChangeValue,
                              onPPURoundingDecimal: this.handlePPURoundingDecimal,
                              onRemoveLine: this.handleDeleteUSADomesticLine,
                              onUpdateLine: this.handleUpdateLine,
                              packerId,
                              packerPlantOriginCountry,
                              packerPlantId: get(purchaseOrderCameled, 'packerPlantId'),
                              packerPlantOptions: sortedPackerPlantOptionsByPacker,
                              packerPlants: packer_plants,
                              grinders,
                              purchasingOffice: purchaseOrderCameled.purchasingOffice,
                              purchaseOrderId: purchaseOrderCameled.id,
                              purchaseOrderIndex: purchaseOrderCameled.id,
                              requiredFields: {
                                form: [],
                              },
                              sellCurrency: purchaseOrderCameled.sellCurrency,
                              sellUnitOfMeasureId: purchaseOrderCameled.sellUnitOfMeasureId,
                              shipmentDateEnd: purchaseOrderCameled.shipmentDateEnd,
                              submitProductionDetails: this.onProductionDetailChange,
                              submitPriceAdjustments: this.onPriceAdjustmentChange,
                              submitCLResults: this.handleCLUpdate,
                              submitTemperatures: this.handleTemperatureUpdate,
                              tags,
                              packageWeights,
                              isUSADomestic,
                              isLocked: purchaseOrder.po_locked,
                              filteredPriceAgreementOptions,
                            }}
                          />
                        ) : (
                          <OrderResults
                            order={purchaseOrder}
                            editing={editing}
                            onOrderLinesChange={this.onOrderLinesChange}
                            submitProductionDetails={this.onProductionDetailChange}
                            addLine={this.handleAddLine}
                            deleteLine={this.handleDeleteLine}
                            input_products={editing ? filteredInputProducts : allInputProducts}
                            {...this.props}
                          />
                        )
                      }
                    </Tabs.Content>
                    {/* COMMENTS AND ATTACHMENTS */}
                    <Tabs.Content value="commentsAttachments" className="tab-panel">
                      {/* {purchaseOrder.status !== 'cancelled' && ( */}
                      <>
                        <Text fontWeight="bold" mb="1em">
                          Comments
                        </Text>
                        <HStack spacing="42px" width="100%">
                          <Box width="100%">
                            <Field label="Internal Comments" htmlFor="internalComments">
                              <Textarea
                                backgroundColor="white"
                                className="form-control"
                                disabled={!editing}
                                id="internalComments"
                                name="internalComments"
                                value={purchaseOrderCameled.internalComments}
                                placeholder="Add a comment here..."
                                onChange={this.handleSelectValue.bind(this, 'internal_comments')}
                                rows="4"
                              />
                            </Field>
                          </Box>
                          <Box width="100%">
                            <Field label="External Comments" htmlFor="externalComments">
                              <Textarea
                                backgroundColor="white"
                                className="form-control"
                                disabled={!editing}
                                id="externalComments"
                                name="externalComments"
                                value={purchaseOrderCameled.externalComments}
                                placeholder="Add a comment here..."
                                onChange={this.handleSelectValue.bind(this, 'external_comments')}
                                rows="4"
                              />
                            </Field>
                          </Box>
                        </HStack>
                        <Text fontWeight="bold" my="1.5em">
                          Load Notes
                        </Text>
                        <IssuesXcodes
                          isInEditMode={editing}
                          issuesXcodesOption={issues_xcode}
                          allIssuesXcodesOption={allIssuesXcodes}
                          onChange={this.handleIssueXcode}
                          onAdd={this.handleAddIssueXcode}
                          onDelete={this.handleDeleteIssueXcode}
                          issuesXcodes={get(purchaseOrder, 'issues_xcodes', [])}
                        />

                        {/* <Text fontWeight="bold" my="1.5em">
                            Load Notes
                          </Text>
                          <LoadNotes
                            {...{
                              form: purchaseOrderCameled,
                              isInEditMode: editing,
                              inputProducts: input_products,
                              packerPlantOriginCountry,
                              onChange: this.handleSelectValue.bind(this, 'load_notes'),
                              onDelete: this.handleSelectValue.bind(this, 'deletedLoadNotesIds'),
                              loadNotes: get(purchaseOrder, 'load_notes', []),
                            }}
                          /> */}
                        <Text fontWeight="bold" my="1.5em">
                          Documents
                        </Text>
                        {/* -- Edit order line details--*/}
                        {/* -- Purchase Order Document uploads --*/}
                        <PurchaseOrderDocuments
                          token={token}
                          error={error}
                          {...convertToCamelCase({
                            autoSave: false,
                            disabled: !editing,
                            dispatch,
                            documents: purchaseOrderCameled.documents.filter(
                              doc => !Constants.GENERATED_DOCUMENT_TYPES_LIST.includes(doc.docType)
                            ),
                            poDocTypes,
                            user,
                          })}
                          stateForm={{ form: purchaseOrder }}
                          currentOrder={current_order}
                        />
                        <Text fontWeight="bold" my="1.5em">
                          Generated Documents
                        </Text>
                        <GeneratedDocuments
                          {...{
                            form: purchaseOrderCameled,
                            currentOrder: currentOrderCameled,
                            isInEditMode: editing,
                            internalPoLabel,
                            user,
                            grinderIsChinese:
                              (grinder ? grinder.destinationCountry.toLowerCase() : '') === Constants.COUNTRIES.CHINA,
                            setIsGeneratingDocument: value => {
                              this.setState({
                                isGeneratingDocument: value,
                              });
                            },
                            isGeneratingDocument,
                            setEmailModalIsOpen: value => {
                              this.setState({
                                emailModalIsOpen: value,
                              });
                            },
                            emailModalIsOpen,
                            ...this.props,
                          }}
                        />
                      </>
                      {/* )} */}
                    </Tabs.Content>
                    {/* ORDER DETAILS SUMMARY */}
                    <Tabs.Content value="orderDetailsSummary" className="tab-panel">
                      {/* Order Details component
                      list the orderline and order details in tabular format */}
                      <OrderDetails {...{ currentOrder: currentOrderCameled, user }} />
                    </Tabs.Content>
                    {/* Activity Logs */}
                    <Tabs.Content value="activityLogs" className="tab-panel">
                      <VStack align="stretch" backgroundColor="inherit">
                        <OrderActivityLog
                          grinderPoNumber={currentOrderCameled.grinderPoNumber || currentOrderCameled.internalPoNumber}
                          entityType={currentOrderCameled.grinderPoNumber ? 'grinder_po_number' : 'internal_po_number'}
                        />
                      </VStack>
                    </Tabs.Content>
                  </Tabs.Root>
                ) : (
                  <Loading />
                )}

                {validationErrors?.length ? (
                  <Box>
                    <Alert status="error">
                      {validationErrors.map(err => {
                        return `Missing values for ${err.key} on Line ${err.value}. `;
                      })}
                    </Alert>
                  </Box>
                ) : null}
              </VStack>
            </DialogBody>
            <DialogFooter justifyContent="space-between" padding="18px 40px" borderTopWidth="1px">
              <Group marginRight="auto" spacing="23px">
                {purchaseOrder.status !== 'cancelled' && !isGrinderUser && !isPackerUser && (
                  <Button
                    variant="outline"
                    colorPalette="red"
                    fontSize="16px"
                    height="43px"
                    width="163px"
                    onClick={this.deleteOrder.bind(this)}
                  >
                    Cancel Order
                  </Button>
                )}
                {purchaseOrder.status !== Constants.ORDER_STATUSES.CANCELLED &&
                  purchaseOrder.status !== Constants.ORDER_STATUSES.DRAFT && (
                    <TransferOrderModal
                      purchaseOrderId={purchaseOrder.id}
                      onTransferSuccess={() => {
                        this.setState({
                          purchaseOrder: {
                            ...purchaseOrder,
                            status: Constants.ORDER_STATUSES.TRANSFERRED,
                          },
                        });
                      }}
                    >
                      {({ onOpen }) => (
                        <Button
                          variant="outline"
                          colorPalette="blue"
                          fontSize="16px"
                          height="43px"
                          width="163px"
                          onClick={onOpen}
                          disabled={purchaseOrder.status === Constants.ORDER_STATUSES.TRANSFERRED}
                        >
                          Transfer
                        </Button>
                      )}
                    </TransferOrderModal>
                  )}
                <IconButton
                  colorPalette="blue"
                  variant="outline"
                  fontSize="16px"
                  height="43px"
                  width="70px"
                  onClick={this.excelExport.bind(this)}
                >
                  <DownloadIcon />
                </IconButton>
              </Group>
              <Group spacing="23px">
                <Flex height="40px">
                  {!editing && purchaseOrder.status !== 'cancelled' && !isGrinderUser && (
                    <Button
                      colorPalette="blue"
                      fontSize="16px"
                      fontWeight="semibold"
                      height="43px"
                      width="163px"
                      marginLeft="auto"
                      onClick={this.handleEditClick.bind(this)}
                    >
                      Edit Order
                    </Button>
                  )}
                </Flex>
                <Button
                  colorPalette="blue"
                  variant="outline"
                  fontSize="16px"
                  height="43px"
                  width="163px"
                  onClick={this.handleModalClose}
                >
                  Close
                </Button>

                {editing && purchaseOrder?.status === Constants.ORDER_STATUSES.DRAFT && (
                  <Button
                    colorPalette="blue"
                    variant="solid"
                    fontSize="16px"
                    fontWeight="semibold"
                    width="163px"
                    height="43px"
                    onClick={this.updateOrder.bind(this, purchaseOrder)}
                    disabled={isGeneratingDocument}
                  >
                    Save Draft Order
                  </Button>
                )}

                {/* Form save/submit/export */}
                {!isGrinderUser &&
                  !isPackerUser &&
                  !loading &&
                  orderChanged &&
                  editing &&
                  (purchaseOrder.status === Constants.ORDER_STATUSES.DRAFT ? (
                    <Confirm
                      onConfirm={this.updateOrder.bind(this, {
                        ...purchaseOrder,
                        status:
                          purchaseOrder?.status === Constants.ORDER_STATUSES.DRAFT
                            ? Constants.ORDER_STATUSES.ORDERED
                            : purchaseOrder?.status,
                      })}
                      title="Are you sure, do you want to submit this order?"
                    >
                      <Button
                        colorPalette="actionPrimary"
                        fontSize="16px"
                        height="43px"
                        width="163px"
                        disabled={isGeneratingDocument || saving}
                      >
                        Submit Order
                      </Button>
                    </Confirm>
                  ) : (
                    <Button
                      colorPalette="actionPrimary"
                      fontSize="16px"
                      height="43px"
                      width="163px"
                      onClick={this.updateOrder.bind(this, purchaseOrder)}
                      disabled={isGeneratingDocument || saving}
                    >
                      Save Edited Order
                    </Button>
                  ))}
              </Group>
            </DialogFooter>
          </DialogContent>
        </DialogRoot>
      );
    }
    return false;
  }
}

const mapStateToProps = state => {
  const result = {
    ...findEntitiesInState(state),
    configs: state.configs,
    email: state.email,
    poDocuments: state.po_documents || [],
    users: state.users,
    ...findAllEntitiesWithoutFilterInState(state, {
      all_grinders: 'grinder',
      all_packers: 'packer',
      all_packer_plants: 'packer_plant',
      all_input_products: 'input_product',
      all_issues_xcodes: 'load_notes',
    }),
    hasError: state.orders.hasError,
  };
  const cameled = convertToCamelCase(result);
  return cameled;
};

export default connect(mapStateToProps)(OrderTableModal);
